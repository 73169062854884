
import AppRoutingConfig from '../../assets/config/AppRoutingConfig'
import useAuthenticate from '../../hooks/useAuthenticate'
import { Navigate, useLocation, Outlet } from 'react-router-dom'


const RequireAuth = () => {
  const location = useLocation()
  const [isAuthenticated] = useAuthenticate() // Destructure the user from the useUser hook
  // const isAuthenticated = true;

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={AppRoutingConfig.APP_URL_SIGN_IN} state={{ from: location }} replace />
  )
}

export default RequireAuth
