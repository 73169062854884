import Actions from "../actions";
import { produce } from "immer";

interface State {
  leaves: {
    totalCount: number;
    leaveDetail: [];
  };
  selectedLeaveManagementId:number,
  leaveManagementDetails:{},
  approvalStatusDetails:{
    selectedLeaveManagementId:number,
    approvalStatus:string
  }
}
const initialState: State = {
  leaves: {
    totalCount: 0,
    leaveDetail: [],
  },
  selectedLeaveManagementId:0,
  leaveManagementDetails:{},
  approvalStatusDetails : {
    selectedLeaveManagementId:0,
    approvalStatus:""
  }
};

const leaveManagement = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_LEAVE_MANAGEMENT_LIST: {
      draft.leaves = payload;
      break;
    }

    case Actions.SELECTED_LEAVE_MANAGEMENT_ID: {
      draft.selectedLeaveManagementId = payload.selectedLeaveManagementId
      break;
    }

    case Actions.LEAVE_MANAGEMENT_APPROVAL_STATUS: {
      draft.approvalStatusDetails = payload?.approvalStatusDetails;
      break;
    }

    case Actions.GET_LEAVE_MANAGEMENT_DETAILS: {
      draft.leaveManagementDetails = payload.leaveManagementDetails
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default leaveManagement;
