export const GET_REGIONS_LIST = null
export const SELECTED_REGION_ID = null
export const GET_REGION_DETAIL = null
export const GET_REGION_SELECT_LIST = null
export const GET_COUNTRY_SELECT_LIST = null
export const GET_STATE_SELECT_LIST = null
export const GET_CITY_SELECT_LIST = null
export const SELECTED_REGION_STATUS = null

export const RESET_CITY_SELECT_LIST = null
export const RESET_STATE_SELECT_LIST = null