import Actions from "../actions";
import { produce } from "immer";

interface State {
partners : {
  totalCount: number;
  partnerDetails:[];
},
selectedPartnersId:number;
partnerDetails:{};
selectedPartnersStatus:boolean
}

const initialState: State = {
    partners : {
      totalCount: 0,
      partnerDetails:[]
    },
    selectedPartnersId:0,
    partnerDetails:{},
    selectedPartnersStatus:false
};

const partners = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {

    case Actions.GET_PARTNERS_LIST: {
      draft.partners = payload?.partners;
      break;
    }

    case Actions.GET_PARTNER_DETAIL: {
      draft.partnerDetails = payload?.partnerDetails;
      break;
    }

    case Actions.SELECTED_PARTNERS_ID: {
      draft.selectedPartnersId = payload?.selectedPartnersId;
      break;
    }

    case Actions.SELECTED_PARTNERS_STATUS: {
      draft.selectedPartnersStatus = payload?.selectedPartnersStatus;
      break;
    }
    
    default: {
      break;
    }
  }
}, initialState);

export default partners;
