import Actions from "../actions";
import { produce } from "immer";
interface State {
  leads: {
    totalCount: number;
    leadDetails: [];
    totalLeads:number;
    unAssingnedLeads:number;
    assignedLeads:number
  };
  selectLeadSource:[];
  selectStages:[];
  selectCustomerTypes:[];
  selectLeadStatus:[];
  leadDetail:{};
  leadStatus:{};
  leadStage:{};
  leadByCampaignIdSelectList:[]
}
const initialState: State = {
  leads: {
    totalCount: 0,
    leadDetails: [],
    totalLeads:0,
    unAssingnedLeads:0,
    assignedLeads:0
  },
  selectLeadSource:[],
  selectStages:[],
  selectCustomerTypes:[],
  selectLeadStatus:[],
  leadDetail:{},
  leadStatus:{},
  leadStage:{},
  leadByCampaignIdSelectList:[]
};
const lead = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_LEAD_DETAIL_LIST: {
      draft.leads = payload?.leadDetails;
      break;
    }
    case Actions.GET_LEAD_SOURCE_SELECT_LIST: {
      draft.selectLeadSource = payload?.selectLeadSource;
      break;
    }
    case Actions.GET_STAGE_SELECT_LIST: {
      draft.selectStages = payload?.stages;
      break;
    }
    case Actions.GET_CUSTOMER_TYPE_SELECT_LIST: {
      draft.selectCustomerTypes = payload?.customerTypes;
      break;
    }
    case Actions.GET_LEAD_DETAIL: {
      draft.leadDetail = payload?.leadDetail;
      break;
    }
    case Actions.GET_LEAD_STATUS_SELECT_LIST: {
      draft.selectLeadStatus = payload?.selectLeadStatus;
      break;
    }
    case Actions.UPDATE_LEAD_STATUS: {
      draft.leadStatus = payload?.leadStatus;
      break;
    }
    case Actions.UPDATE_LEAD_STAGE: {
      draft.leadStage = payload?.leadStage;
      break;
    }
    case Actions.GET_LEAD_BY_CAMPAIGN_ID_SELECT_LIST: {
      draft.leadByCampaignIdSelectList = payload?.leadByCampaignIdSelectList;
      break;
    }
    default: {
      break;
    }
  }
}, initialState);

export default lead;
