import React, { useEffect, useState, useTransition } from "react";
import Grid from "../../../../common/grid";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Label from "../../../../common/label";
import InputField from "../../../../common/inputField";
import Select from "../../../../common/select";
import Button from "../../../../common/button";
import { useForm } from "react-hook-form";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router";
import { useToast } from "../../../../../hooks/useToast";
import * as yup from "yup";
import {
  dateObjToDispViewIsoFormat,
  dateToDispViewIsoFormat,
  dateToTimestamp,
  getFilteredObjectFromId,
  isoToDispViewDate
} from "../../../../../utils/appCommonUtils";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addCampaignDetailAction,
  getBusinessTypeListAction,
  getSearchLeadCountAction,
  getSourceListAction,
} from "../../../../../redux/services/campaigns/campaignsServices";
import { useTranslation } from "react-i18next";
import { allowCharactersOnlyForIntegerNumbers } from "../../../../../utils/appKeyStrokeLimitingUtils";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";
import Datepicker from "../../../../common/datepicker";
import "./Campaigns.scss";

type Props = {};

type DefaultValueTypes = {
  id: number;
  campaignName: string;
  startDate: Date | null;
  endDate: Date | null;
  targetAudience: string;
  expectedTargetAudiene: string;
  bussinessType: any | null;
  source: any | null;
  actualTargetAudiene: string;
};


const CampaignsForm = (props: Props) => {

  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* loading dependencies */
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  /* loading dependencies */
  
  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const location = useLocation();
  const navigate = useNavigate();
  const params = location?.state;
  /* For navigating between pages */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* searchable select dependencies */
  const { businessTypeSelectList, sourceSelectList  } = useAppSelector(
    (state) => state.campaigns
    );
  /* searchable select dependencies */

  /* Dependencies for default values and validation */
  const defaultValues: DefaultValueTypes = {
    id: 0,
    campaignName: "",
    startDate: null,
    endDate: null,
    targetAudience: "",
    expectedTargetAudiene: "",
    bussinessType: null,
    source: null,
    actualTargetAudiene: "",
  };

  const schema = yup.object().shape({
    campaignName: yup.string().max(100, `${t("Crm.Campaigns.CampaignsForm.Name")} ${t(`CommonUtils.Validations.MustNotExceed100Characters`)}` ).required(t("Crm.Campaigns.CampaignsForm.PleaseEnterName")),
    targetAudience: yup.string().max(100, `${t("Crm.Campaigns.CampaignsForm.TargetAudience")} ${t(`CommonUtils.Validations.MustNotExceed100Characters`)}` ).required(t("Crm.Campaigns.CampaignsForm.PleaseEnterTargetAudience")),
    expectedTargetAudiene: yup.string().max(16, `${t("Crm.Campaigns.CampaignsForm.ExpectedTargetAudience")} ${t(`CommonUtils.Validations.MustNotExceed16Characters`)}`).required(t("Crm.Campaigns.CampaignsForm.PleaseEnterExpectedTargetAudience")),
    bussinessType: yup.object().required(t("Crm.Campaigns.CampaignsForm.PleaseSelectBusinessType")),
    source: yup.object().required(t("Crm.Campaigns.CampaignsForm.PleaseSelectSource")),
    startDate: yup.date().required(t("Crm.Campaigns.CampaignsForm.PleaseSelectStartDate"))
      .test(
        "START_DATE_VALIDATION_CHECK",
        t("Operations.ProjectSummary.ProjectSummaryForm.StartDateValidation"),
        function (value) {
          const { endDate } = this.parent;
          if (dateToTimestamp(isoToDispViewDate(new Date(value), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT)) <= 
          dateToTimestamp(isoToDispViewDate(new Date(endDate), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT))) {
            return true;
          } else {
            return false;
          }
        }
      ),
    endDate: yup.date().required(t("Crm.Campaigns.CampaignsForm.PleaseSelectEndDate"))
      .test(
        "END_DATE_VALIDATION_CHECK",
        t("Operations.ProjectSummary.ProjectSummaryForm.EndDateValidation"),
        function (value) {
          const { startDate } = this.parent;
          if (dateToTimestamp(isoToDispViewDate(new Date(value), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT)) >= 
          dateToTimestamp(isoToDispViewDate(new Date(startDate), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT))) {
            return true;
          } else {
            return false;
          }
        }
      ),
  });

  const {handleSubmit, control, setValue, watch, reset, trigger} = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {actualTargetAudiene, bussinessType , source} = watch()
  /* Dependencies for default values and validation */


  const selectListApiCalls = (details: any) => {

    // Business Type select list
    dispatch(getBusinessTypeListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.bussinessType, apiRes?.data).then(
            (bussinessType: any) => {
              setValue("bussinessType", bussinessType);
            }
          );
      }
    );


    // Source select list
    dispatch(getSourceListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.source, apiRes?.data).then(
            (source: any) => {
              setValue("source", source);
            }
          );
      }
    );
  };

  /* function definition for select list API calls */

  /* load initial dependencies - Add */
  useEffect(() => {
    if (!params || params === null || params === undefined) {
      selectListApiCalls(null);
    }
  }, []);
  /* load initial dependencies - Add */

  /* Form submit handler */
  const onSubmit = (formData: any) => {
    const {bussinessType, source, startDate, endDate, expectedTargetAudiene, ...rest } = formData;

    const dataToBeSent = {
      ...rest,
      expectedTargetAudiene:expectedTargetAudiene * 1,
      bussinessType: bussinessType && bussinessType?.value,
      source: source && source?.value,
      startDate: dateObjToDispViewIsoFormat(startDate),
      endDate: dateObjToDispViewIsoFormat(endDate)
    };

    dispatch(addCampaignDetailAction(navigate, showToast, dataToBeSent))
  };
  /* Form submit handler */

  /* Form submit handler */
  const onSubmitLeadCount = (e : React.SyntheticEvent) => {
    e.preventDefault()
    
    const dataToBeSent = {
      bussinessTypeId: bussinessType && bussinessType?.value,
      sourceId: source && source?.value,
    };

    dispatch(getSearchLeadCountAction(dataToBeSent)).then((apiRes) => {
      setValue("actualTargetAudiene", apiRes?.data);
    });
  };
  /* Form submit handler */

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name="campaignsForm"
        id="campaignsForm"
      >
        <Grid container spacing="md" flexDirection="column">
          {/* Campaigns Info */}
          <Grid item>
            <Card>
              <div className="campaigns-form-container flex_box flex_box--column">
                <Grid container>
                  <Title>
                    {t("Crm.Campaigns.CampaignsForm.CampaignDetails")}
                  </Title>
                </Grid>

                <Grid container spacing="sm">
                  {/* Name */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t("Crm.Campaigns.CampaignsForm.Name")}
                    </Label>
                    <InputField
                      name="campaignName"
                      placeholder={t("Crm.Campaigns.CampaignsForm.EnterName")}
                      control={control}
                    />
                  </Grid>
                  {/* Start Date*/}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t("Crm.Campaigns.CampaignsForm.StartDate")}
                    </Label>
                    <Datepicker name="startDate" onCustomChange={async () => await trigger("endDate")} control={control} isClearable/>
                  </Grid>
                  {/* Address Line 1 */}
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t("Crm.Campaigns.CampaignsForm.EndDate")}
                    </Label>
                    <Datepicker name="endDate" onCustomChange={async () => await trigger("startDate")} control={control} isClearable/>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t("Crm.Campaigns.CampaignsForm.TargetAudience")}
                    </Label>
                    <InputField
                      name="targetAudience"
                      placeholder={t(
                        "Crm.Campaigns.CampaignsForm.EnterTargetAudience"
                      )}
                      control={control}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t("Crm.Campaigns.CampaignsForm.ExpectedTargetAudience")}
                    </Label>
                    <InputField
                      name="expectedTargetAudiene"
                      onKeyDown={(e)=>allowCharactersOnlyForIntegerNumbers(e)}
                      placeholder={t(
                        "Crm.Campaigns.CampaignsForm.EnterExpectedTargetAudience"
                      )}
                      control={control}
                    />
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <div className="campaigns-form-container flex_box flex_box--column">
                <Grid container>
                  <Title>{t("Crm.Campaigns.CampaignsForm.Criteria")}</Title>
                </Grid>

                    
                <Grid container spacing="sm">
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Label required>
                      {t("Crm.Campaigns.CampaignsForm.BusinessType")}
                    </Label>
                    <Select
                      name="bussinessType"
                      options={businessTypeSelectList}
                      control={control}
                      placeholder={t(
                        "Crm.Campaigns.CampaignsForm.SelectBusinessType"
                      )}
                      isLoading={apiLoader}
                      isClearable
                    />
                  </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label required>
                        {t("Crm.Campaigns.CampaignsForm.Source")}
                      </Label>
                      <Select
                        name="source"
                        options={sourceSelectList}
                        control={control}
                        placeholder={t(
                          "Crm.Campaigns.CampaignsForm.SelectSource"
                        )}
                        isLoading={apiLoader}
                        isClearable
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label required>
                        {t(
                          "Crm.Campaigns.CampaignsForm.CriteriaTargetAudience"
                        )}
                      </Label>
                      <InputField
                        name="actualTargetAudiene"
                        control={control}
                        disabled
                      />
                    </Grid>

                    <Grid item xs={12} textAlign="center">
                      <Button disabled={!bussinessType || !source} onClick={(e)=>{onSubmitLeadCount(e)}}>
                        {t("CommonUtils.Button.Search")}
                      </Button>
                    </Grid>

                    
           
                  <Grid item xs={12} textAlign="center">
                    <Button type="submit" form="campaignsForm" disabled={!actualTargetAudiene}  onClick={handleSubmit(onSubmit)}>
                      {t("Crm.Campaigns.CampaignsForm.CreateCampaign")}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => reset({...defaultValues})}
                    >
                      {t("CommonUtils.Button.Reset")}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={(event: React.SyntheticEvent) => {
                        event.preventDefault();
                        navigate(AppRoutingConfig.APP_URL_CRM_CAMPAIGNS_MODULE_LIST);
                      }}
                    >
                      {t("CommonUtils.Button.Back")}
                    </Button>
                  </Grid>
                </Grid>
                {/* </form> */}
              </div>
            </Card>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CampaignsForm;
