import Actions from "../actions";
import { produce } from "immer";

interface State {
    hrDashboardDetails : {
        anniversaries:[];
        birthdays:[];
        leaves:[];
    }
}

const initialState: State = {
    hrDashboardDetails : {
        anniversaries:[],
        birthdays:[],
        leaves:[] 
    }   
}

const hrDashboard = produce((draft: State, action: any) => {
    const { payload } = action;
    switch (action.type) {

        case Actions.GET_HR_DASHBOARD_LIST: {
            draft.hrDashboardDetails = payload?.hrDashboardDetails;
            break;
        }

        default: {
            break;
        }
    }

}, initialState);
  
export default hrDashboard;