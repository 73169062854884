
import AppApiConfig from '../../../assets/config/AppApiConfig'
import { AddCampaignDetailTypes } from './campaignsServices'
import axiosConfigInstance from '../../../axios/axiosInstance'


export const getCampaigns = (dataToBeSent:{isActive:boolean, orderBy:boolean, pageNumber:number, pageSize:number,searchString:String,regionId:number}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_CAMPAIGNS_MODULE_LIST_URL,
    dataToBeSent
  )
}

export const addCampaignsDetail = (dataToBeSent:AddCampaignDetailTypes) => {
    return axiosConfigInstance.post(
      AppApiConfig.API_SAVE_CAMPAIGNS_DETAIL_URL,
      dataToBeSent
    )
  }

export const getSearchLeadCountDetail = (dataToBeSent:{bussinessTypeId:number, sourceId:number}) => {
    return axiosConfigInstance.post(
      AppApiConfig.API_LOAD_SEARCH_LEAD_COUNT_DETAIL_URL,
      dataToBeSent
    )
}  

  
export const getBusinessTypeSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_BUSUNESS_TYPE_LIST_URL,
    )
}

export const getSourceSelectList = ()=> {
  return axiosConfigInstance.post(
    AppApiConfig.API_SOURCE_LIST_URL
    )
}

export const getAgentNameSelectList = ()=> {
  return axiosConfigInstance.post(
    AppApiConfig.API_AGENT_NAME_LIST_URL
    )
}


export const getCampaignsSelectList = (dataToBeSent:{RegionId:string}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_CAMPAIGNS_LIST_URL,{},{params:dataToBeSent}
    )
}


export const addManualAssignDetails = (dataToBeSent:AddCampaignDetailTypes) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_SAVE_MENUAL_ASSIGN_DETAIL_URL,
    dataToBeSent
  )
}


export const addAssignAgentDetails = (dataToBeSent:AddCampaignDetailTypes) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_SAVE_ASSIGN_AGENT_DETAIL_URL,
    dataToBeSent
  )
}


export const getCampaignStatusSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_CAMPAIGN_STATUS_SELECT_LIST_URL,
  )
}


export const  updateCampaignStatus = (dataToBeSent:{campaignId:number, status: string}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_UPDATE_CAMPAIGN_STATUS_URL,
    dataToBeSent
  )
}

export const getCampaignDetail = (dataToBeSent:{CampaignId: number}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_LOAD_CAMPAIGN_DETAIL_URL,{},{params:dataToBeSent}
  )
}

export const manualAssignAgent = (dataToBeSent:{campaignId: number, agentId: number, leadId: number }) => {
  return axiosConfigInstance.post(AppApiConfig.API_SAVE_MANUAL_ASSIGN_AGENT_URL, dataToBeSent)
}

export const assignAgent = (dataToBeSent:{campaignId: number, agentId: number, assignLeadNo: number }) => {
  return axiosConfigInstance.post(AppApiConfig.API_SAVE_ASSIGN_AGENT_URL, dataToBeSent)
}

