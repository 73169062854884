import AppApiConfig from '../../../assets/config/AppApiConfig'
import axiosConfigInstance from '../../../axios/axiosInstance'
import { AddActivityTypes, GetActivityTypes } from './activityServices'

export const getActivities = (dataToBeSent:GetActivityTypes) => {
  return axiosConfigInstance.post(AppApiConfig.API_ACTIVITY_MODULE_LIST_URL, dataToBeSent)
}

export const addActivity = (dataToBeSent:AddActivityTypes) => {
  return axiosConfigInstance.post(AppApiConfig.API_SAVE_ACTIVITY_DETAIL_URL, dataToBeSent)
}

export const getActivityDetail = (dataToBeSent:{ActivityId:number}) => {
  return axiosConfigInstance.post(AppApiConfig.API_LOAD_ACTIVITY_DETAIL_URL, {}, {params:dataToBeSent})
}
