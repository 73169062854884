const GENERIC_SPECIAL_CHARACTERS_ARR = [ "Backspace", "Enter", "Tab", "ArrowLeft", "ArrowRight", "CapsLock" ];

export const allowCharactersOnlyForDecimalNumbers = (event:React.KeyboardEvent<HTMLInputElement>) => {
   

    var SPECIAL_CHARACTERS_ARR = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", ".", "-"];
    var CONS_SPECIAL_CHARACTERS_ARR = [ ...GENERIC_SPECIAL_CHARACTERS_ARR, ...SPECIAL_CHARACTERS_ARR ];

    let tempShouldAllowSpecialCharactersVal : boolean | void = false;

    if (event && event !== undefined) {
        tempShouldAllowSpecialCharactersVal = !CONS_SPECIAL_CHARACTERS_ARR.includes(event.key) && event.preventDefault();
    }

    return tempShouldAllowSpecialCharactersVal
}

export const allowCharactersOnlyForIntegerNumbers = (event:React.KeyboardEvent<HTMLInputElement>) => {

    const SPECIAL_CHARACTERS_ARR = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "-"];
    var CONS_SPECIAL_CHARACTERS_ARR = [ ...GENERIC_SPECIAL_CHARACTERS_ARR, ...SPECIAL_CHARACTERS_ARR ];

    let tempShouldAllowSpecialCharactersVal:boolean | void = false;

    if (event && event !== undefined) {
        tempShouldAllowSpecialCharactersVal = !CONS_SPECIAL_CHARACTERS_ARR.includes(event.key) && event.preventDefault();
    }

    return tempShouldAllowSpecialCharactersVal
}
