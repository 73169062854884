import AppModuleConfig from "../../assets/config/AppModuleConfig";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import { translate } from "../../i18n/i18n";

export const useLeaveMenuUtils = () => {
    const tempMenuGroup = [];

    /* menuItemHrLeaveManagement */
    const menuItemHrLeaveManagement = {
        id: "leaveManagement",
        title: AppModuleConfig.HR_MODULES.LEAVE_MANAGEMENT,
        url: AppRoutingConfig.APP_URL_LEAVE_MANAGEMENT_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemHrLeaveManagement);
    /* menuItemHrLeaveManagement */

    return tempMenuGroup;
};
