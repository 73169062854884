import { useEffect, useState } from 'react'
import useLocalStorage from './useLocalStorage'
import UserSessionService from '../services/UserSessionService';


// Custom hook for managing user authentication
const useAuthenticate = (): [boolean] => {
  const token = UserSessionService.getUserSessionToken()

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    if (!token) return false
    return true
  })

  // Effect to update the value whenever the token changes
  useEffect(() => {
    if (!token) {
      setIsAuthenticated(false)
    } else {
      setIsAuthenticated(true)
    }
  }, [token])

  // Return the user object
  return [isAuthenticated]
}

export default useAuthenticate
