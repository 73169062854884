import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import QuotesConfig from "./quotes/QuotesConfig";
import LeadConfig from "./lead/LeadConfig";
import { Navigate } from "react-router";
import SalesRepConfig from "./salesRep/SalesRepConfig";
import CustomersConfig from "./customers/CustomersConfig";
import ProjectsConfig from "./projects/ProjectsConfig";

const SalesConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_SALES,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_SALES_CUSTOMERS_MODULE_LIST} replace />
      ),
    },
    ...CustomersConfig.routes,
    ...LeadConfig.routes,
    ...QuotesConfig.routes,
    ...ProjectsConfig.routes,
    ...SalesRepConfig.routes
  ]
};

export default SalesConfig;
