
import Actions from "../actions";
import { produce } from "immer";


interface State {
  regions:{
    totalCount:number;
    regionDetails:[]
  };
  regionSelectList: [];
  countrySelectList: [];
  stateSelectList: [];
  citySelectList: [];
  selectedRegionId:number;
  regionDetails:{};
  selectedRegionStatus:boolean
}

const initialState: State = {
  regions:{
    totalCount:0,
    regionDetails:[]
  },
  regionSelectList: [],
  countrySelectList: [],
  stateSelectList: [],
  citySelectList: [],
  selectedRegionId:0,
  regionDetails:{},
  selectedRegionStatus:false
};


const region = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {

    case Actions.GET_REGIONS_LIST: {
      draft.regions = payload?.regions;
      break;
    }
    
    case Actions.GET_REGION_SELECT_LIST: {
      draft.regionSelectList = payload.regions
      break;
    }
    
    case Actions.SELECTED_REGION_ID: {
      draft.selectedRegionId = payload?.selectedRegionId;
      break;
    }

    case Actions.SELECTED_REGION_STATUS: {
      draft.selectedRegionStatus = payload?.selectedRegionStatus;
      break;
    }

    case Actions.GET_REGION_DETAIL: {
      draft.regionDetails = payload?.regionDetails;
      break;
    }

    case Actions.GET_COUNTRY_SELECT_LIST: {
      draft.countrySelectList = payload.countries
      break;
    }


    case Actions.GET_STATE_SELECT_LIST: {
      draft.stateSelectList = payload.states
      break;
    }

    case Actions.GET_CITY_SELECT_LIST: {
      draft.citySelectList = payload.cities
      break;
    }

    case Actions.RESET_STATE_SELECT_LIST:{
      draft.stateSelectList = [];
      break;
    
    }
    case Actions.RESET_CITY_SELECT_LIST:{
      draft.citySelectList = [];
      break;
    }
  }
}, initialState);

export default region;
