import AppApiConfig from '../../../assets/config/AppApiConfig'
import axiosConfigInstance from '../../../axios/axiosInstance'
import { AddRegionTypes } from './regionServices'

export const GET_REGION_SELECT_LIST_URL = AppApiConfig.API_REGION_SELECT_LIST_URL
export const GET_COUNTRY_SELECT_LIST_URL = AppApiConfig.API_COUNTRY_SELECT_LIST_URL
export const GET_STATE_SELECT_LIST_URL = AppApiConfig.API_STATE_SELECT_LIST_URL
export const GET_CITY_SELECT_LIST_URL = AppApiConfig.API_CITY_SELECT_LIST_URL


export const getRegions = (dataToBeSent: {isActive:boolean | null,orderBy: boolean;pageNumber: number;pageSize: number, searchString:string}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_REGION_MODULE_LIST_URL,
    dataToBeSent
  )
}

export const getRegionDetail = (dataToBeSent: {RegionId:number}) => {
  return axiosConfigInstance.post(AppApiConfig.API_LOAD_REGION_DETAIL_URL, {}, { params: dataToBeSent })
}

export const addRegion = (dataToBeSent: AddRegionTypes) => {
  return axiosConfigInstance.post(AppApiConfig.API_SAVE_REGION_MODULE_URL, dataToBeSent)
}

export const getRegionSelectList = () => {
  return axiosConfigInstance.post(
    GET_REGION_SELECT_LIST_URL
  )
}

export const changeRegionStatus = (dataToBeSent: {regionId:number, isActive:boolean}) => {
  return axiosConfigInstance.post(AppApiConfig.API_CHANGE_REGION_MODULE_STATUS_URL, dataToBeSent)
}

export const getCountrySelectList = () => {
  return axiosConfigInstance.post(
    GET_COUNTRY_SELECT_LIST_URL
  )
}

export const getStateSelectList = (dataToBeSent:{CountryId:number}) => {
  return axiosConfigInstance.post(
    GET_STATE_SELECT_LIST_URL,{},{params:dataToBeSent}
  )
}

export const getCitySelectList = (dataToBeSent:{StateId:number}) => {
  return axiosConfigInstance.post(
    GET_CITY_SELECT_LIST_URL,{},{params:dataToBeSent}
  )
}
