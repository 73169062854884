import React from "react";
import { Control, useController } from "react-hook-form";
import ErrorText from "../errorText";
import { isObjectEmpty } from "../../../utils/appCommonUtils";
import "./TextArea.scss";
import { sanitizeDataTypeClientSide } from "../../../utils/appDataSanitization";

export type InputProps = {
  name: string;
  control?: Control<any>;
  disabled?:boolean;
  onCustomChange?: (event:React.SyntheticEvent<HTMLTextAreaElement>) => void;
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextArea = ({ name, control, onCustomChange, disabled, ...props }: InputProps) => {
  const {
    field,
    formState: { errors },
  } = useController({ name, control });

  /* function definition to handle on change */
  const handleChange = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    field.onChange(sanitizeDataTypeClientSide(event));
    onCustomChange && onCustomChange(event)
  };
  /* function definition to handle on change */
  return (
    <>
      <textarea
        id={name}
        {...props}
        {...field}
        disabled={disabled}
        className={`text-area ${disabled && "text-area-disabled"} ${
          errors && errors[name]
            ? "text-area-error"
            : "text-area-outlined"
        }`}
        value={field.value}
        onChange={handleChange}
      />
      {!isObjectEmpty(errors) && errors[name] ? (
        <ErrorText>{`${errors[name]?.message}`}</ErrorText>
      ) : (
        ""
      )}
    </>
  );
};

export default TextArea;

