import Actions from "../../actions";
import * as requestFromServer from "./commonApis";
import { AxiosResponse } from "axios";

/* GET ROLE SELECT LIST */
export const getRoleSelectListAction =
  () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getRoleSelectList()
    response && dispatch(Actions.createAction(Actions.GET_ROLE_SELECT_LIST, {roles:response?.data}));
    return response 
  };

/* GET SUFFIX SELECT LIST */
export const getSuffixSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getSuffixSelectList()
    response && dispatch(Actions.createAction(Actions.GET_SUFFIX_SELECT_LIST, {suffix:response?.data}));
    return response 
  };

/* GET BASE WAGE TYPE SELECT LIST */
export const getBaseWageTypeSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getBaseWageTypeSelectList()
    response && dispatch(Actions.createAction(Actions.GET_BASE_WAGE_TYPE_SELECT_LIST, {baseWageTypes:response?.data}));
    return response 
  };

/* GET TYPE SELECT LIST */
export const getTypeSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getTypeSelectList()
    response && dispatch(Actions.createAction(Actions.GET_TYPE_SELECT_LIST, {types:response?.data}));
    return response 
  };

/* GET STATUS SELECT LIST */
export const getStatusSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getStatusSelectList()
    response && dispatch(Actions.createAction(Actions.GET_STATUS_SELECT_LIST, {status:response?.data}));
    return response 
  };

/* GET EDUCATION LEVEL SELECT LIST */
export const getEducationLevelSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getEducationLevelSelectList()
    response && dispatch(Actions.createAction(Actions.GET_EDUCATION_LEVEL_SELECT_LIST, {educationLevels:response?.data}));
    return response 
  };

/* GET EDUCATION LEVEL SELECT LIST */
export const getEducationDegreeSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getEducationDegreeSelectList()
    response && dispatch(Actions.createAction(Actions.GET_EDUCATION_DEGREE_SELECT_LIST, {educationDegrees:response?.data}));
    return response 
  };

/* GET REPORTING TO SELECT LIST */
export const getReportingToSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getReportingToSelectList()
    response && dispatch(Actions.createAction(Actions.GET_REPORTING_TO_SELECT_LIST, {reportingTo:response?.data}));
    return response 
  };


/* GET QUOTE TYPE SELECT LIST */
export const getQuoteTypeSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getQuoteTypeSelectList()
    response && dispatch(Actions.createAction(Actions.GET_QUOTE_TYPE_SELECT_LIST, {quoteTypes:response?.data}));
    return response 
  };

/* GET PROBABILITY SELECT LIST */
export const getProbabilitySelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getProbabilitySelectList()
    response && dispatch(Actions.createAction(Actions.GET_PROBABILITY_SELECT_LIST, {probabilities:response?.data}));
    return response 
  };

/* GET LEAD SOURCE SELECT LIST */
export const getLeadSourceSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getLeadSourceSelectList()
    response && dispatch(Actions.createAction(Actions.GET_LEAD_SOURCE_SELECT_LIST, {leadSources:response?.data}));
    return response 
  };

/* GET CUSTOMER TYPE SELECT LIST */
export const getCustomerTypeSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getCustomerTypeSelectList()
    response && dispatch(Actions.createAction(Actions.GET_CUSTOMER_TYPE_SELECT_LIST, {customerTypes:response?.data}));
    return response 
  };

/* GET STAGE SELECT LIST */
export const getStageSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getStageSelectList()
    response && dispatch(Actions.createAction(Actions.GET_STAGE_SELECT_LIST, {stages:response?.data}));
    return response 
  };


/* GET QUOTE STATUS SELECT LIST */
export const getQuoteStatusSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getQuoteStatusSelectList()
    response && dispatch(Actions.createAction(Actions.GET_QUOTE_STATUS_SELECT_LIST, {quoteStatus:response?.data}));
    return response 
  };

/* GET ACTIVITY TYPE SELECT LIST */
export const getActivityTypeSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getActivityTypeSelectList()
    response && dispatch(Actions.createAction(Actions.GET_ACTIVITY_TYPE_SELECT_LIST, {activityTypes:response?.data}));
    return response 
  };

/* GET UNIT OF MEASURE SELECT LIST */
export const getUnitOfMeasureSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getUnitOfMeasureSelectList()
    response && dispatch(Actions.createAction(Actions.GET_UNIT_OF_MEASURE_SELECT_LIST, {unitOfMeasures:response?.data}));
    return response 
  };

/* GET TITLE SELECT LIST */
export const getTitleSelectListAction =
  (
    dataToBeSent: { CustomerId: number }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getTitleSelectList(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_TITLE_SELECT_LIST, {titles:response?.data}));
    return response 
  };

/* GET PROJECT SCHEDULE STATUS SELECT LIST */
export const getProjectScheduleStatusSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getProjectScheduleStatusSelectList()
    response && dispatch(Actions.createAction(Actions.GET_PROJECT_SCHEDULE_STATUS_SELECT_LIST, {projectScheduleStatus:response?.data}));
    return response 
  };


  