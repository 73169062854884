import { createSelector } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/redux-hooks";
import Actions from "../actions";
import { produce } from "immer";

interface State {
  employees: {
    totalCount: number;
    userDetails: [];
  };
  employeeCode:string
  employeeManagementDetailPhase1:{};
  educationDetail:{level:{value:any,label:string},degree:{value:any,label:string}, dateCompleted:Date | null, decription:string}[];
  userFullNameSelectList:[]
  employeeManagementDetailsForSecondaryForm:{
    userId:number,
    employeeCode:string,
    departmentId:number
  }
  selectedEmployeeManagementId:number
}

const initialState: State = {
  employees: {
    totalCount: 0,
    userDetails: [],
  },
  employeeCode:"",
  employeeManagementDetailPhase1:{},
  educationDetail:[],
  userFullNameSelectList:[],
  employeeManagementDetailsForSecondaryForm:{
    userId:0,
    employeeCode:"",
    departmentId:0
  },
  selectedEmployeeManagementId:0
};


const employeeManagement = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_EMPLOYEE_MANAGEMENT_LIST: {
      draft.employees = payload.employees;
      break;
    }

    case Actions.GET_EMPLOYEE_CODE:{
      draft.employeeCode = payload?.userCode
      break;
    }

    case Actions.GET_EMPLOYEE_MANAGEMENT_DETAIL_PHASE1:{
      draft.employeeManagementDetailPhase1 = payload?.employeeManagementPhase1Details;
      break;
    }

    case Actions.EMPLOYEE_MANAGEMENT_DETAILS_FOR_SECONDARY_FORM: {
      draft.employeeManagementDetailsForSecondaryForm = payload.employeeManagementDetailsForSecondaryForm
      break;
    }

    case Actions.ADD_EMPLOYEE_MANAGEMENT_EDUCATION_DETAILS:{
      draft.educationDetail = [...draft.educationDetail,...payload.educationDetail]
      break;
    }

    case Actions.EMPTY_EMPLOYEE_MANAGEMENT_EDUCATION_DETAILS:{
      draft.educationDetail = []
      break;
    }

    case Actions.DELETE_EMPLOYEE_MANAGEMENT_EDUCATION_DETAILS:{
      const tempEducationDetail = draft.educationDetail.filter((element,index)=>{
        return index !== payload.selectedEducationDetail.index
      })
      draft.educationDetail = tempEducationDetail
      break;
    }

    case Actions.GET_USER_FULL_NAME_SELECT_LIST: {
      draft.userFullNameSelectList = payload.userFullNames;
      break;
    }

    case Actions.SELECTED_EMPLOYEE_MANAGEMENT_ID: {
      draft.selectedEmployeeManagementId = payload.selectedEmployeeManagementId
      break;
    }
    

    default: {
      break;
    }
  }
}, initialState);

export default employeeManagement;
