import { Navigate } from "react-router-dom";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { lazy } from "react";
import ProjectSchedulingForm from "./ProjectSchedulingForm";
import FieldDataForm from "./FieldDataForm";
import FieldDataList from "./FieldDataList";
import ProjectSchedulingDetail from "./ProjectSchedulingDetail";

const WorkFlowList = lazy(() => import("./WorkFlowList"));

const WorkFlowConfig = {
  routes: [
    {
      index: true,
      element: (
        <Navigate
          to={AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE_LIST}
          replace
        />
      ),
    },
    {
      path: AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE_LIST,
      element: <WorkFlowList />,
      handle: { crumb: () => "Work Flow" },
    },
    {
      path: AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE,
      element: <ProjectSchedulingForm />,
      handle: { crumb: () => "Add Project Scheduling" },
    },
    {
      path: AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE_DETAIL,
      element: <ProjectSchedulingDetail />,
      handle: { crumb: () => "Project Scheduling Detail" },
    },
    {
      path: AppRoutingConfig.APP_URL_FIELD_DATA_MODULE_LIST,
      element: <FieldDataList />,
      handle: { crumb: () => "Field Data" },
    },
    {
      path: AppRoutingConfig.APP_URL_ADD_FIELD_DATA_MODULE,
      element: <FieldDataForm />,
      handle: { crumb: () => "Add Field Data" },
    },
  ],
};

export default WorkFlowConfig;
