import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./campaignsApis";
import { AxiosResponse } from "axios";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";

/* GET CAMPAIGNS */
export const getCampaignsAction =
  (
    dataToBeSent: {
      isActive: boolean;
      orderBy: boolean;
      pageNumber: number;
      pageSize: number;
      searchString: String;
      regionId: number;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCampaigns(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_CAMPAIGNS_LIST, {campaigns: response?.data}));
    return response;
  };


/* GET SEARCH LEAD COUNT  */
export const getSearchLeadCountAction =
  (
    dataToBeSent: {
      bussinessTypeId:number,
      sourceId:number
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getSearchLeadCountDetail(dataToBeSent)
    response &&
      dispatch(Actions.createAction(Actions.GET_SEARCH_LEAD_COUNT_DETAIL, {searchLeadCountDetail: response?.data}));
    return response;
  };
  /* GET SEARCH LEAD COUNT  */


/* ADD CAMPAIGN DETAIL */
export type AddCampaignDetailTypes = {
  id: number;
  campaignName: string;
  startDate: string;
  endDate: string;
  targetAudience: string;
  expectedTargetAudiene: number;
  bussinessType: number;
  source: number;
  actualTargetAudiene: number;
};
export const addCampaignDetailAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddCampaignDetailTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addCampaignsDetail(dataToBeSent)
    dispatch(Actions.createAction(Actions.ADD_EDIT_CAMPAIGNS_DETAILS, {campaignsDetails: response?.data}));
    if (response) {
      navigate(AppRoutingConfig.APP_URL_CRM_CAMPAIGNS_MODULE_LIST);
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };

/* GET BUSINESS TYPE LIST */
export const getBusinessTypeListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getBusinessTypeSelectList()
    response && dispatch(Actions.createAction(Actions.GET_BUSINESS_TYPE_SELECT_LIST, {businessTypeSelectList :response?.data}));
    return response;
  };

/* GET BUSINESS TYPE LIST */

/* GET SOURCE TYPE LIST */
export const getSourceListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getSourceSelectList()
    response && dispatch(Actions.createAction(Actions.GET_SOURCE_SELECT_LIST, {sourceSelectList :response?.data }));
    return response;
  };

/* GET SOURCE TYPE LIST */


/* GET AGENT NAME LIST */
export const getAgentNameAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getAgentNameSelectList()
    response && dispatch(Actions.createAction(Actions.GET_AGENT_NAME_SELECT_LIST, {agentNameSelectList :response?.data}));
    return response;
  };

/* GET AGENT NAME LIST */



/* GET CAMPAIGN SELECT LIST */
export const getCampaignsSelectListAction =
  (
    dataToBeSent: { RegionId: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCampaignsSelectList(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_CAMPAIGNS_SELECT_LIST, {campaignsSelectList: response?.data}));
    return response;
  };

/* GET CAMPAIGN SELECT LIST */

/* ADD MENUAL ASSIGN DETAILS */
export type AddManualAssignDetailTypes = {
  id: number;
  campaignName: string;
  startDate: string;
  endDate: string;
  targetAudience: string;
  expectedTargetAudiene: number;
  bussinessType: number;
  source: number;
  actualTargetAudiene: number;
};

export const addManualAssignDetailsAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddManualAssignDetailTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addManualAssignDetails(dataToBeSent)
    if (response) {
      dispatch(Actions.createAction(Actions.ADD_MENUAL_ASSIGN_DETAILS, {manualAssignDetails: response?.data}));
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    } else {

    }
    return response;
  };

/* ADD MENUAL ASSIGN DETAILS */

/* ADD ASSIGN AGENT DETAILS */
export type AddAssignAgentDetailTypes = {
  id: number;
  campaignName: string;
  startDate: string;
  endDate: string;
  targetAudience: string;
  expectedTargetAudiene: number;
  bussinessType: number;
  source: number;
  actualTargetAudiene: number;
};

export const addAssignAgentDetailsAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddAssignAgentDetailTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addAssignAgentDetails(dataToBeSent)
    dispatch(Actions.createAction(Actions.ADD_ASSIGN_AGENT_DETAILS, {assignAgentsDetails: response?.data}));
    if (response) {
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };

/* ADD ASSIGN AGENT DETAILS */

/* MANUAL ASSIGN AGENT */
export const manualAssignAgentAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: {
        campaignId: number,
        agentId: number,
        leadId: number
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.manualAssignAgent(dataToBeSent)
    if (response) {
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };

/* MANUAL ASSIGN AGENT */

/* ASSIGN AGENT */
export const assignAgentAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: {
        campaignId: number,
        agentId: number,
        assignLeadNo: number
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.assignAgent(dataToBeSent)
    if (response) {
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };

/* ASSIGN AGENT */

/* GET STATUS LIST */
export const getCampaignStatusListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCampaignStatusSelectList()
    response &&
      dispatch(
        Actions.createAction(Actions.GET_CAMPAIGN_STATUS_SELECT_LIST, {
          campaignStatusSelectList: response?.data,
        })
      );
    return response;
  };

/* GET STATUS LIST */

/* UPDATE CAMPAIGN  STATUS */
export const updateCampaignStatusAction =
  (
    dataToBeSent: { campaignId: number; status: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.updateCampaignStatus(dataToBeSent)
    dispatch(Actions.createAction(Actions.UPDATE_CAMPAIGN_STATUS, {updateCampaignStatus: response?.data}));
    return response;
  };

/* UPDATE CAMPAIGN  STATUS */


/* CAMPAIGN DETAIL */
export const getCampaignDetailAction =
  (
    dataToBeSent: { CampaignId: number }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCampaignDetail(dataToBeSent)
    response &&
      dispatch(Actions.createAction(Actions.GET_CAMPAIGNS_DETAIL, {CampaignId: response?.data}));
    return response;
  };

/* CAMPAIGN DETAIL */
