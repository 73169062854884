import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./activityFieldApis";
import { AxiosResponse } from "axios";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";

/* GET ACTIVITY SELECT LIST */
export const getActivitySelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getActivitySelectList()
    response && dispatch(Actions.createAction(Actions.GET_ACTIVITY_SELECT_LIST, {activities:response?.data}));
    return response 
  };

/* ADD ACTIVITY FIELDS */
export type AddActivityFieldsTypes = {
  id: number;
  activityId:number;
  activityField:{id:number; fieldName:string}[];
};
export const addActivityFieldsAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddActivityFieldsTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addActivityFields(dataToBeSent)
    if (response) {
      navigate(AppRoutingConfig.APP_URL_ADMIN_ACTIVITY_MODULE_LIST)
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };