import React, { useEffect, useState } from "react";
import Dialog from "../../../../common/dialog";
import Title from "../../../../common/title";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Label from "../../../../common/label";
import Grid from "../../../../common/grid";
import Button from "../../../../common/button";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../../hooks/useToast";
import TableLoader from "../../../../common/tableLoader";
import { getAgentNameAction, manualAssignAgentAction } from "../../../../../redux/services/campaigns/campaignsServices";
import Select from "../../../../common/select";
import { SearchIcon } from "../../../../../assets/svgs/svg-components";
import { getLeadByCampaignIdSelectListAction } from "../../../../../redux/services/lead/leadServices";
import "./Campaigns.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

type DefaultValueTypes = {
  agent: null;
  lead: null;
};

const CampaignsDetailManualDialog = ({ open, onClose }: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* loading dependencies */
  const [loading, setLoading] = useState(false);
  /* loading dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  /* For navigating between pages */

  /* selected id dependencies */
  const { agentNameSelectList, selectedCampaignId } = useAppSelector(
    (state) => state.campaigns
  );
  /* selected id dependencies */

  /* select list dependencies */
  const { leadByCampaignIdSelectList } = useAppSelector(
    (state) => state.lead
    );
    /* select list dependencies */

  /* Dependencies for default values and validation */
  const defaultValues: DefaultValueTypes = {
    agent: null,
    lead: null,
  };

  const schema = yup.object().shape({
    agent: yup.object().required(t("Crm.Campaigns.CampaignsDetail.PleaseSelectAgentName")),
    lead: yup.object().required(t("Crm.Campaigns.CampaignsDetail.PleaseSelectLeadName"))
  });

  const { handleSubmit, control, clearErrors, reset } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  /* Dependencies for default values and validation */

  const selectListApiCalls = (details: any) => {
    dispatch(getAgentNameAction());
    dispatch(getLeadByCampaignIdSelectListAction({CampaignId : selectedCampaignId}));
  }

  useEffect(() => {
    selectListApiCalls(null)
  }, [open])

  /* function definition for dialog close to reset additional dependencies */
  const handleDialogClose = () => {
    onClose();
    reset();
    clearErrors();
  };
  /* function definition for dialog close to reset additional dependencies */

  /* Form submit handler */
  const onSubmit = async (formData: any) => {
    if(selectedCampaignId && selectedCampaignId !== 0){
      const dataToBeSent = {
        campaignId:selectedCampaignId,
        agentId:formData?.agent ? formData?.agent?.value : 0,
        leadId:formData?.lead ? formData?.lead?.value : 0,
      }
      await dispatch(manualAssignAgentAction(navigate, showToast, dataToBeSent))
      return handleDialogClose()
    }
  };
  /* Form submit handler */

  return (
    <Dialog open={open} onClose={handleDialogClose} width="xs">
      {loading ? (
        <TableLoader />
      ) : (
        <div className="campaigns-detail-manual-assign-form-container flex_box flex_box--column">
          <Title textDecoration="underline" justifyContent="center">
            {t("Crm.Campaigns.CampaignsDetail.ManualAssign")}
          </Title>
          <form
            onSubmit={handleSubmit(onSubmit)}
            name="campaignDetailManualAssignForm"
            id="campaignDetailManualAssignForm"
          >
            <Grid container spacing="sm" style={{ margin: "0 1rem" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Label required>
                  {t("Crm.Campaigns.CampaignsDetail.AgentName")}
                </Label>
                <Select
                name="agent"
                options={agentNameSelectList}
                control={control}
                isClearable
                placeholder={t("Crm.Campaigns.CampaignsDetail.SelectAgentName")}
                isLoading={loading}
                maxMenuHeight={150}
              />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Label required>
                  {t("Crm.Campaigns.CampaignsDetail.LeadName")}
                </Label>
                <Select
                name="lead"
                options={leadByCampaignIdSelectList}
                control={control}
                customIcon={<SearchIcon />}
                isClearable
                placeholder={t("Crm.Campaigns.CampaignsDetail.SelectLeadName")}
                isLoading={loading}
                menuPlacement="top"
                maxMenuHeight={150}
              />
              </Grid>

              <Grid item xs={12} textAlign="center">
                <Button type="submit" form="campaignDetailManualAssignForm">
                  {t("CommonUtils.Button.Assign")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Dialog>
  );
};

export default CampaignsDetailManualDialog;
