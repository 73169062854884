import { createSelector } from "@reduxjs/toolkit";
import { useAppSelector } from "../../hooks/redux-hooks";
import Actions from "../actions";
import { produce } from "immer";
import CampaignsDetails from '../../components/pages/modules/crm/campaigns/CampaignsDetail';

interface State {
  campaigns: {
    totalCount: number;
    campaignDetails: [];
  };
  campaignsDetails:[];
  businessTypeSelectList:[];
  sourceSelectList:[];
  assignAgentsDetails:[];
  manualAssignDetails:[];
  campaignsSelectList:[];
  campaignStatusSelectList: [];
  updateCampaignStatus : [];
  getCampaignsDetail:{
    leadDetails:[]
};
  selectedCampaignId:number;
  searchLeadCountDetail:[];
  agentNameSelectList : [];
}

const initialState: State = {
  campaigns: {
    totalCount: 0,
    campaignDetails: [],
  },
  campaignsDetails:[],
  businessTypeSelectList:[],
  sourceSelectList:[],
  assignAgentsDetails:[],
  manualAssignDetails:[],
  campaignsSelectList:[],
  campaignStatusSelectList: [],
  updateCampaignStatus : [],
  getCampaignsDetail:{
    leadDetails:[]
  },
  selectedCampaignId:0,
  searchLeadCountDetail:[],
  agentNameSelectList : [],
};


const campaigns = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_CAMPAIGNS_LIST: {
      draft.campaigns = payload?.campaigns;
      break;
    }

    case Actions.EMPTY_CAMPAIGNS_LIST: {
      draft.campaigns = {
        totalCount: 0,
        campaignDetails: []
      }
      break;
    }

    case Actions.ADD_EDIT_CAMPAIGNS_DETAILS:{
      draft.campaignsDetails = payload?.campaignsDetails
      break;
    }

    case Actions.GET_BUSINESS_TYPE_SELECT_LIST: {
      draft.businessTypeSelectList = payload?.businessTypeSelectList;
      break;
    }

    
    case Actions.GET_SOURCE_SELECT_LIST: {
      draft.sourceSelectList = payload?.sourceSelectList;
      break;
    }

    case Actions.GET_AGENT_NAME_SELECT_LIST: {
      draft.agentNameSelectList = payload?.agentNameSelectList;
      break;
    }

        
    case Actions.GET_CAMPAIGNS_SELECT_LIST: {
      draft.campaignsSelectList = payload?.campaignsSelectList;
      break;
    }

    case Actions.ADD_MENUAL_ASSIGN_DETAILS:{
      draft.manualAssignDetails = payload?.manualAssignDetails
      break;
    }

    case Actions.ADD_ASSIGN_AGENT_DETAILS:{
      draft.assignAgentsDetails = payload?.assignAgentsDetails
      break;
    }

    case Actions.GET_CAMPAIGN_STATUS_SELECT_LIST: {
      draft.campaignStatusSelectList = payload?.campaignStatusSelectList;
      break;
    }

    case Actions.UPDATE_CAMPAIGN_STATUS: {
      draft.updateCampaignStatus = payload;
      break;
    }

    case Actions.GET_CAMPAIGNS_DETAIL: {
      draft.getCampaignsDetail = payload?.CampaignId;
      break;
    }

    
    case Actions.SELECTED_CAMPAIGN_ID: {
      draft.selectedCampaignId = payload.selectedCampaignId;
      break;
    }

    case Actions.GET_SEARCH_LEAD_COUNT_DETAIL: {
      draft.searchLeadCountDetail = payload.searchLeadCountDetail;
      break;
    }




    default: {
      break;
    }
  }
}, initialState);

export default campaigns;
