import Actions from "../actions";
import { produce } from "immer";

type DocumentManagementDetailTypes = {
  id:number,
  user:{value:number,label:string}, 
  documentType:{value:number,label:string}, 
  expiryDate:string, 
  attachment:{
  attFileName: string,
  attFileObj: File,
  attFileSizeBytes: number,
  attFileImagePreview: string,
  isFileChanged:boolean
  },
}
interface State {
  employeeDocuments: {
    totalCount: number;
    userDocumentDetails: [];
  };
  companyDocuments: {
    totalCount: number;
    companyDocumentDetails: [];
  };
  documentTypeSelectList:[];
  documentManagementDetail:DocumentManagementDetailTypes[];
  selectedDocumentManagementId:number;
  selectedDocumentManagementStatus:string
}

const initialState: State = {
    employeeDocuments: {
    totalCount: 0,
    userDocumentDetails: [],
  },
    companyDocuments: {
    totalCount: 0,
    companyDocumentDetails: [],
  },
  documentTypeSelectList:[],
  documentManagementDetail:[],
  selectedDocumentManagementId:0,
  selectedDocumentManagementStatus:""
};

const documentManagement = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_EMPLOYEE_DOCUMENT_MANAGEMENT_LIST: {
      draft.employeeDocuments = payload;
      break;
    }

    case Actions.GET_COMPANY_DOCUMENT_MANAGEMENT_LIST: {
      draft.companyDocuments = payload;
      break;
    }

    case Actions.GET_DOCUMENT_TYPE_SELECT_LIST: {
      draft.documentTypeSelectList = payload.documentTypes;
      break;
    }

    case Actions.ADD_DOCUMENT_MANAGEMENT_DETAILS:{
      draft.documentManagementDetail = [...draft.documentManagementDetail,{...payload?.documentManagementDetail}]
      break;
    }

    case Actions.EMPTY_DOCUMENT_MANAGEMENT_DETAILS:{
      draft.documentManagementDetail = []
      break;
    }

    case Actions.DELETE_DOCUMENT_MANAGEMENT_DETAILS:{
      const tempDocumentManagementDetail = draft.documentManagementDetail?.filter((element,index)=>{
        return index !== payload?.selectedDocumentManagementDetail?.index
      })
      draft.documentManagementDetail = tempDocumentManagementDetail
      break;
    }

    case Actions.SELECTED_DOCUMENT_MANAGEMENT_ID: {
      draft.selectedDocumentManagementId = payload?.selectedDocumentManagementId;
      draft.selectedDocumentManagementStatus = payload?.selectedDocumentManagementStatus;
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default documentManagement;
