
/* function definition to trim/sanitize data type from client side */
export const sanitizeDataTypeClientSide = (event: React.SyntheticEvent) => {
    let value = (event.target as HTMLInputElement).value;
    if (value.length === 1 && value === " ") 
    {
        value = "";
    } 
    else if (value.length > 1 && value[0] === " " && value[value.length - 1] === " ") 
    {
        value = value.trim();
        const words = value.split(" ");
        const filteredWords = words.filter((word: any) => word !== "");
        value = filteredWords.join(" ");
    } 
    else if (value.length > 1 && value[0] === " ") 
    {
      value = value.trim();
    }
    return value
};

export const sanitizeDataTypeClientSideOnlyOneSpaceAllowed = (e: any) => {
    // get the value
    const value = e.target.value
    // check if the value isn't exactly ' ' and ends with a space
    const hasTrailingSpace = value !== ' ' && value.charAt(value.length-1) === ' '
    // extract words, remove empty words (handles double spaces)
    const words = value.split(' ').filter((el:any) => el !== '')
    // create the sanitized value
    let sanitizedValue = words.join(' ')
    // add a trailing space if there was one
    if( hasTrailingSpace ) sanitizedValue += ' '
    // apply the sanitized value to the input's value
    e.target.value = sanitizedValue
  }
/* function definition to trim/sanitize data type from client side */