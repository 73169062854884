import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserSessionService from "../../../services/UserSessionService";
import useClickOutside from "../../../hooks/useClickOutside";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import HeaderBreadCrumb from "../headerBreadCrumb";
import IconButton from "../iconButton";
import { LogoutIcon } from "../../../assets/svgs/svg-components";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import { useAppDispatch } from "../../../hooks/redux-hooks";
import Actions from "../../../redux/actions";
import "./Navbar.scss";

type Props = {};

const Navbar = (props: Props) => {


  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* user session dependencies */
  const getUserName = UserSessionService.getUserSessionName();
  const getUserEmail = UserSessionService.getUserSessionEmail();
  /* user session dependencies */

  const menuWrapperRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);

  /* navigation dependencies */
  const navigate = useNavigate();
  /* navigation dependencies */

  /* menu - open/close dependencies */
  const [menu, setMenu] = useState(false);
  /* menu - open/close dependencies */

  /* check for outside click to close the menu */
  useClickOutside(menuWrapperRef, () => {
    setMenu(false);
  });

  useClickOutside(buttonRef, () => {
    setMenu(false);
  });
  /* check for outside click to close the menu */

  /* function definition to remove the session - Logout */
  const handleRemoveUserSession = () => {
    UserSessionService.removeUserSession();
    dispatch(Actions.createAction(Actions.USER_PERMISSIONS, {}))
    navigate(AppRoutingConfig.APP_URL_SIGN_IN);
  };
  /* function definition to remove the session - Logout */

  return (
    <>
      <div className="header-wrapper">
        <div className="header-container">
          <HeaderBreadCrumb />
          <IconButton onClick={() => setMenu(!menu)}>
          <div
            ref={menuWrapperRef}
            className="profile-container flex_box flex_box--justify-center flex_box--items-center"
          >
            {getUserName.charAt(0).toUpperCase()}
          </div>
          </IconButton>
            <div
              className={`menu-wrapper ${menu ? "menu-wrapper-open" : ""}`}
              id="menu-wrapper"
            >
              <div className="menu flex_box flex_box--column">
                <div className="user-info flex_box flex_box--items-center flex_box--space-between">
                  <div className="profile-container inner-profile-container flex_box flex_box--justify-center flex_box--items-center">
                    {getUserName.charAt(0).toUpperCase()}
                  </div>
                  <div className="user-details">
                    <h3>{getUserName}</h3>
                    <h5>{getUserEmail}</h5>
                  </div>
                </div>
                <hr className="horizontal-rule" />

                <div
                  onClick={handleRemoveUserSession}
                  className="menu-link flex_box flex_box--items-center flex_box--justify-center"
                >
                  <div className="menu-link-icon flex_box flex_box--items-center flex_box--justify-center">
                    <LogoutIcon fillColor={AppConstConfig.LIGHT_GRAY_COLOR} />
                  </div>
                  <p className="menu-link-text">Logout</p>
                  <span className="menu-link-arrow">{">"}</span>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
