import React from "react";
import { Outlet } from "react-router-dom";
import "./AuthLayout.scss";
import AppAssetsConfig from "../../../assets/config/AppAssetsConfig";
import { useAppSelector } from "../../../hooks/redux-hooks";
import Loader from "../../common/loader";
import Grid from "../../common/grid";
import Card from "../../common/card";

type Props = {};

const AuthLayout = (props: Props) => {
  const { isLoading: isLoadingScreen } = useAppSelector(
    (state) => state.loadingScreen
  );

  return !isLoadingScreen ? (
    <div className="auth-layout-container">
      <Grid container spacing="sm">
        <Grid item xs={12} sm={12} md={12} lg={12} textAlign="center">
          <img
            src={AppAssetsConfig.DEFAULT_COMPANY_LOGO_WITH_NAME}
            className="auth-layout-image"
            alt="Ops-Acuity"
          />
        </Grid>
        <Card width="md">
          <Grid
            container
            spacing="md"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={10} md={5} lg={5}>
              <Outlet />
            </Grid>
            <Grid item xs={12} sm={10} md={6} lg={6} textAlign="center">
              <img
                src={AppAssetsConfig.DEFAULT_SIGN_IN_IMAGE}
                className="auth-layout-image"
                alt="Ops-Acuity"
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </div>
  ) : (
    <Loader />
  );
};

export default AuthLayout;
