export default class AppConfig{
    static SESSION_USER_NAME = 'userName'
    static SESSION_USER_LAST_NAME = 'userLastName'
    static SESSION_USER_EMAIL = 'userEmail'
    static SESSION_USER_MOBILE_NO = 'userMobileNo'
    static SESSION_USER_CODE = 'userCode'
    static SESSION_USER_ID = 'userId'
    static SESSION_USER_PERMISSION = 'userPermission' 
    static SESSION_USER_ROLE_NAME = "userRoleName"

    static SESSION_USER_KEY = 'token'
    static SESSION_USER_INFO = 'userDetail'
    static SESSION_IS_USER_LOGGED_IN = "isLoggedIn"
}