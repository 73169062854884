import AppApiConfig from '../../../assets/config/AppApiConfig'
import axiosConfigInstance from '../../../axios/axiosInstance'

export const GET_ROLE_SELECT_LIST_URL = AppApiConfig.API_ROLE_SELECT_LIST_URL
export const GET_SUFFIX_SELECT_LIST_URL = AppApiConfig.API_SUFFIX_SELECT_LIST_URL
export const GET_BASE_WAGE_TYPE_SELECT_LIST_URL = AppApiConfig.API_BASE_WAGE_TYPE_SELECT_LIST_URL
export const GET_TYPE_SELECT_LIST_URL = AppApiConfig.API_TYPE_SELECT_LIST_URL
export const GET_STATUS_SELECT_LIST_URL = AppApiConfig.API_STATUS_SELECT_LIST_URL
export const GET_EDUCATION_LEVEL_SELECT_LIST_URL = AppApiConfig.API_EDUCATION_LEVEL_SELECT_LIST_URL
export const GET_EDUCATION_DEGREE_SELECT_LIST_URL = AppApiConfig.API_EDUCATION_DEGREE_SELECT_LIST_URL
export const GET_REPORTING_TO_SELECT_LIST_URL = AppApiConfig.API_REPORTING_TO_SELECT_LIST_URL

export const getRoleSelectList = () => {
  return axiosConfigInstance.post(
    GET_ROLE_SELECT_LIST_URL
  )
}

export const getSuffixSelectList = () => {
  return axiosConfigInstance.post(
    GET_SUFFIX_SELECT_LIST_URL
  )
}

export const getBaseWageTypeSelectList = () => {
  return axiosConfigInstance.post(
    GET_BASE_WAGE_TYPE_SELECT_LIST_URL
  )
}

export const getTypeSelectList = () => {
  return axiosConfigInstance.post(
    GET_TYPE_SELECT_LIST_URL
  )
}

export const getStatusSelectList = () => {
  return axiosConfigInstance.post(
    GET_STATUS_SELECT_LIST_URL
  )
}

export const getEducationLevelSelectList = () => {
  return axiosConfigInstance.post(
    GET_EDUCATION_LEVEL_SELECT_LIST_URL
  )
}

export const getEducationDegreeSelectList = () => {
  return axiosConfigInstance.post(
    GET_EDUCATION_DEGREE_SELECT_LIST_URL
  )
}

export const getReportingToSelectList = () => {
  return axiosConfigInstance.post(
    GET_REPORTING_TO_SELECT_LIST_URL
  )
}
export const getQuoteTypeSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_QUOTE_TYPE_SELECT_LIST_URL
  )
}

export const getProbabilitySelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_PROBABILITY_SELECT_LIST_URL
  )
}

export const getLeadSourceSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_LEAD_SOURCE_SELECT_LIST_URL
  )
}

export const getCustomerTypeSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_CUSTOMER_TYPE_SELECT_LIST_URL
  )
}

export const getStageSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_STAGE_SELECT_LIST_URL
  )
}

export const getQuoteStatusSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_QUOTE_STATUS_SELECT_LIST_URL
  )
}

export const getActivityTypeSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_ACTIVITY_TYPE_SELECT_LIST_URL
  )
}

export const getUnitOfMeasureSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_UNIT_OF_MEASURE_SELECT_LIST_URL
  )
}

export const getTitleSelectList = (dataToBeSent:{CustomerId:number}) => {
  return axiosConfigInstance.post(AppApiConfig.API_TITLE_SELECT_LIST_URL, {}, {params:dataToBeSent})
}

export const getProjectScheduleStatusSelectList = () => {
  return axiosConfigInstance.post(AppApiConfig.API_PROJECT_SCHEDULE_STATUS_SELECT_LIST_URL)
}