import Actions from "../actions";
import { produce } from "immer";

interface State {
  fleetManagementList : {
    totalCount: number;
    vehicleDetailList:[];
  }
  vehicleTypeSelectList: [];
  vehicleClassSelectList: [];
  fuelTypeSelectList: [];
  selectedVehicleId:number;
  vehicleDetails:{};
  maintenanceAndRepairDetails:{};
  maintenanceAndRepairList : {
    totalCount: number;
    maintenanceDetail:[];
  }
  fuelManagementDetails:{};
  fuelManagementList : {
    totalCount: number;
    fuelDetailList:[];
  }
  tripManagementDetails:{};
  tripManagementList : {
    totalCount: number;
    tripDetailsList:[];
  }
  driverInformationDetails:{};
  driverInformationList : {
    totalCount: number;
    driverDetailList:[];
  }
  selectedUserId:number;
  selectedDetails : {
    notesTitle : string;
    viewDetails : string;
  } 
}

const initialState: State = {
    fleetManagementList : {
        totalCount: 0,
        vehicleDetailList:[]
    },
    vehicleTypeSelectList: [],
    vehicleClassSelectList:[],
    fuelTypeSelectList:[],
    selectedVehicleId:0,
    vehicleDetails:{},
    maintenanceAndRepairDetails:{},
    maintenanceAndRepairList : {
      totalCount: 0,
      maintenanceDetail:[]
    },
    fuelManagementDetails:{},
    fuelManagementList : {
    totalCount: 0,
    fuelDetailList:[]
   },
   tripManagementDetails:{},
   tripManagementList : {
    totalCount: 0,
    tripDetailsList:[]
   },
   driverInformationDetails:{},
   driverInformationList : {
    totalCount: 0,
    driverDetailList:[]
  },
  selectedUserId:0,
  selectedDetails : {
    notesTitle : '',
    viewDetails : ''
  } 
};

const fleetManagement = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_FLEET_MANANGEMENT_LIST: {
      draft.fleetManagementList = payload?.fleetManagement;
      break;
    }

    case Actions.GET_VEHICLE_TYPE_SELECT_LIST: {
      draft.vehicleTypeSelectList = payload.vehicleTypes
      break;
    }

    case Actions.GET_VEHICLE_CLASS_SELECT_LIST: {
      draft.vehicleClassSelectList = payload.vehicleClasses
      break;
    }

    case Actions.GET_FUEL_TYPE_SELECT_LIST: {
      draft.fuelTypeSelectList = payload.fuelTypes
      break;
    }

    case Actions.SELECTED_VEHICLE_ID: {
      draft.selectedVehicleId = payload?.selectedVehicleId;
      break;
    }

    case Actions.GET_VEHICLE_DETAILS: {
      draft.vehicleDetails = payload?.vehicleDetails;
      break;
    }

    case Actions.GET_MAINTENANCE_AND_REPAIRS_DETAILS: {
      draft.maintenanceAndRepairDetails = payload?.maintenanceAndRepairDetails;
      break;
    }

    case Actions.GET_MAINTENANCE_AND_REPAIRS_LIST: {
      draft.maintenanceAndRepairList = payload?.maintenanceAndRepairList;
      break;
    }

    case Actions.GET_FUEL_MANAGEMENT_DETAILS: {
      draft.fuelManagementDetails = payload?.fuelManagementDetails;
      break;
    }

    case Actions.GET_FUEL_MANAGEMENT_LIST: {
      draft.fuelManagementList = payload?.fuelManagementList;
      break;
    }

    case Actions.GET_TRIP_MANAGEMENT_DETAILS: {
      draft.tripManagementDetails = payload?.tripManagementDetails;
      break;
    }

    case Actions.GET_TRIP_MANAGEMENT_LIST: {
      draft.tripManagementList = payload?.tripManagementList;
      break;
    }

    case Actions.GET_DRIVER_INFORMATION_DETAILS: {
      draft.driverInformationDetails = payload?.driverInformationDetails;
      break;
    }

    case Actions.GET_DRIVER_INFORMATION_LIST: {
      draft.driverInformationList = payload?.driverInformationList;
      break;
    }

    case Actions.SELECTED_USER_ID: {
      draft.selectedUserId = payload?.selectedUserId;
      break;
    }

    case Actions.SELECTED_DETAILS: {
      draft.selectedDetails = payload?.selectedDetails;
      break;
    }

    case Actions.EMPTY_SELECTED_DETAILS: {
      draft.selectedDetails = { notesTitle : '', viewDetails : ''} 
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default fleetManagement;
