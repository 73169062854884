import AppAssetsConfig from "../assets/config/AppAssetsConfig";

/* FILE EXTENSIONS FOR IDENTIFICATION */
const VALID_FILES_EXTENSION_ARR = [
  "png",
  "jpg",
  "jpeg",
  "pdf",
  "doc",
  "docx"
];
const IMG_EXTENSION_ARR = ["png", "jpg", "jpeg", "bmp", "gif"];
const VID_EXTENSION_ARR = ["mp4"];
const PDF_EXTENSION_ARR = ["pdf"];
const TXT_EXTENSION_ARR = ["txt"];
const DOC_EXTENSION_ARR = ["doc", "docx"];
const XLS_EXTENSION_ARR = ["xls", "xlsx", "csv"];
const PPT_EXTENSION_ARR = ["ppt", "pptx"];
/* FILE EXTENSIONS FOR IDENTIFICATION */

/* function definition to check if the attachment is valid */
export const checkIfFileTypeIsValidForAttachment = (fileName: string) => {
  let fileExtension = getFileExtensionFromFileName(fileName);

  let isValidAttachment = false;
  let isInvalidMessage = "";

  if (VALID_FILES_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    isValidAttachment = true;
  } else {
    isInvalidMessage = "Invalid Attachment";
  }

  return {
    isValid: isValidAttachment,
    isInvalidMessage,
  };
};
/* function definition to check if the attachment is valid */

/* function definition to check if the attachment is valid */
export const checkIfFileTypeIsValidForExcelAttachment = (fileName: string) => {
  let fileExtension = getFileExtensionFromFileName(fileName);

  let isValidAttachment = false;
  let isInvalidMessage = "";

  if (XLS_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    isValidAttachment = true;
  } else {
    isInvalidMessage = 'Please upload proper attachment having "xls", "xlsx", "csv" file extensions. ';
  }

  return {
    isValid: isValidAttachment,
    isInvalidMessage,
  };
};
/* function definition to check if the attachment is valid */

/* function definition to check if the attachment size is valid */
export const checkIfFileSizeIsValidForAttachment = (
  fileSizeInBytes: number
) => {
  const MAX_ATTACHMENT_SIZE_IN_MB = 5;
  const MAX_ATTACHMENT_SIZE_IN_BYTES = MAX_ATTACHMENT_SIZE_IN_MB * 1000000;

  let isValidAttachment = false;
  let isInvalidMessage = "";
  if (fileSizeInBytes <= MAX_ATTACHMENT_SIZE_IN_BYTES) {
    isValidAttachment = true;
  } else {
    isInvalidMessage =
      "File cannot be bigger than " + MAX_ATTACHMENT_SIZE_IN_MB + " MB(s)";
  }

  return {
    isValid: isValidAttachment,
    isInvalidMessage,
  };
};
/* function definition to check if the attachment size is valid */

/* Function definition to get the file extension from file name */
export const getFileExtensionFromFileName = (fileName: string) => {
  let fileExtension = "";

  if (fileName && fileName !== "") {
    let tempFileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);

    if (tempFileExtension && tempFileExtension !== "") {
      fileExtension = tempFileExtension;
      fileExtension = fileExtension.toLowerCase();
    }
  }
  return fileExtension;
};
/* Function definition to get the file extension from file name */

/* Function definition to check if the file type is image */
export const checkIfFileIsTypeImageFromFileName = (fileName: string) => {
  let isFileTypeImage = false;
  let fileExtension = getFileExtensionFromFileName(fileName);

  if (IMG_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    isFileTypeImage = true;
  }
  return isFileTypeImage;
};
/* Function definition to check if the file type is image */

/* For generating file preview from file of type image */
export const readImageFileAsync = async (file: File) => {
  return new Promise(async (resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};
/* For generating file preview from file of type image */

/* Function definition to generate image preview from file name */
export const formulateFileImagePreviewFromFileName = (fileName: string) => {
  let filePreviewImage = "";
  let fileExtension = getFileExtensionFromFileName(fileName);
  
  if (IMG_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    filePreviewImage = AppAssetsConfig.IMAGE_TYPE_FILE_PREVIEW;
  } else if (PDF_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    filePreviewImage = AppAssetsConfig.PDF_TYPE_FILE_PREVIEW;
  } else if (TXT_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    filePreviewImage = AppAssetsConfig.TXT_TYPE_FILE_PREVIEW;
  } else if (DOC_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    filePreviewImage = AppAssetsConfig.DOC_TYPE_FILE_PREVIEW;
  } else if (XLS_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    filePreviewImage = AppAssetsConfig.XLS_TYPE_FILE_PREVIEW;
  } else if (PPT_EXTENSION_ARR.indexOf(fileExtension) >= 0) {
    /* For generating file preview from file of type image */
    filePreviewImage = AppAssetsConfig.PPT_TYPE_FILE_PREVIEW;
  }else{
    filePreviewImage = AppAssetsConfig.RANDOM_TYPE_FILE_PREVIEW;
  }
  
  return filePreviewImage;
};
/* Function definition to generate image preview from file name */

/* Function definition to formulate image preview of the attachment */
export const formulateAttachmentPreview = (
  attachmentFileObj: File | null,
  attachmentFileName: string,
  attachmentFileUrl:string
) => {
  return new Promise(async (resolve, reject) => {
    let fileImagePreview: any = "";
    if (attachmentFileObj){
      attachmentFileName = attachmentFileObj.name;
    } 
      let isFileTypeImage = checkIfFileIsTypeImageFromFileName(attachmentFileName);

      if (isFileTypeImage === true) {
        if(attachmentFileObj){
          await readImageFileAsync(attachmentFileObj).then(
            (fileReadResponse: unknown) => {
              fileImagePreview = fileReadResponse;
  
              if (!fileImagePreview || fileImagePreview === "") {
                fileImagePreview = formulateFileImagePreviewFromFileName(attachmentFileName);
              }
              resolve(fileImagePreview);
            }
          );
        }
        else if(attachmentFileUrl && attachmentFileUrl!=="") {
          fileImagePreview = attachmentFileUrl
          resolve(fileImagePreview)
        }
      } else {
        fileImagePreview =
          formulateFileImagePreviewFromFileName(attachmentFileName);
        resolve(fileImagePreview);
      }
  });
};
/* Function definition to formulate image preview of the attachment */


/* Function definition for getting the file size readable string for user */
export const getFileSizeStrFromBytes = (sizeBytes:number) => {
  let sizeStr = '-';
  let sizeKb = getFileSizeInKBFromBytes(sizeBytes);
  if(sizeKb > 0){
      if(sizeKb >= 1000){
          let sizeMb = getFileSizeInMBFromKB(sizeKb);
          sizeStr = sizeMb + ' MB';
      }
      else {
          sizeStr = sizeKb + ' KB';
      }
  }
  return sizeStr;
};
/* Function definition for getting the file size readable string for user */

/* Function definition for getting the file size in KBs from bytes */
export const getFileSizeInKBFromBytes = (sizeBytes:number) => {
  let sizeKb = 0;
  if(sizeBytes !== undefined && sizeBytes > 0) 
  {
      sizeKb = sizeBytes * 0.001;
      sizeKb = Math.ceil(sizeKb);
      if(sizeKb < 0)
      {
          sizeKb = 0;
      }
  }
  return sizeKb;
};
/* Function definition for getting the file size in KBs from bytes */

/* Function definition for getting the file size in MBs from KBs */
export const getFileSizeInMBFromKB = (sizeKb:number) => {
  let sizeMb = 0;
  if(sizeKb !== undefined && sizeKb > 0) 
  {
      sizeMb = sizeKb * 0.001;
      sizeMb = Math.ceil(sizeMb);
      if(sizeMb < 0)
      {
          sizeMb = 0;
      }
  }
  return sizeMb;
};
/* Function definition for getting the file size in MBs from KBs */
