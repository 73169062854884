import React, { useEffect, useState } from "react";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../common/button";
import InputField from "../../../../common/inputField";
import Select from "../../../../common/select";
import { getProjectListAction } from "../../../../../redux/services/projectSummary/projectSummaryServices";
import {
  getFilteredObjectFromId,
} from "../../../../../utils/appCommonUtils";
import { addFieldDataDetailAction, getFieldDataDetailAction } from "../../../../../redux/services/workflow/workFlowServices";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import TableLoader from "../../../../common/tableLoader";
import { useToast } from "../../../../../hooks/useToast";
import { allowCharactersOnlyForIntegerNumbers } from "../../../../../utils/appKeyStrokeLimitingUtils";
import { getActivitySelectListAction } from "../../../../../redux/services/activityField/activityFieldServices";
import { getActivityDetailAction } from "../../../../../redux/services/activity/activityServices";
import "./WorkFlow.scss";

type Props = {};

type SelectListProps = { value: any; label: string };

type DefaultValueTypes = {
  id: number;
  projectId: null;
  item: string;
  activity: null;
  dynamicFields: { id: number, fieldName: string}[],
};

const FieldDataForm = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false)
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  const [submitLoader, setSubmitLoader] = useState(false);
  /* loading dependencies */


  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  const params = location?.state;
  const { showToast } = useToast();
  /* For navigating between pages */

  /* searchable select dependencies */
  const {projectSelect} = useAppSelector((state) => state.workFlow);
  const { activities } = useAppSelector((state) => state.activityField);
  /* searchable select dependencies */

  /* dynamic dependencies */
  const [activityFieldData, setActivityFieldData] = useState([])
  /* dynamic dependencies */

  /* Dependencies for default values and validation */

  const defaultValues: DefaultValueTypes = {
    id: 0,
    projectId: null,
    item: "",
    activity: null,
    dynamicFields: []
  };

  const schema = yup.object().shape({
    projectId: yup
      .object()
      .required(t("Operations.WorkFlow.FieldDataForm.ProjectIsRequired")),
    item: yup
      .string()
      .max(20,`${t("Operations.WorkFlow.FieldDataForm.Item")} ${t(`CommonUtils.Validations.MustNotExceed20Characters`)}`)
      .required(t("Operations.WorkFlow.FieldDataForm.ItemIsRequired")),
    activity: yup
      .object()
      .required(t("Operations.WorkFlow.FieldDataForm.ActivityIsRequired"))
  });

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { projectId } = watch()

  const { fields, append, remove, update, replace } = useFieldArray({
    control,
    name: "dynamicFields"
  });
  /* Dependencies for default values and validation */
  
  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {

    // Project select list
    dispatch(getProjectListAction({CustomerId: ""})).then((apiRes) => {
      if (details) {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.projectId, apiRes?.data).then(
            (project: any) => {
              setValue("projectId", project);
            }
          );
      }
    });

    // Activity category select list
    dispatch(getActivitySelectListAction()).then((apiRes) => {
      details && apiRes?.data && getFilteredObjectFromId(details?.activityId, apiRes?.data).then(
          (activity: any) => {
            setValue("activity", activity)
            const selectedActivityId = activity?.value;
            const dataToBeSent = { ActivityId: selectedActivityId };
            
            
          }
        );
    });
  };
  /* function definition for select list API calls */

  /* load initial dependencies - Add */
  useEffect(() => {
    if (!params || params === null || params === undefined) {
      selectListApiCalls(null);
    }
  }, []);
  /* load initial dependencies - Add */

  /* load initial dependencies - Edit */
  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { fieldDataId } = params;
      setLoadingComponent(true)
      const dataToBeSent = { FieldDataId: fieldDataId };
      dispatch(
        getFieldDataDetailAction(navigate, showToast, setLoadingComponent, dataToBeSent)
      ).then((apiRes) => {
        const fieldDataDetail  = apiRes?.data;
        if (fieldDataDetail) {
          const { fieldData, dynamicField } = fieldDataDetail;
          selectListApiCalls(fieldData);

          const filteredDynamicFields = dynamicField?.map((element:{activityFieldId: number; fieldName : string; fieldValue : string})=>({id:element.activityFieldId, fieldName:element.fieldName}))

          replace(filteredDynamicFields)

          
          const resetAllFields = (dynamicField:any) => {
            const fieldValues = dynamicField?.reduce((accumulator:any, element:any) => {
              accumulator[element.fieldName] = element.fieldValue;
              return accumulator;
            }, {});
          
            reset({
              ...fieldValues, 
              id: fieldDataId,
              dynamicFields:filteredDynamicFields,
              item:fieldData?.item
            });
          };

          resetAllFields(dynamicField)
          setLoadingComponent(false)
        }
      });
    }
  }, []);
  /* load initial dependencies - Edit */

  /* On Activity changed function */
  const onActivityChanged = (
    option: SelectListProps,
    isClearAction: boolean
  ) => {
    if (option && option !== null && option !== undefined) {
      reset({...defaultValues},{keepDirtyValues:true})
      const selectedActivityId = option?.value;
      const dataToBeSent = { ActivityId: selectedActivityId };

      dispatch(getActivityDetailAction(dataToBeSent)).then((apiRes: any) => {
          const activityDetail = apiRes?.data;
          if (activityDetail) {
            const {activityFieldDataList, ...rest} = activityDetail;
            append(activityFieldDataList)
          }
      });
    }
    if (isClearAction) {
      remove()
      reset({...defaultValues},{keepDirtyValues:true})
    }
  };

  /* Form submit handler */
  const onSubmit = (formData: any) => {
    setSubmitLoader(true)
    const { id, projectId, activity, item, dynamicFields } = formData;
    
    const filteredDynamicFields = dynamicFields?.map((element:{ id: number, fieldName: string})=>{
      for (const key in formData){
        if(key === element.fieldName) return {activityFieldId:element.id, value:formData[key] || ""}
      }
    })

    const dataToBeSent = {
      id, item,
      projectId: projectId ? projectId?.value : 0, 
      activityId: activity ? activity?.value : 0,  
      dynamicFields:filteredDynamicFields
    }
    
    dispatch(addFieldDataDetailAction(navigate,showToast, dataToBeSent)).then(()=>{
      setSubmitLoader(false)
    })
  };

  /* Form submit handler */

  return loadingComponent ? (
    <Card>
      <TableLoader />
    </Card>
  ) : (
    <Card>
      <div className="workflow-form-container flex_box flex_box--column">
        <Grid container>
          <Title> {t("Operations.WorkFlow.FieldDataForm.AddFieldData")}</Title>
        </Grid>
        <form
          onSubmit={handleSubmit(onSubmit)}
          name="fieldDataForm"
          id="fieldDataForm"
        >
          <Grid container spacing="sm">
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.WorkFlow.FieldDataForm.Project")}
              </Label>

              <Select
                name="projectId"
                options={projectSelect}
                control={control}
                isClearable
                isLoading={apiLoader}
                placeholder={t("Operations.WorkFlow.FieldDataForm.SelectProject")}
                isDisabled={
                  !params || params === null || params === undefined
                    ? false
                    : true
                }
              />
            </Grid>

            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Operations.WorkFlow.FieldDataForm.Item")}</Label>
              <InputField
                name="item"
                control={control}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterItem")}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>
                {t("Operations.WorkFlow.FieldDataForm.Activity")}
              </Label>

              <Select
                name="activity"
                options={activities}
                control={control}
                isClearable
                isLoading={apiLoader}
                placeholder={t("Operations.WorkFlow.FieldDataForm.EnterActivity")}
                onCustomChange={(option, isClearAction) => {
                  onActivityChanged(option, isClearAction);
                }}
                isDisabled={
                  !params || params === null || params === undefined
                    ? false
                    : true
                }
              />
            </Grid>

            {
              fields?.map((element)=>{
                return (
                  <Grid item xs={12} sm={8} md={6} lg={4} key={element.id}>
                  <Label >{element?.fieldName}</Label>
                  <InputField
                    name={`${String(element?.fieldName)}`}
                    control={control}
                    placeholder={`Enter ${element?.fieldName}`}
                  />
                </Grid>
                )
              })
            }
            <Grid item xs={12} textAlign="center">
              <Button type="submit" form="fieldDataForm" loading={submitLoader}
                disabled={submitLoader}>
                {t("CommonUtils.Button.Submit")}
              </Button>
              {/* <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  params && params?.fieldDataId !== null && params?.fieldDataId !== undefined? reset( {...defaultValues,projectId:projectId}):reset({...defaultValues})
                }}
              >
                {t("CommonUtils.Button.Reset")}
              </Button> */}
              <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  navigate(AppRoutingConfig.APP_URL_FIELD_DATA_MODULE_LIST);
                }}
              >
                {t("CommonUtils.Button.Back")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Card>
  );
};

export default FieldDataForm;
