import React, {  useEffect, useState } from 'react'
import Button from '../../../../common/button'
import Grid from '../../../../common/grid'
import Label from '../../../../common/label'
import Card from '../../../../common/card'
import TableLoader from '../../../../common/tableLoader'
import { useTranslation } from 'react-i18next'
import { useToast } from '../../../../../hooks/useToast'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks'
import {  useLocation, useNavigate } from 'react-router'
import { addCommaDollarAndDecimalInNumber, getFilteredObjectFromId, isoToDispViewCustomDate } from '../../../../../utils/appCommonUtils'
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig'
import { getCitySelectListAction, getCountrySelectListAction, getStateSelectListAction } from '../../../../../redux/services/region/regionServices'
import { getCustomerListAction, getProjectSummaryDetailAction, getStatusListAction } from '../../../../../redux/services/projectSummary/projectSummaryServices'

type Props = {}

const ProjectSummaryDetails = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  /* loading dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  /* For navigating between pages */

  /* detail page dependencies */
  const [projectSummaryDetails, setProjectSummaryDetails] = useState<any>()
  const [projectSummarySelectDetails, setProjectSummarySelectDetails] = useState({
    country:"",
    state:"",
    city:"",
    customer:"",
    status:""
  })
  /* detail page dependencies */

  /* searchable select dependencies */
  const {
    selectedProjectSummaryId,
  } = useAppSelector((state) => state.projectSummary);
  /* searchable select dependencies */


  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {
   // Country select list
   dispatch(getCountrySelectListAction()).then(
    (apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.country, apiRes?.data).then(
          (country: any) => {
            country && loadDispatchStateForCountry(country);
            setProjectSummarySelectDetails((prevValue)=>({...prevValue,country:country?.label}))
          }
        );
    }
  );


  
    // State select list based on country
    const loadDispatchStateForCountry = (country: {
        value: any;
        label: string;
      }) => {
        dispatch(getStateSelectListAction({CountryId: country?.value})).then((apiRes) => {
          details &&
            apiRes?.data &&
            getFilteredObjectFromId(details?.state, apiRes?.data).then(
              (state: any) => {
                state && loadDispatchCityForState(state);
                setProjectSummarySelectDetails((prevValue)=>({...prevValue,state:state?.label}))
              }
            );
        });
      };

  // City select list based on state
    const loadDispatchCityForState = (state: { value: any; label: string }) => {
      dispatch(getCitySelectListAction({StateId: state?.value})).then((apiRes) => {
          details &&
            apiRes?.data &&
            getFilteredObjectFromId(details?.city, apiRes?.data).then(
              (city: any) => {
                setProjectSummarySelectDetails((prevValue)=>({...prevValue,city:city?.label}))
              }
            );
        });
      };

        // Customer select list
    dispatch(
        getCustomerListAction({ RegionId: "" })
      ).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.customerId, apiRes?.data).then(
            (customer: any) => {
                setProjectSummarySelectDetails((prevValue)=>({...prevValue,customer:customer?.label}))
            }
          );
      });
  
      // status select list
      dispatch(getStatusListAction()).then(
        (apiRes) => {
          details &&
            apiRes?.data &&
            getFilteredObjectFromId(details?.status, apiRes?.data).then(
              (status: any) => {
                setProjectSummarySelectDetails((prevValue)=>({...prevValue,status:status?.label}))
              }
            );
        }
      );
  };
  /* function definition for select list API calls */

   useEffect(() => {

    /* load initial dependencies - Edit */
    if (selectedProjectSummaryId && selectedProjectSummaryId !== 0) {
      setLoadingComponent(true)
      dispatch(
        getProjectSummaryDetailAction({
          projectId: selectedProjectSummaryId,
        })).then((apiRes) => {
          const userProjectSummaryDetail = apiRes?.data;
          if (userProjectSummaryDetail) {
            selectListApiCalls(userProjectSummaryDetail);
            setProjectSummaryDetails(userProjectSummaryDetail)
            setLoadingComponent(false)
            }
          });
      }
    
    /* load initial dependencies - Edit */
  }, []);


  return (
    <Card>
      {
        loadingComponent ? 
        <TableLoader/>
        :
      <div className="project-summary-form-container flex_box flex_box--column">
          <Grid container spacing="sm">

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.ProjectNo")}</Label>
              <div className='label_info'>{projectSummaryDetails?.projectNo || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.ProjectName")}</Label>
              <div className='label_info'>{projectSummaryDetails?.projectName || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.ServiceOrderNo")}</Label>
              <div className='label_info'>{projectSummaryDetails?.serviceOrderNo || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.Po")}</Label>
              <div className='label_info'>{projectSummaryDetails?.po || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.Customer")}</Label>
              <div className='label_info'>{projectSummarySelectDetails?.customer || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.AddressLine1")}</Label>
              <div className='label_info'>{projectSummaryDetails?.addressLine1 || "-"}</div>
            </Grid>
            
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.AddressLine2")}</Label>
              <div className='label_info'>{projectSummaryDetails?.addressLine2 || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.Country")}</Label>
              <div className='label_info'>{projectSummarySelectDetails?.country || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.State")}</Label>
              <div className='label_info'>{projectSummarySelectDetails?.state || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.City")}</Label>
              <div className='label_info'>{projectSummarySelectDetails?.city || "-"}</div>
            </Grid>

            
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.ZipCode")}</Label>
              <div className='label_info'>{projectSummaryDetails?.zipCode || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.Status")}</Label>
              <div className='label_info'>{projectSummarySelectDetails?.status || "-"}</div>
            </Grid>

            
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.Amount")}</Label>
              <div className='label_info'>{addCommaDollarAndDecimalInNumber(projectSummaryDetails?.amount) || "-"}</div>
            </Grid>

                 
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.StartDate")}</Label>
              <div className='label_info'>{isoToDispViewCustomDate(projectSummaryDetails?.startDate) || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.EndDate")}</Label>
              <div className='label_info'>{isoToDispViewCustomDate(projectSummaryDetails?.endDate) || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.TypeofWork")}</Label>
              <div className='label_info'>{projectSummaryDetails?.typeOfWork || "-"}</div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label >{t("Operations.ProjectSummary.ProjectSummaryForm.WorkDetails")}</Label>
              <div className='label_info'>{projectSummaryDetails?.workDetails || "-"}</div>
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Button variant="outlined" onClick={(event:React.SyntheticEvent)=>{
                event.preventDefault()
                if(location.pathname.includes("sales")){
                  navigate(AppRoutingConfig.APP_URL_SALES_PROJECTS_MODULE_LIST);
                }
                else {
                  navigate(AppRoutingConfig.APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_LIST);
                }
              }}>
                {t("CommonUtils.Button.Back")}
              </Button>
            </Grid>
          </Grid>
      </div>
      }
    </Card>
  )
}

export default ProjectSummaryDetails