import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./leadApis";
import { Dispatch, SetStateAction } from "react";
import { AxiosResponse } from "axios";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";

//GET LEAD LIST
export const getLeadDetaiListAction =
  (
    dataToBeSent: {
      isActive: null;
      orderBy: boolean;
      pageNumber: number;
      pageSize: number;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getLeads(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_LEAD_DETAIL_LIST, {leadDetails: response?.data}));
    return response;
  };
//GET LEAD LIST

//ADD EDIT LEAD
export const addLeadAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: FormData
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addLead(dataToBeSent)
    if(response){
      navigate(AppRoutingConfig.APP_URL_SALES_LEAD_MODULE_LIST);
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };
//ADD EDIT LEAD

//LEAD SOURCE SELECT
export const getLeadSourceListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getLeadSourceSelectList()
    response && dispatch(Actions.createAction(Actions.GET_LEAD_SOURCE_SELECT_LIST, {selectLeadSource: response?.data}));
    return response;
  };
//LEAD SOURCE SELECT
//STAGE LIST
export const getStageListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getStageSelectList()
    response && dispatch(Actions.createAction(Actions.GET_STAGE_SELECT_LIST, {stages: response?.data}));
    return response;
  };
//CUSTOMER TYPE LIST
export const getCustomerTypeListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCustomerTypeSelectList()
    response && dispatch(Actions.createAction(Actions.GET_CUSTOMER_TYPE_SELECT_LIST, {customerTypes: response?.data}));
    return response;
  };

//GET LEAD DETAIL
export const getLeadDetailAction =
  (
    dataToBeSent: { LeadId: number }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getLeadDetail(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_LEAD_DETAIL, {leadDetail: response?.data}));
    return response;
  };

//LEAD STATUS LIST
export const getLeadStatusListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getLeadStatusSelectList()
    response && dispatch(Actions.createAction(Actions.GET_LEAD_STATUS_SELECT_LIST, {selectLeadStatus: response?.data}));
    return response;
  };

// LEAD BY CAMPAIGN ID SELECT LIST
export const getLeadByCampaignIdSelectListAction =
  (
    dataToBeSent: {CampaignId:number}
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getLeadByCampaignIdSelectList(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_LEAD_BY_CAMPAIGN_ID_SELECT_LIST, {leadByCampaignIdSelectList: response?.data}));
    return response;
  };

//UPDATE LEAD STATUS
export const updateLeadStatusAction =
  (
    dataToBeSent: {
      leadId: number;
      leadStatus: string;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.updateLeadStatus(dataToBeSent)
    response &&
      dispatch(Actions.createAction(Actions.UPDATE_LEAD_STATUS, {leadStatus: response?.data}));
    return response;
  };
//UPDATE LEAD STAGES
export const updateLeadStageAction =
  (
    dataToBeSent: {
      leadId: number;
      stageId: number;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.updateLeadStage(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.UPDATE_LEAD_STAGE, {leadStatus: response?.data}));
    return response;
  };
/* IMPORT LEADPOOL FILE */
export const importLeadpoolFileAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: FormData
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.importLeadpoolFile(dataToBeSent)
    response &&
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    return response;
  };
