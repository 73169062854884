import { useEffect, useState } from "react";
import AppAssetsConfig from "../../../assets/config/AppAssetsConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import {
  DeleteIcon,
  DetailIcon,
  EyeIcon,
} from "../../../assets/svgs/svg-components";
import { getFileSizeStrFromBytes } from "../../../utils/appFileUtils";
import IconButton from "../iconButton";
import "./FileAttachment.scss";

type Props = {
  attachmentObj: {
    attFileName: string;
    attFileObj: File | null;
    attFileSizeBytes: number;
    attFileImagePreview: string;
    attFileUrl: string;
  };
  removeAttachment?: () => void;
  isView?:boolean
};

const FileAttachment = ({ attachmentObj, removeAttachment, isView = false }: Props) => {
  /* attachments default dependencies */
  const defaultImagePreview = AppAssetsConfig.DEFAULT_ATTACHMENT;
  /* attachments default dependencies */

  const handleDeleteAttachment = (event: React.SyntheticEvent) => {
    event.preventDefault();
    removeAttachment && removeAttachment();
  };

  const handleViewAttachment = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (attachmentObj?.attFileUrl && attachmentObj?.attFileUrl !== "") {
      window.open(attachmentObj?.attFileUrl, "_blank");
    }
  };

  return (
    <div className="file-attachment-main-container flex_box">
      <div className="file-attachment-container flex_box flex_box--justify-center flex_box--items-center">
        <img
          className="file-attachment"
          src={
            attachmentObj &&
            attachmentObj !== undefined &&
            attachmentObj !== null &&
            attachmentObj.attFileImagePreview &&
            attachmentObj.attFileImagePreview !== "" &&
            attachmentObj.attFileImagePreview !== undefined &&
            attachmentObj.attFileImagePreview !== null
              ? attachmentObj.attFileImagePreview
              : defaultImagePreview
          }
          onClick={() => {
            if (attachmentObj?.attFileUrl && attachmentObj?.attFileUrl !== "") {
              window.open(attachmentObj?.attFileUrl, "_blank");
            }
          }}
        />
      </div>
      <div className="file-name-and-actions-container flex_box flex_box--column flex_box--space-between">
        <div className="file-name-size">
          <h4 className="file-name">{attachmentObj?.attFileName}</h4>
          {
            attachmentObj?.attFileUrl === "" &&
          <h5 className="file-size">
            {getFileSizeStrFromBytes(
              attachmentObj && attachmentObj?.attFileSizeBytes
                ? attachmentObj?.attFileSizeBytes
                : 0
            )}
          </h5>
          }
        </div>
        <div className="actions flex_box flex_box--items-center">
          {attachmentObj?.attFileUrl !== "" && (
            <IconButton onClick={handleViewAttachment}>
              <DetailIcon fillColor={AppConstConfig.LIGHT_BLUE_GRAY_COLOR} />
            </IconButton>
          )}
          {
            !isView &&
            <IconButton onClick={handleDeleteAttachment}>
              <DeleteIcon />
            </IconButton>
          }
        </div>
      </div>
    </div>
  );
};

export default FileAttachment;
