import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const PayrollManagementList = lazy(()=> import("./PayrollManagementList"));
const PayrollManagementForm = lazy(()=> import("./PayrollManagementForm"));

const PayrollManagementConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_HR_PAYROLL_MANAGEMENT_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_HR_PAYROLL_MANAGEMENT_MODULE_LIST,
      element: <PayrollManagementList/>,
      handle: { crumb: () => "Payroll Management" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_HR_PAYROLL_MODULE,
      element: <PayrollManagementForm/>,
      handle: { crumb: () => "Add Payroll" },
    },
  ],
};

export default PayrollManagementConfig;