import AppModuleConfig from "../../assets/config/AppModuleConfig";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import { translate } from "../../i18n/i18n";

export const useAdminMenuUtils = () => {
    const tempMenuGroup = [];

    /* menuItemDashboard */
    const menuItemAdminDashboard = {
        id: "adminDashboard",
        title: AppModuleConfig.ADMIN_MODULES.DASHBOARD,
        url: AppRoutingConfig.APP_URL_ADMIN_DASHBOARD
    };
    tempMenuGroup.push(menuItemAdminDashboard);
    /* menuItemDashboard */  
  
    /* menuItemRegion */
    const menuItemAdminRegion = {
        id: "region",
        title: AppModuleConfig.ADMIN_MODULES.REGION,
        url: AppRoutingConfig.APP_URL_ADMIN_REGION_MODULE_LIST
    };
    tempMenuGroup.push(menuItemAdminRegion);
    /* menuItemRegion */  
  
    /* menuItemDepartment */
    const menuItemAdminDepartment = {
        id: "department",
        title: AppModuleConfig.ADMIN_MODULES.DEPARTMENT,
        url: AppRoutingConfig.APP_URL_ADMIN_DEPARTMENT_MODULE_LIST
    };
    tempMenuGroup.push(menuItemAdminDepartment);
    /* menuItemDepartment */  
  
    /* menuItemCustomers */
    const menuItemAdminCustomers = {
        id: "customers",
        title: AppModuleConfig.ADMIN_MODULES.CUSTOMERS,
        url: AppRoutingConfig.APP_URL_ADMIN_CUSTOMERS_MODULE_LIST
    };
    tempMenuGroup.push(menuItemAdminCustomers);
    /* menuItemCustomers */  
  
    /* menuItemAdminVendors */
    const menuItemAdminVendors = {
        id: "vendors",
        title: AppModuleConfig.ADMIN_MODULES.VENDORS,
        url: AppRoutingConfig.APP_URL_ADMIN_VENDORS_MODULE_LIST
    };
    tempMenuGroup.push(menuItemAdminVendors);
    /* menuItemAdminVendors */  
  
    /* menuItemAdminPartners */
    const menuItemAdminPartners = {
        id: "partners",
        title: AppModuleConfig.ADMIN_MODULES.PARTNERS,
        url: AppRoutingConfig.APP_URL_ADMIN_PARTNERS_MODULE_LIST
    };
    tempMenuGroup.push(menuItemAdminPartners);
    /* menuItemAdminPartners */  

    /* menuItemAdminActivity */
    const menuItemAdminActivity = {
        id: "activity",
        title: AppModuleConfig.ADMIN_MODULES.ACTIVITY,
        url: AppRoutingConfig.APP_URL_ADMIN_ACTIVITY_MODULE_LIST,
      };
      tempMenuGroup.push(menuItemAdminActivity);
      /* menuItemAdminActivity */  

    return tempMenuGroup;
};
