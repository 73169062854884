import React from "react";
import { Control, useController } from "react-hook-form";
import ErrorText from "../errorText";
import { isObjectEmpty } from "../../../utils/appCommonUtils";
import { sanitizeDataTypeClientSide, sanitizeDataTypeClientSideOnlyOneSpaceAllowed } from "../../../utils/appDataSanitization";
import "./InputField.scss";

export type InputProps = {
  name: string;
  control: Control<any>;
  variant?: "standard";
  onCustomChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
  customIcon?: React.ReactElement;
  disabled?:boolean
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const InputField = ({
  name,
  control,
  variant,
  onCustomChange,
  customIcon,
  disabled,
  ...props
}: InputProps) => {
  const {
    field,
    formState: { errors }
  } = useController({ name, control });

  /* function definition to handle on change */
  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    sanitizeDataTypeClientSideOnlyOneSpaceAllowed(event)
    field.onChange(event);
    onCustomChange && onCustomChange(event);
  };
  /* function definition to handle on change */

  return (
    <>
      <div className="input-container">
        <input
          id={name}
          {...props}
          {...field}
          disabled={disabled}
          className={`input-field ${disabled && "input-field-disabled"} ${variant ? `input-field-${variant}` : "input-field-outlined"} 
          ${
            errors && errors[name] && variant === "standard"
              ? `input-field-error-${variant}`
              : errors && errors[name] ? "input-field-error-outlined" : "" 
          }`}
          value={field.value}
          onChange={handleChange}
        />
        {
          customIcon &&
        <div className="input-field-custom-icon flex_box flex_box--items-center flex_box--justify-center">
          {customIcon}
        </div>
        }
      </div>
      {!isObjectEmpty(errors) && errors[name] ? (
        <ErrorText>{`${errors[name]?.message}`}</ErrorText>
      ) : (
        ""
      )}
    </>
  );
};

export default InputField;
