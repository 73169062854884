import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const LeadPoolList = lazy(()=> import("./LeadPoolList"))

const CrmLeadPoolConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_CRM_LEADPOOL_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_CRM_LEADPOOL_MODULE_LIST,
      element: <LeadPoolList />,
      handle: { crumb: () => "Lead Pool" },
    },
  ],
};

export default CrmLeadPoolConfig