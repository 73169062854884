import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getSortedRowModel,
  SortingState,
  OnChangeFn
} from "@tanstack/react-table";
import type { ColumnDef, VisibilityState } from "@tanstack/react-table";
import "./Table.scss";
import { SortAscendingIcon, SortDescendingIcon } from "../../../assets/svgs/svg-components";
import { useMemo, useState } from "react";

type ReactTableProps<T extends object> = {
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  data: T[];
  columns: ColumnDef<T>[];
  sorting: SortingState;
  columnVisibility?:VisibilityState;
  setSorting?: OnChangeFn<SortingState>;
  setColumnVisibility?:OnChangeFn<VisibilityState>;
  className?:string;
};

const Table = <T extends object>({
  data,
  columns,
  sorting,
  setSorting,
  columnVisibility,
  setColumnVisibility,
  width,
  className
}: ReactTableProps<T>) => {
  
  /* Memoizing the data for unnecessary re-renders */
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  /* Memoizing the data for unnecessary re-renders */

  /* Creating a table instance */
  const table = useReactTable({
    data: memoizedData,
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sorting,
      columnVisibility:columnVisibility
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange:setColumnVisibility
  });
  /* Creating a table instance */

  return (
    <div className={`table-wrapper ${className}`}>
    <div className={`table-container table-container-default ${width ? `table-container-width-${width}` : `table-container-default`}`}>
      <table className="main-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="table-header-row" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="table-header"
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <span>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </span>
                  <span className="sorting-container">
                    {{ asc: <SortAscendingIcon/>, desc: <SortDescendingIcon /> }[
                      header.column.getIsSorted() as string
                    ] ?? null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {
          data?.length > 0 ?
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr className="table-row" key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    className="table-data"
                    data-cell={cell.column.columnDef.header}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
        :
        <tbody>
            <tr className="table-row">
              <td colSpan={12} className="no-records-found-message">
                No records found
              </td>
            </tr>
          </tbody>
        }
      </table>
    </div>
    
    </div>
  );
};

export default Table;
