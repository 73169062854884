import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import AppModuleConfig from "../../assets/config/AppModuleConfig";

export const useHrMenuUtils = () => {

    const tempMenuGroup = [];

    /* menuItemHrDashboard */
    const menuItemHrDashboard = {
        id: "hrDashboard",
        title: AppModuleConfig.HR_MODULES.DASHBOARD,
        url: AppRoutingConfig.APP_URL_HR_DASHBOARD,
    };
    tempMenuGroup.push(menuItemHrDashboard);
    /* menuItemHrDashboard */

    /* menuItemHrEmployeeManagement */
    const menuItemHrEmployeeManagement = {
        id: "employeeManagement",
        title: AppModuleConfig.HR_MODULES.EMPLOYEE_MANAGEMENT,
        url: AppRoutingConfig.APP_URL_HR_EMPLOYEE_MANAGEMENT_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemHrEmployeeManagement);
    /* menuItemHrEmployeeManagement */

    /* menuItemHrDocumentManagement */
    const menuItemHrDocumentManagement = {
        id: "documentManagement",
        title: AppModuleConfig.HR_MODULES.DOCUMENT_MANAGEMENT,
        url: AppRoutingConfig.APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemHrDocumentManagement);
    /* menuItemHrDocumentManagement */

    /* menuItemHrAttendanceManagement  */
    const menuItemHrAttendanceManagement = {
        id: "attendanceManagement",
        title: AppModuleConfig.HR_MODULES.ATTENDANCE_MANAGEMENT,
        url: AppRoutingConfig.APP_URL_HR_ATTENDANCE_MANAGEMENT_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemHrAttendanceManagement);
    /* menuItemHrAttendanceManagement  */

    /* menuItemHrLeaveManagement */
    const menuItemHrLeaveManagement = {
        id: "leaveManagement",
        title: AppModuleConfig.HR_MODULES.LEAVE_MANAGEMENT,
        url: AppRoutingConfig.APP_URL_HR_LEAVE_MANAGEMENT_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemHrLeaveManagement);
    /* menuItemHrLeaveManagement */
    
    /* menuItemHrPayrollManagement */
    const menuItemHrPayrollManagement = {
        id: "payrollManagement",
        title: AppModuleConfig.HR_MODULES.PAYROLL_MANAGEMENT,
        url: AppRoutingConfig.APP_URL_HR_PAYROLL_MANAGEMENT_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemHrPayrollManagement);
    /* menuItemHrPayrollManagement */

    return tempMenuGroup;
};
