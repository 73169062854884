import { useState } from "react";
import Dialog from "../../../../common/dialog";
import Title from "../../../../common/title";
import { useForm } from "react-hook-form";
import Grid from "../../../../common/grid";
import Button from "../../../../common/button";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import TableLoader from "../../../../common/tableLoader";
import { useToast } from "../../../../../hooks/useToast";
import { changeFieldDataStatusAction } from "../../../../../redux/services/workflow/workFlowServices";
import "./WorkFlow.scss";
type Props = {
  open: boolean;
  onClose: () => void;
};

const FieldDataConfirmStatusDialog = ({ open, onClose }: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  /* For navigating between pages */

  /* edit dependencies */
  const { confirmFieldDataStatus } = useAppSelector((state) => state.workFlow);
  /* edit dependencies */

  /* Dependencies for default values and validation */

  const {
    handleSubmit,

    formState: { isValid },
    reset,
  } = useForm({
    mode: "all",
  });

  /* Dependencies for default values and validation */
  /* Form submit handler */
  const onSubmit = async (formData: any) => {
    let dataToBeSent = {
      id: confirmFieldDataStatus?.fieldDataId,
      isActive: confirmFieldDataStatus?.isActive,
    };
    await dispatch(changeFieldDataStatusAction(dataToBeSent));
    return onClose();
  };
  /* Form submit handler */

  return (
    <Dialog open={open} onClose={onClose} width="xs">
      <div className="workflow-form-container flex_box flex_box--column gap-2">
        <Title textDecoration="underline" justifyContent="center">
          Confirm Status?
        </Title>
        <form
          onSubmit={handleSubmit(onSubmit)}
          name="confirmFieldDataStatus"
          id="confirmFieldDataStatus"
        >
          <Grid container spacing="sm" style={{ margin: "0 1rem" }}>
            {/* Button */}
            <Grid item xs={6} textAlign="end">
              <Button type="submit" form="confirmFieldDataStatus">
                {t("CommonUtils.Button.Yes")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  onClose();
                }}
              >
                {t("CommonUtils.Button.No")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Dialog>
  );
};

export default FieldDataConfirmStatusDialog;
