import Actions from "../actions";
import { produce } from "immer";

interface State {
  user : {
    isAuthenticated: boolean;
  }
  userPermission: {
    adminDashboard?: boolean;
    cRM?: boolean;
    finance?: boolean;
    fleet?: boolean;
    hR?: boolean;
    leave?: boolean;
    operations?: boolean;
    sales?: boolean;
  }
}

const initialState: State = {
  user : {
    isAuthenticated: false,
  },
  userPermission: {}
};

const auth = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.USER_LOGIN: {
      draft.user = {
        isAuthenticated:true
      };
      break;
    }

    case Actions.USER_PERMISSIONS: {
      draft.userPermission = payload.userPermission;
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default auth;
