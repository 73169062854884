import Actions from "../actions";
import { produce } from "immer";

interface State {
  driverCertifications: {
    totalCount: number;
    driverCertificationList: [];
  };
}
const initialState: State = {
  driverCertifications: {
    totalCount: 0,
    driverCertificationList: [],
  },
};

const driverCertification = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_DRIVER_CERTIFICATION_LIST: {
      draft.driverCertifications = payload.driverCertifications;
      break;
    }

    case Actions.EMPTY_DRIVER_CERTIFICATION_LIST: {
      draft.driverCertifications = {
        totalCount: 0,
        driverCertificationList: [],
      }
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default driverCertification;
