import React from 'react'
import './Card.scss'

type Props = {
    children:React.ReactNode,
    className?:string,
    width?: 'xs' | 'sm' | 'md' | 'lg'
}

const Card = ({width, children, className, ...props}: Props) => {
  return (
    <div className={`card-container ${className} ${width ? `card-container-width-${width}` : "card-container-width-xl"}`} {...props}>
        {children}
    </div>
  )
}

export default Card