import { Navigate } from "react-router-dom";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { lazy } from "react";

const EmployeeManagementList = lazy(() => import("./EmployeeManagementList"));
const EmployeeManagementForm = lazy(() => import("./EmployeeManagementForm"));
const EmployeeManagementPrimaryForm = lazy(
  () => import("./EmployeeManagementPrimaryForm")
);
const EmployeeManagementSecondaryForm = lazy(
  () => import("./EmployeeManagementSecondaryForm")
);

const EmployeeManagementConfig = {
  routes: [
    {
      index: true,
      element: (<Navigate to={AppRoutingConfig.APP_URL_HR_EMPLOYEE_MANAGEMENT_MODULE_LIST} replace/>),
    },
    {
      path: AppRoutingConfig.APP_URL_HR_EMPLOYEE_MANAGEMENT_MODULE_LIST,
      element: <EmployeeManagementList />,
      handle: { crumb: () => "Employee Management" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_HR_EMPLOYEE_MANAGEMENT_MODULE,
      element: (<Navigate to={AppRoutingConfig.APP_URL_MOD_ADD_HR_EMPLOYEE_MANAGEMENT_PRIMARY_MODULE}replace/>),
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_HR_EMPLOYEE_MANAGEMENT_MODULE,
      element: <EmployeeManagementForm />,
      children: [
        {
          path: AppRoutingConfig.APP_URL_MOD_ADD_HR_EMPLOYEE_MANAGEMENT_PRIMARY_MODULE,
          element: <EmployeeManagementPrimaryForm />,
          handle: { crumb: () => "Add Employee" },
        },           
        {
          path: AppRoutingConfig.APP_URL_MOD_ADD_HR_EMPLOYEE_MANAGEMENT_SECONDARY_MODULE,
          element: <EmployeeManagementSecondaryForm />,
          handle: { crumb: () => "Add Employee" },
        },
      ],
    },
  ],
};

export default EmployeeManagementConfig;
