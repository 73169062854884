import Actions from "../actions";
import { produce } from "immer";

interface State {
vendors : {
  totalCount: number;
  vendorDetails:[];
},
 selectedVendorsId:number;
 vendorDetails:{};
 selectedVendorsStatus:boolean
}

const initialState: State = {
    vendors : {
      totalCount: 0,
      vendorDetails:[]
    },
    selectedVendorsId:0,
    vendorDetails:{},
    selectedVendorsStatus:false
};

const vendors = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {

    case Actions.GET_VENDORS_LIST: {
      draft.vendors = payload?.vendors;
      break;
    }

    case Actions.GET_VENDOR_DETAIL: {
      draft.vendorDetails = payload?.vendorDetails;
      break;
    }

    case Actions.SELECTED_VENDORS_ID: {
      draft.selectedVendorsId = payload?.selectedVendorsId;
      break;
    }
    
    case Actions.SELECTED_VENDORS_STATUS: {
      draft.selectedVendorsStatus = payload?.selectedVendorsStatus;
      break;
    }
    
    default: {
      break;
    }
  }
}, initialState);

export default vendors;
