import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./activityApis";
import { AxiosResponse } from "axios";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";

/* GET ACTIVITY */
export type GetActivityTypes = {
  isActive: boolean | null;
  orderBy: boolean;
  pageNumber: number;
  pageSize: number;
  searchString: String;
  regionId:number
}
export const getActivitiesAction = (dataToBeSent: GetActivityTypes) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getActivities(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_ACTIVITIES_LIST, {activities: response?.data}));
    return response;
  };


/* ADD ACTIVITY */
export type AddActivityTypes = {
  id: number;
  activityName: string;
};
export const addActivityAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddActivityTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addActivity(dataToBeSent)
    if (response) {
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };


  /* GET ACTIVITY DETAIL */
export const getActivityDetailAction =
(
  dataToBeSent:{ActivityId:number}
) =>
async (dispatch: any): Promise<AxiosResponse<any> | null> => {
  const response:any = await requestFromServer.getActivityDetail(dataToBeSent)
  response && dispatch(Actions.createAction(Actions.GET_ACTIVITY_DETAIL, {activityDetails:response?.data}));
  return response 
}

