import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const VendorsList = lazy(()=> import("./VendorsList"))
const VendorsForm = lazy(()=> import("./VendorsForm"))
const VendorsDetails = lazy(()=> import("./VendorsDetails"))

const VendorsConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_ADMIN_VENDORS_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_VENDORS_MODULE_LIST,
      element: <VendorsList />,
      handle: { crumb: () => "Vendors" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_ADMIN_VENDORS_MODULE,
      element: <VendorsForm />,
      handle: { crumb: () => "Add Vendor" },
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_VENDORS_MODULE_DETAIL,
      element: <VendorsDetails />,
      handle: { crumb: () => "Vendor Detail" }
    },
  ],
};

export default VendorsConfig