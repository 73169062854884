import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import FleetDashboardConfig from "./dashboard/DashboardConfig"

import { Navigate } from "react-router";
import FleetManagementConfig from "./fleetManagement/FleetManagementConfig";

const FleetConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_FLEET,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_FLEET_DASHBOARD} replace />
      ),

    },
    ...FleetDashboardConfig.routes,
    ...FleetManagementConfig.routes
  ]
};

export default FleetConfig;
