export const checkIfStringIsValidTwoDigitDecimal = (consStr:any) => {
    if (consStr && consStr !== undefined && consStr !== '') 
    {
        var regExp = /^\d+(\.\d{1,2})?$/;
        var isValidDec = regExp.test(consStr);
        if(isValidDec === true && isNaN(consStr))
        {
            isValidDec = false;
        }
        else if(isValidDec === true && !isNaN(consStr) && consStr * 1 < 0)
        {
            isValidDec = false;
        }
        return isValidDec;
    }
    else
    {
        return true;
    }
}