import React from "react";
import "./ErrorText.scss"

type Props = {
  children: React.ReactNode
};

const ErrorText = ({ children }: Props) => {
  return (
    <div className="error-text-container">
      <p className="error-text">{children}</p>
    </div>
  );
};

export default ErrorText;
