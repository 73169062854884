import { useEffect, useState } from "react";
import Button from "../../../../common/button";
import Grid from "../../../../common/grid";
import Table from "../../../../common/table";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import useAuthenticate from "../../../../../hooks/useAuthenticate";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { useTranslation } from "react-i18next";
import Pagination from "../../../../common/pagination";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";
import {
  EditIcon,
  ToggleOffIcon,
  ToggleOnIcon,
} from "../../../../../assets/svgs/svg-components";
import IconButton from "../../../../common/iconButton";
import {
  changeFieldDataStatusAction,
  getFieldDataDetailListAction,
} from "../../../../../redux/services/workflow/workFlowServices";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import TableLoader from "../../../../common/tableLoader";
import { useToast } from "../../../../../hooks/useToast";
import Actions from "../../../../../redux/actions";
import FieldDataConfirmStatusDialog from "./FieldDataConfirmStatusDialog";
import "./WorkFlow.scss";

type Props = {};

const FieldDataList = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  const [loading, setLoading] = useState(false);
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  /*State for confirm status dialog */
  const [openConfirmStatusDialog, setOpenConfirmStatusDialog] = useState(false);
  /*State for confirm status dialog */
  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const { showToast } = useToast();
  /* For navigating between pages */

  /* check user authentication dependency */
  const [isAuthenticated] = useAuthenticate();
  /* check user authentication dependency */

  /* table dependencies */
  const { fieldDatas } = useAppSelector((state) => state.workFlow);
  const [columns, setColumns] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [pageNo, setPageNo] = useState(
    AppConstConfig.DEFAULT_TABLE_PAGE_NUMBER
  );
  const [pageSize, setPageSize] = useState(
    AppConstConfig.DEFAULT_TABLE_PAGE_SIZE
  );
  /* table dependencies */

  /* add edit dialog dependencies */
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);

  /* add edit dialog dependencies */

  /* function definition to get table data */
  const handleGetTableData = () => {
    let dataToBeSent = {
      isActive: null,
      pageNumber: pageNo,
      pageSize: pageSize,
      orderBy: false,
      searchString: "",
    };
    dispatch(getFieldDataDetailListAction(dataToBeSent));
  };
  /* function definition to get table data */

  useEffect(() => {
    handleGetTableData();
  }, [pageNo, pageSize]);

  /* initial rendering to check the user authentication */
  useEffect(() => {
    /* table column config */
    const tempTableColumns = [
      {
        header: t("Operations.WorkFlow.FieldDataList.ProjectName"),
        accessorFn: (row: any) => row.projectName || "-",
      },
      {
        header: t("Operations.WorkFlow.FieldDataList.Item"),
        accessorFn: (row: any) => row.item || "-",
      },
      {
        header: t("Operations.WorkFlow.FieldDataList.Activity"),
        accessorFn: (row: any) => row.activity || "-",
      },
      {
        header:AppConstConfig.TABLE_COLUMN_NAME_ACTIVE_INACTIVE,
        cell: ({ row }: any) => {
          return (
            <IconButton onClick={(e) => handleStatusDialogOpen(e, row)}>
              {row?.original && row?.original?.isActive ? (
                <ToggleOnIcon />
              ) : (
                <ToggleOffIcon />
              )}
            </IconButton>
          );
        },
      },
      {
        header: AppConstConfig.TABLE_COLUMN_NAME_ACTIONS,
        cell: ({ row }: any) => {
          return (
            <>
              {row?.original?.isActive ? (
                <IconButton onClick={(e) => handleAddEditDetails(e, row)}>
                  <EditIcon fillColor={AppConstConfig.LIGHT_BLUE_GRAY_COLOR} />
                </IconButton>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    ];
    /* table column config */
    setColumns(tempTableColumns);
  }, [pageSize, pageSize]);
  /* initial rendering to check the user authentication */

  /* function definition to handle add/edit - Page open */
  const handleAddEditDetails = (e: React.SyntheticEvent, row: any) => {
    if (row && row?.original && row?.original?.id) {
      const fieldDataId = row?.original?.id;

      navigate(AppRoutingConfig.APP_URL_ADD_FIELD_DATA_MODULE, {
        state: { fieldDataId },
      });
    } else {
      navigate(AppRoutingConfig.APP_URL_ADD_FIELD_DATA_MODULE);
    }
  };
  /* function definition to handle add/edit - Page open */


  /*On Status Change Function */
  const handleStatusDialogOpen = (e: React.SyntheticEvent, row: any) => {

    let dataToBeSent = {
      fieldDataId: row?.original.id,
      isActive: row?.original.isActive === false ? true : false,
    };
    dispatch(
      Actions.createAction(Actions.CONFIRM_FIELD_DATA_STATUS, dataToBeSent)
    );
    setOpenConfirmStatusDialog(true);
  };

  const handleStatusDialogClose = () => {
    setOpenConfirmStatusDialog(false);
    handleGetTableData();
  };
  /*On Status Change Function */

  return (
    <>
      <Card>
        <div className="workflow-list-container flex_box flex_box--column">
          <Grid container>
            <Grid item xs={4} sm={6} md={8} lg={9} style={{ margin: "auto" }}>
              <Title>{t("Operations.WorkFlow.FieldDataUtils.FieldData")}</Title>
            </Grid>
            <Grid item xs={8} sm={6} md={4} lg={3} textAlign="end">
              <Button onClick={(e) => handleAddEditDetails(e, null)}>
              {t("Operations.WorkFlow.FieldDataUtils.AddFieldData")}
              </Button>
            </Grid>
          </Grid>
          {apiLoader ? (
            <TableLoader />
          ) : (
            <>
            <Table
              data={fieldDatas?.fieldDataDetails}
              columns={columns}
              sorting={sorting}
              setSorting={setSorting}
            />
            {(fieldDatas?.totalCount && fieldDatas?.totalCount!==0) ?
            <Pagination
              pageNo={pageNo}
              total={fieldDatas?.totalCount}
              pageSize={pageSize}
              setPageSize={setPageSize}
              onPageChange={(page) => setPageNo(page)}
            />
            : <></>
            }
            </>
          )}

          
          <FieldDataConfirmStatusDialog
            open={openConfirmStatusDialog}
            onClose={() => handleStatusDialogClose()}
          />
        </div>

        {/* Buttons */}
        <Grid item xs={12} textAlign="center">
          <Button
            variant="outlined"
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              navigate(AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE_LIST);
            }}
          >
            {t("CommonUtils.Button.Back")}
          </Button>
        </Grid>
      </Card>
    </>
  );
};

export default FieldDataList;
