import Actions from '../actions'
import { produce } from 'immer'

interface State {
  isLoading:boolean
  apiLoader:boolean
}

const initialState: State = {
  isLoading:false,
  apiLoader:false
}

const loadingScreen = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.IS_LOADING: {
        draft.isLoading = payload.isLoading
      break
    }

    case Actions.API_LOADER: {
        draft.apiLoader = payload.isLoading
      break
    }

    default: {
      break
    }
  }
}, initialState)

export default loadingScreen
