import React, { useState } from "react";
import { Control, useController } from "react-hook-form";
import { isObjectEmpty } from "../../../utils/appCommonUtils";
import ErrorText from "../errorText";
import "./Checkbox.scss";

type Props = {
  name: string;
  control?: Control<any>;
  direction: "row" | "column";
  value?: string;
  isTitleUpperCase?:boolean;
  onCustomChange?: (event: React.SyntheticEvent) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const CheckBox = ({
  name,
  control,
  direction,
  value,
  onCustomChange,
  isTitleUpperCase,
  ...props
}: Props) => {
  const {
    field,
    formState: { errors },
  } = useController({ name, control });

  /* function definition to handle on change */
  const handleChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    field.onChange(event);
    onCustomChange && onCustomChange(event)
  };
  /* function definition to handle on change */

  return (
    <>
      <div className="checkbox-container flex_box flex_box--items-center">
        <input
          {...field}
          id={name}
          name={name}
          type="checkbox"
          className="checkbox"
          onChange={handleChange}
          {...props}
        />
        <span className={` ${isTitleUpperCase ? "checkbox-title-uppercase" : "checkbox-title"}`}>{value}</span>
      </div>
      {!isObjectEmpty(errors) && errors[name] ? (
        <ErrorText>{`${errors[name]?.message}`}</ErrorText>
      ) : (
        ""
      )}
    </>
  );
};

export default CheckBox;