import React, { useEffect, useState } from "react";
import Button from "../../../../common/button";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import Card from "../../../../common/card";
import TableLoader from "../../../../common/tableLoader";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../../../hooks/useToast";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router";
import {
  getFilteredObjectFromId,
  isoToDispViewCustomDate,
} from "../../../../../utils/appCommonUtils";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import Title from "../../../../common/title";
import Table from "../../../../common/table";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";
import CampaignsDetailAssignAgentDialog from "./CampaignDetailAssignAgentDialog";
import Actions from "../../../../../redux/actions";
import {
  getBusinessTypeListAction,
  getCampaignDetailAction,
  getSourceListAction,
} from "../../../../../redux/services/campaigns/campaignsServices";
import InputField from "../../../../common/inputField";
import { useForm } from "react-hook-form";
import Select from "../../../../common/select";
import CampaignsDetailManualDialog from "./CampaignDetailManualDialog";
import "./Campaigns.scss";

type Props = {};

type DefaultValueTypes = {
  id: number;
  bussinessType: any | null;
  source: any | null;
  totalLeads: String;
  assignedLeads: string;
  unassignedLeads: string;
};

const CampaignsDetails = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  /* loading dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  const params = location?.state;
  /* For navigating between pages */

  /* add edit Manually dialog dependencies */
  const [openAddEditManullayDialog, setOpenAddEditManuallyDialog] =
    useState(false);
  /* add edit Manually dialog dependencies */

  /* add edit Assign Agent dialog dependencies */
  const [openAddEditAssignAgentDialog, setOpenAddEditAssignAgentDialog] =
    useState(false);
  /* add edit Manually dialog dependencies */

  /* table dependencies */
  const {} = useAppSelector((state) => state.campaigns);
  const [columns, setColumns] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [pageNo, setPageNo] = useState(
    AppConstConfig.DEFAULT_TABLE_PAGE_NUMBER
  );
  const [pageSize, setPageSize] = useState(
    AppConstConfig.DEFAULT_TABLE_PAGE_SIZE
  );

  /* table dependencies */

  /* detail page dependencies */
  const [campaignDetails, setCampaignDetails] = useState<any>();
  const [selectedCampaignId, setselectedCampaignId] = useState<any | undefined>();
  /* detail page dependencies */

  /* searchable select dependencies */
  const { businessTypeSelectList, sourceSelectList, getCampaignsDetail } =
    useAppSelector((state) => state.campaigns);
  /* searchable select dependencies */


  /* Dependencies for default values and validation */
  const defaultValues: DefaultValueTypes = {
    id: 0,
    bussinessType: null,
    source: null,
    totalLeads: "",
    assignedLeads: "",
    unassignedLeads: "",
  };

  const {
    handleSubmit,
    control,
    formState,
    getValues,
    setValue,
    watch,
    reset,
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues
  });
  /* Dependencies for default values and validation */

  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {
    // Business Type select list
    dispatch(getBusinessTypeListAction()).then((apiRes) => {
        details && apiRes?.data && getFilteredObjectFromId(details?.bussinessTypeId, apiRes?.data).then((bussinessType: any) => {
            setValue("bussinessType", bussinessType);
        });
      }
    );
    // Source select list
    dispatch(getSourceListAction()).then((apiRes) => {
        details && apiRes?.data && getFilteredObjectFromId(details?.sourceId, apiRes?.data).then((source: any) => {
            setValue("source", source);
        });
      }
    );
  };
  /* function definition for select list API calls */

  const handleGetTableData = () => {
    /* load initial dependencies - Detail */

    if (params && params !== null && params !== undefined) {
      const { selectedCampaignsId } = params;

      setLoadingComponent(true)
      const dataToBeSent = {
        CampaignId: selectedCampaignsId,
      };

      dispatch(getCampaignDetailAction(dataToBeSent)).then((apiRes) => {
        const userCampaignDetail = apiRes?.data;
        if (userCampaignDetail) {
          selectListApiCalls(userCampaignDetail);
          setCampaignDetails(userCampaignDetail);
          reset(userCampaignDetail)
          setLoadingComponent(false)
        }
      });
    }
    /* load initial dependencies - Detail */
  }
  useEffect(() => {
    handleGetTableData()
  }, []);

  /* initial rendering to check the user authentication */
  useEffect(() => {
    /* table column config */
    const tempTableColumns = [
      {
        header: t("Crm.Campaigns.CampaignsDetail.SrNo"),
        accessorFn: (row: any, index:number) => index + 1 || "-",
      },
      {
        header: t("Crm.Campaigns.CampaignsDetail.Source"),
        accessorFn: (row: any) => row?.source || "-",
      },
      {
        header: t("Crm.Campaigns.CampaignsDetail.Agent"),
        accessorFn: (row: any) => row?.agent || "-",
      },
      {
        header: t("Crm.Campaigns.CampaignsDetail.EndDate"),
        accessorFn: (row: any) => isoToDispViewCustomDate(row?.endDate) || "-",
      },
      {
        header: t("Crm.Campaigns.CampaignsDetail.LeadName"),
        accessorFn: (row: any) => row?.title || "-",
      },
      {
        header: t("Crm.Campaigns.CampaignsDetail.ContactNo"),
        accessorFn: (row: any) =>row?.contactNo || "-",
        
      },
      {
        header: t("Crm.Campaigns.CampaignsDetail.EmailID"),
        accessorFn: (row: any) =>row?.contactNo || "-",
      },
      {
        header: t("Crm.Campaigns.CampaignsDetail.Status"),
        accessorFn: (row: any) => row?.status || "-",
    
      },
    ];
    /* table column config */
    setColumns(tempTableColumns);
  }, []);
  /* initial rendering to check the user authentication */

  /* Manually dialog dependencies */
  const handleAddEditManuallyDetailsDialog = (
    e: React.SyntheticEvent,
    row: any
  ) => {
    setOpenAddEditManuallyDialog(true);
    if (params && params !== null && params !== undefined) {
      const { selectedCampaignsId } = params;
      const selectedCampaignId = selectedCampaignsId;
      dispatch(Actions.createAction(Actions.SELECTED_CAMPAIGN_ID, {selectedCampaignId}));
    }
  };

  const handleAddEditManuallyDialogClose = () => {
    setOpenAddEditManuallyDialog(false);
    dispatch(Actions.createAction(Actions.SELECTED_CAMPAIGN_ID, {selectedCampaignId: 0}));
    handleGetTableData()
  };
  /* Manually dialog dependencies */

  /* Assign Agent dialog dependencies */
  const handleAddEditAssignAgentDetailsDialog = (
    e: React.SyntheticEvent,
    row: any
  ) => {
    setOpenAddEditAssignAgentDialog(true);
    if (params && params !== null && params !== undefined) {
      const { selectedCampaignsId } = params;
      const selectedCampaignId = selectedCampaignsId;
      dispatch(Actions.createAction(Actions.SELECTED_CAMPAIGN_ID, {selectedCampaignId}));
    }
  };

  const handleAddEditAssignAgentDialogClose = () => {
    setOpenAddEditAssignAgentDialog(false);
    dispatch(Actions.createAction(Actions.SELECTED_CAMPAIGN_ID, {selectedCampaignId: 0}));
    handleGetTableData()
  };
  /* Assign Agent dependencies */

  return (
    <Card>
      {loadingComponent ? (
        <TableLoader />
      ) : (
        <div className="campaigns-form-container flex_box flex_box--column">
          <Grid container>
            <Grid item xs={4} sm={6} md={8} lg={8}>
              <Title>{t("Crm.Campaigns.CampaignsForm.Criteria")}</Title>
            </Grid>

            <Grid item xs={8} sm={6} md={1} lg={1}>
              <Button
                onClick={(e) => handleAddEditManuallyDetailsDialog(e, null)}
              >
                {t("Crm.Campaigns.CampaignsDetail.Manually")}
              </Button>
            </Grid>
            <Grid item xs={8} sm={6} md={2} lg={2}>
              <Button
                onClick={(e) => handleAddEditAssignAgentDetailsDialog(e, null)}
              >
                {t("Crm.Campaigns.CampaignsDetail.AssignAgent")}
              </Button>
            </Grid>
          </Grid>

          <Grid container spacing="sm">
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label>{t("Crm.Campaigns.CampaignsForm.BusinessType")}</Label>
              <Select
                name="bussinessType"
                options={businessTypeSelectList}
                control={control}
                isLoading={apiLoader}
                isClearable
                isDisabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label>{t("Crm.Campaigns.CampaignsForm.Source")}</Label>
              <Select
                name="source"
                options={sourceSelectList}
                control={control}
                isLoading={apiLoader}
                isClearable
                isDisabled
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4} sm={6} md={8} lg={8}>
              <Title>{t("Crm.Campaigns.CampaignsDetail.LeadDetails")}</Title>
            </Grid>
          </Grid>

          <Grid container spacing="sm">
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label>{t("Crm.Campaigns.CampaignsDetail.TotalLeads")}</Label>

              <InputField
                name="totalLeads"
                control={control}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label>{t("Crm.Campaigns.CampaignsDetail.AssignedLeads")}</Label>
              <InputField
                name="assignedLeads"
                control={control}
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label>
                {t("Crm.Campaigns.CampaignsDetail.UnassignedLeads")}
              </Label>
              <InputField
                name="unassignedLeads"
                control={control}
                disabled
              />
            </Grid>
          </Grid>

          {loadingComponent ? (
            <TableLoader />
          ) : (
            <Table
              data={getCampaignsDetail?.leadDetails}
              columns={columns}
              sorting={sorting}
              setSorting={setSorting}
            />
          )}

          <Grid item xs={12} textAlign="center">
            <Button
              variant="outlined"
              onClick={(event: React.SyntheticEvent) => {
                event.preventDefault();
                navigate(AppRoutingConfig.APP_URL_CRM_CAMPAIGNS_MODULE_LIST);
              }}
            >
              {t("CommonUtils.Button.Back")}
            </Button>
          </Grid>
        </div>
      )}

          <CampaignsDetailAssignAgentDialog
            open={openAddEditAssignAgentDialog}
            onClose={() => handleAddEditAssignAgentDialogClose()}
          />

          <CampaignsDetailManualDialog
            open={openAddEditManullayDialog}
            onClose={() => handleAddEditManuallyDialogClose()}
          />
    </Card>
  );
};

export default CampaignsDetails;
