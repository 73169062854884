
import { lazy } from 'react';
import AppRoutingConfig from '../../../../assets/config/AppRoutingConfig';
import CrmDashboardConfig from "./dashboard/DashboardConfig";
import CrmCampaignsConfig from './campaigns/CampaignsConfig';
import CrmAgentsConfig from './agents/AgentsConfig';
import CrmLeadPoolConfig from './leadpool/LeadPoolConfig';
import { Navigate } from 'react-router';


const CrmConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_CRM,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_CRM_DASHBOARD} replace />
      ),
    },
    ...CrmDashboardConfig.routes,
    ...CrmCampaignsConfig.routes,
    ...CrmAgentsConfig.routes,
    ...CrmLeadPoolConfig.routes
  ]
};

export default CrmConfig;
