import React, { useEffect, useState } from "react";
import Dialog from "../../../../common/dialog";
import Title from "../../../../common/title";
import { useForm } from "react-hook-form";
import Grid from "../../../../common/grid";
import Button from "../../../../common/button";
import { useTranslation } from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../../common/checkbox";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";
import Table from "../../../../common/table";
import Select from "../../../../common/select";
import { useToast } from "../../../../../hooks/useToast";
import Actions from "../../../../../redux/actions";
import TableLoader from "../../../../common/tableLoader";
import "./WorkFlow.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

const ActivityListDialog = ({ open, onClose }: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loading, setLoading] = useState(false);
  /* loading dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* searchable select dependencies */
  const { fieldDataList, activityStatusSelectList } = useAppSelector(
    (state) => state.workFlow
    );
    /* searchable select dependencies */

  /* For navigating between pages */
  const navigate = useNavigate();
  /* For navigating between pages */

  /* table dependencies */
  const [columns, setColumns] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [pageNo, setPageNo] = useState(
  AppConstConfig.DEFAULT_TABLE_PAGE_NUMBER
  );
  const [pageSize, setPageSize] = useState(
  AppConstConfig.DEFAULT_TABLE_PAGE_SIZE
  );
  /* table dependencies */

  /* Dependencies for default values and validation */
  type DefaultValueTypes = {
    status: { value: string; label: string }[];
    sameAsAbove: boolean[];
  };
  const defaultValues = {
    status: [{ value: "", label: "" }],
    sameAsAbove: [],
  };

  const { control } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
  });

  /* on checkbox changed */
  const onCheckboxChanged = (event: React.SyntheticEvent, row: any) => {

    const tempChecked = (event.target as HTMLInputElement).checked;
    const tempActivityId = row?.original?.value;
    
    let dataToBeSent = {
      activityId: tempActivityId,
      checked: tempChecked
    };

    dispatch(Actions.createAction(Actions.UPDATE_ACTIVITY_CHECKBOX_FOR_FIELD_DATA_LIST, {activityCheckbox:dataToBeSent}))
  };
  /* on checkbox changed */

  /* on status changed */
  const onStatusChanged = (option: { value: any; label: string }, row: any) => {
    const tempActivityId = row?.original?.value;
    const tempStatus = option;

    let dataToBeSent = {
      activityId: tempActivityId,
      status: tempStatus
    };

    dispatch(Actions.createAction(Actions.UPDATE_ACTIVITY_STATUS_FOR_FIELD_DATA_LIST, {activityStatus:dataToBeSent}))
  };
  /* on status changed */


  /* Dependencies for default values and validation */
  useEffect(() => {
    const tempTableColumns = [
      {
        header: t(
          "Operations.WorkFlow.ProjectSchedulingForm.ActivityList.ActivityId"
        ),
        accessorFn: (row: any) => row?.value || "-",
      },
      {
        header: t("Operations.WorkFlow.ProjectSchedulingForm.ActivityList.Status"),
        cell: ({ row }: any) => {
          const tempActivityId = row?.original?.value;
          const tempDefaultStatus = row?.original?.status;
          
          const tempDefaultValueObj = {
            value: tempDefaultStatus,
            label: tempDefaultStatus,
          };
          return (
            <div className="flex_box flex_box--justify-center">
              <Select
                name={`status[${tempActivityId}]`}
                options={activityStatusSelectList}
                height="32px"
                width="117px"
                fontSize="11px"
                maxMenuHeight={70}
                control={control}
                menuPlacement={row.index === fieldDataList?.length - 1 ? "top" : (row.index === 0 && fieldDataList?.length === 1) ? "top" : "bottom"}
                defaultValue={tempDefaultValueObj}
                onCustomChange={(option) => onStatusChanged(option, row)}
              />
            </div>
          );
        },
      },
      {
        header: t("Operations.WorkFlow.ProjectSchedulingForm.ActivityList.ActivityName"),
        accessorFn: (row: any) => row?.label || "-",
      },
      {
        header: t("Operations.WorkFlow.ProjectSchedulingForm.ActivityList.Select"),
        cell: ({ row }: any) => {
          const tempActivityId = row?.original?.value;

          return (
            <div className="flex_box flex_box--justify-center">
              <CheckBox
                name={`sameAsAbove[${tempActivityId}]`}
                direction="row"
                control={control}
                defaultChecked={row?.original?.isChecked}
                onCustomChange={(event) => onCheckboxChanged(event, row)}
              />
            </div>
          );
        },
      },
    ];
    /* table column config */
    setColumns(tempTableColumns);
  }, [fieldDataList]);

  return (
    <Dialog open={open} onClose={onClose} width="md">
      <div className="workFlow-form-container flex_box flex_box--column gap-1">
        <Title textDecoration="underline" justifyContent="center">
          Activity List
        </Title>
        {loading ? (
          <TableLoader />
        ) : (
          <Table
            width="md"
            data={fieldDataList}
            columns={columns}
            sorting={sorting}
            setSorting={setSorting}
          />
        )}
        <Grid item xs={12} textAlign="center">
          <Button type="button" onClick={onClose}>
            {t("CommonUtils.Button.Submit")}
          </Button>
        </Grid>
      </div>
    </Dialog>
  );
};

export default React.memo(ActivityListDialog);
