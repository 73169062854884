import "./Title.scss"

type Props = {
    children:React.ReactNode;
    textDecoration?:'underline';
    justifyContent?:'center' | 'flex-start' | 'flex-end'
}

const Title = ({children, textDecoration, justifyContent}: Props) => {
  return (
    <div className={`title-container ${justifyContent ? `title-justifyContent-${justifyContent}` : ''}`}>
        <p className={`title ${textDecoration ? `title-${textDecoration}` : ''}`}>
            {children}
        </p>
    </div>
  )
}

export default Title
