import { Dispatch, SetStateAction } from "react";
import "./Pagination.scss";
import { FirstPageIcon, LastPageIcon, NextPageIcon, PreviousPageIcon } from "../../../assets/svgs/svg-components";

/* Component for total limiting pages */
type PaginationListingProps = {
  pageSize:number;
  setPageSize:Dispatch<SetStateAction<number>>;
  onPageChange: (page: number) => void;
  numberPagesArray:number[]
}

const PaginationListing = ({pageSize, setPageSize, onPageChange, numberPagesArray}:PaginationListingProps) => {
  return (
    <div className="pagination-listing-container">
    <p className="listing">Listing</p>
      <select
      className="listing-select"
        value={pageSize}
        onChange={(event) => {
            setPageSize(Number(event.target.value))
            onPageChange(1)
        }}
      >
        {numberPagesArray.map((numberPageArray) => (
          <option className="listing-options" key={numberPageArray} value={numberPageArray}>
            {numberPageArray}
          </option>
        ))}
      </select>
    </div>
  )
}
/* Component for total limiting pages */

/* Function definition to get the required number of pages */
type getRequiredPagesProps = {
  pagesCount: number;
  requiredPagesCount: number;
  pageNo: number;
};

const getRequiredPages = ({
  pagesCount,
  requiredPagesCount,
  pageNo,
}: getRequiredPagesProps) => {
  const ceiling = Math.ceil(requiredPagesCount / 2);
  const floor = Math.floor(requiredPagesCount / 2);

  if (pagesCount < requiredPagesCount) {
    return {
      start: 1,
      end: pagesCount + 1,
    };
  } else if (pageNo >= 1 && pageNo <= ceiling) {
    return { start: 1, end: requiredPagesCount + 1 };
  } else if (pageNo + floor >= pagesCount) {
    return { start: pagesCount - requiredPagesCount + 1, end: pagesCount + 1 };
  } else {
    return { start: pageNo - ceiling + 1, end: pageNo + floor + 1 };
  }
};
/* Function definition to get the required number of pages */

/* Component for individual pagination item */
type PaginationItemProps = {
  page: any;
  currentPage?: number;
  onPageChange: (page: number) => void;
  isDisabled?: boolean;
};

const PaginationItem = ({
  page,
  currentPage,
  onPageChange,
  isDisabled,
}: PaginationItemProps) => {
  return (
    <li
      className={`pagination-item ${
        page === currentPage ? "active-page" : ""
      } ${isDisabled ? "button-disabled" : ""}`}
      onClick={() => onPageChange(page)}
      >
      <span className="pagination-link">{page}</span>
    </li>
  );
};
/* Component for individual pagination item */



/* Main pagination component */
type Props = {
  pageNo: number;
  total: number;
  pageSize: number;
  setPageSize:Dispatch<SetStateAction<number>>;
  onPageChange: (page: number) => void;
};

const Pagination = ({ pageNo, total, pageSize, setPageSize, onPageChange }: Props) => {
  const pagesCount = Math.ceil(total / pageSize);

  const range = (start: number, end: number) => {
    const array: number[] = Array.from(
      { length: end - start },
      (_, index) => index + start
    );
    return array;
  };
  const requiredPages = getRequiredPages({
    pagesCount,
    requiredPagesCount: 4,
    pageNo,
  });
  const totalPages = range(requiredPages.start, requiredPages.end);
  const isFirstPage = pageNo === 1;
  const isLastPage = pageNo === pagesCount;
  const numberPagesArray = [10, 15, 20];

  return (
    <div className="pagination-container">
      <ul className="pagination">
        <PaginationItem
          page={<FirstPageIcon/>}
          currentPage={pageNo}
          onPageChange={() => onPageChange(1)}
          isDisabled={isFirstPage}
        />
        <PaginationItem
          page={<PreviousPageIcon/>}
          currentPage={pageNo}
          onPageChange={() => onPageChange(pageNo - 1)}
          isDisabled={isFirstPage}
        />
        {totalPages.map((page) => {
          return (
            <>
              <PaginationItem
                page={page}
                key={page}
                currentPage={pageNo}
                onPageChange={onPageChange}
              />
            </>
          );
        })}
        <PaginationItem
          page={<NextPageIcon/>}
          currentPage={pageNo}
          onPageChange={() => onPageChange(pageNo + 1)}
          isDisabled={isLastPage}
        />
        <PaginationItem
          page={<LastPageIcon/>}
          currentPage={pageNo}
          onPageChange={() => onPageChange(pagesCount)}
          isDisabled={isLastPage}
        />
      </ul>

      <PaginationListing pageSize={pageSize} setPageSize={setPageSize} onPageChange={onPageChange} numberPagesArray={numberPagesArray}/>
    </div>
  );
};

export default Pagination;
/* Main pagination component */
