import React, { useEffect } from "react";
import { CrossIcon } from "../../../assets/svgs/svg-components";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import "./Toast.scss";

type Props = {
  children: React.ReactNode;
  close: () => void;
  variant: string;
};

const Toast = ({ close, variant, children }: Props) => {
  useEffect(() => {
    const toastTimeout = setTimeout(() => close(), 3000);
    return () => clearTimeout(toastTimeout);
  }, [close]);

  return (
    <div
      className={`toast-container toast-container-${variant} flex_box flex_box--space-between flex_box--center`}
    >
      <h4 className="toast-content">{children}</h4>
      <div className="button-container">
        <button className="toast-button flex_box flex_box--items-center flex_box--center" onClick={close}>
          <CrossIcon fillColor={AppConstConfig.GRAY_COLOR} />
        </button>
      </div>
    </div>
  );
};

export default Toast;
