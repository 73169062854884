import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';


const LeaveManagementList = lazy(()=> import("../../hr/leaveManagement/LeaveManagementList"))

const LeaveManagementConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_LEAVE_MANAGEMENT_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_LEAVE_MANAGEMENT_MODULE_LIST,
      element: <LeaveManagementList />,
      handle: { crumb: () => "Leave Management" },
    },
  ],
};

export default LeaveManagementConfig