import React from 'react'
import './Box.scss'

type Props = {
    children:React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

const Box = ({children, ...props}: Props) => {
  return (
    <div className='box-container' {...props}>{children}</div>
  )
}

export default Box