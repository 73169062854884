import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Button from "../../../../common/button";
import Grid from "../../../../common/grid";

import Label from "../../../../common/label";

import Card from "../../../../common/card";
import TableLoader from "../../../../common/tableLoader";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../../../hooks/useToast";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router";

import {

  getFilteredObjectFromId,

} from "../../../../../utils/appCommonUtils";

import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";

import {
  getCitySelectListAction,
  getCountrySelectListAction,
  getStateSelectListAction,
} from "../../../../../redux/services/region/regionServices";

import Title from "../../../../common/title";
import { getCustomerByIdAction } from "../../../../../redux/services/customerManagement/customerManagementServices";

type Props = {};

const CustomerManagementDetail = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  /* loading dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */
  const location = useLocation();
  const params = location?.state;
  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  /* For navigating between pages */

  /* detail page dependencies */
  const [customerManagementDetails, setCustomerManagementDetails] = useState<any>();
  const [customerManagementSelectDetails, setCustomerManagementSelectDetails] = useState({
      country: "",
      state: "",
      city: "",
      billingCountry: "",
      billingState: "",
      billingCity: "",
    });
  /* detail page dependencies */


  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {
    // Country select list
    dispatch(getCountrySelectListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.country, apiRes?.data).then(
            (country: any) => {
              country && loadDispatchStateForCountry(country);

              setCustomerManagementSelectDetails((prevValue) => ({
                ...prevValue,
                country: country?.label,
              }));
            }
          );

        getFilteredObjectFromId(details?.billingCountry, apiRes?.data).then(
          (billingCountry: any) => {
            billingCountry && loadDispatchStateForCountry(billingCountry);

            setCustomerManagementSelectDetails((prevValue) => ({
              ...prevValue,
              billingCountry: billingCountry?.label,
            }));
          }
        );
      }
    );

    // State select list based on country
    const loadDispatchStateForCountry = (country: {
      value: any;
      label: string;
    }) => {
      dispatch(getStateSelectListAction({CountryId: country?.value})).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.state, apiRes?.data).then(
            (state: any) => {
              state && loadDispatchCityForState(state);
              setCustomerManagementSelectDetails((prevValue) => ({
                ...prevValue,
                state: state?.label,
              }));
            }
          );

        getFilteredObjectFromId(details?.billingState, apiRes?.data).then(
          (billingState: any) => {
            billingState && loadDispatchCityForState(billingState);
            setCustomerManagementSelectDetails((prevValue) => ({
              ...prevValue,
              billingState: billingState?.label,
            }));
          }
        );
      });
    };

    // City select list based on state
    const loadDispatchCityForState = (state: { value: any; label: string }) => {
      dispatch(getCitySelectListAction({StateId: state?.value})).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.city, apiRes?.data).then(
            (city: any) => {
              setCustomerManagementSelectDetails((prevValue) => ({
                ...prevValue,
                city: city?.label,
              }));
            }
          );

        getFilteredObjectFromId(details?.billingCity, apiRes?.data).then(
          (billingCity: any) => {
            setCustomerManagementSelectDetails((prevValue) => ({
              ...prevValue,
              billingCity: billingCity?.label,
            }));
          }
        );
      });
    };
  };
  /* function definition for select list API calls */

  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { customerManagementId } = params;

      const dataToBeSent = { CustomerId: customerManagementId };

      setLoadingComponent(true)
      dispatch(
        getCustomerByIdAction(dataToBeSent)
      ).then((apiRes: any) => {
        if (apiRes?.data) {
          selectListApiCalls(apiRes?.data);
          setCustomerManagementDetails(apiRes?.data);
          setLoadingComponent(false)
        }
      });
    }
  }, []);

  return (
    <>
      {loadingComponent ? (
        <Card>
        <TableLoader />
        </Card>
      ) : (
        <div className="project-summary-form-container flex_box flex_box--column">
          <Grid container spacing="md" flexDirection="column">
            {/* Customer Info */}
            <Grid item>
              <Card>
                <div className="customer-management-form-container flex_box flex_box--column">
                  <Grid container>
                    <Title>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.CustomerInfo"
                      )}
                    </Title>
                  </Grid>

                  <Grid container spacing="sm">
                    {/* Customer Id */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.CustomerId"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.customerId || "-"}
                      </div>
                    </Grid>
                    {/* Customer Name */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.CustomerName"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.customerName || "-"}
                      </div>
                    </Grid>
                    {/* Address Line 1 */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.AddressLine1"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.addressLine1 || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.AddressLine2"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.addressLine2 || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.Country"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementSelectDetails?.country || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.State"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementSelectDetails?.state || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.City"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementSelectDetails?.city || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.ZipCode"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.zipCode || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.Phone"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.phone || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.Fax"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.fax || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.Email"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.email || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.Website"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.website || "-"}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <div className="customer-management-form-container flex_box flex_box--column">
                  <Grid container>
                    <Title>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.BillingAddress"
                      )}
                    </Title>
                  </Grid>

                  <Grid container spacing="sm">
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Grid container>
                        <Grid item sm={12} md={6} lg={5}>
                          <Label>
                            {t(
                              "Operations.CustomerManagement.CustomerManagementForm.AddressLine1"
                            )}
                          </Label>
                          <div className="label_info">
                            {customerManagementDetails?.billingAddressLine1 || "-"}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.AddressLine2"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.billingAddressLine2 || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.Country"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementSelectDetails?.billingCountry || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.State"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementSelectDetails?.billingState || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.City"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementSelectDetails?.billingCity || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.ZipCode"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.billingZipcode || "-"}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
            <Grid item>
              <Card>
                <div className="customer-management-form-container flex_box flex_box--column">
                  <Grid container>
                    <Title>
                      {t(
                        "Operations.CustomerManagement.CustomerManagementForm.Contact"
                      )}
                    </Title>
                  </Grid>

                  <Grid container spacing="sm">
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.Name"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.contactName || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.JobTitle"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.contactPosition || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.WorkPhone"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.contactWorkPhone || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.MobilePhone"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.contactMobile || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>
                        {t(
                          "Operations.CustomerManagement.CustomerManagementForm.Email"
                        )}
                      </Label>
                      <div className="label_info">
                        {customerManagementDetails?.contactEmail || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      <Button variant="outlined" onClick={()=>{
                        if(location.pathname.includes("sales")){
                          navigate(AppRoutingConfig.APP_URL_SALES_CUSTOMERS_MODULE_LIST);
                        }
                        else{
                          navigate(AppRoutingConfig.APP_URL_CUSTOMER_MANAGEMENT_MODULE_LIST);
                        }
                      }}>
                        {t("CommonUtils.Button.Back")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default CustomerManagementDetail;
