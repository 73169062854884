import { useState } from "react";
import { useTranslation } from "react-i18next";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import { translate } from "../../i18n/i18n";
import AppModuleConfig from "../../assets/config/AppModuleConfig";

export const useCrmMenuUtils = () => {
    const tempMenuGroup = [];

    /* menuItemCrmDashboard */
    const menuItemCrmDashboard = {
      id: "crmDashboard",
      title: AppModuleConfig.CRM_MODULES.DASHBOARD,
      url: AppRoutingConfig.APP_URL_CRM_DASHBOARD,
    };
    tempMenuGroup.push(menuItemCrmDashboard);
    /* menuItemCrmDashboard */

    /* menuItemLeadpool */
    const menuItemLeadpool = {
      id: "leadPool",
      title: AppModuleConfig.CRM_MODULES.LEADPOOL,
      url: AppRoutingConfig.APP_URL_CRM_LEADPOOL_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemLeadpool);
    /* menuItemLeadpool */

    /* menuItemAgents */
    const menuItemAgents = {
      id: "agents",
      title: AppModuleConfig.CRM_MODULES.AGENTS,
      url: AppRoutingConfig.APP_URL_CRM_AGENTS_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemAgents);
    /* menuItemAgents */

    /* menuItemCampaigns */
    const menuItemCampaigns = {
      id: "campaigns",
      title: AppModuleConfig.CRM_MODULES.CAMPAIGNS,
      url: AppRoutingConfig.APP_URL_CRM_CAMPAIGNS_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemCampaigns);
    /* menuItemCampaigns */

    return tempMenuGroup;
};
