import AppModuleConfig from "../../assets/config/AppModuleConfig";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";

export const useSalesMenuUtils = () => {
    const tempMenuGroup = [];

    /* menuItemCustomers */
    const menuItemCustomers = {
      id: "customers",
      title: AppModuleConfig.SALES_MODULES.CUSTOMERS,
      url: AppRoutingConfig.APP_URL_SALES_CUSTOMERS_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemCustomers);
    /* menuItemCustomers */

    /* menuItemLead */
    const menuItemLead = {
      id: "lead",
      title: AppModuleConfig.SALES_MODULES.LEAD,
      url: AppRoutingConfig.APP_URL_SALES_LEAD_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemLead);
    /* menuItemLead */

    /* menuItemQuotes */
    const menuItemQuotes = {
      id: "quotes",
      title: AppModuleConfig.SALES_MODULES.QUOTES,
      url: AppRoutingConfig.APP_URL_SALES_QUOTES_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemQuotes);
    /* menuItemQuotes  */

    /* menuItemProjects */
    const menuItemProjects = {
      id: "projects",
      title: AppModuleConfig.SALES_MODULES.PROJECTS,
      url: AppRoutingConfig.APP_URL_SALES_PROJECTS_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemProjects);
    /* menuItemProjects */

    /* menuItemSalesRep */
    const menuItemSalesRep = {
      id: "salesRep",
      title: AppModuleConfig.SALES_MODULES.SALES_REP,
      url: AppRoutingConfig.APP_URL_SALES_REP_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemSalesRep);
    /* menuItemSalesRep */

    return tempMenuGroup;
};
