export const GET_FLEET_MANANGEMENT_LIST = null
export const GET_VEHICLE_TYPE_SELECT_LIST = null
export const GET_VEHICLE_CLASS_SELECT_LIST = null
export const GET_FUEL_TYPE_SELECT_LIST = null
export const SELECTED_VEHICLE_ID = null
export const GET_VEHICLE_DETAILS = null
export const GET_MAINTENANCE_AND_REPAIRS_DETAILS = null
export const GET_MAINTENANCE_AND_REPAIRS_LIST = null
export const GET_FUEL_MANAGEMENT_DETAILS = null
export const GET_FUEL_MANAGEMENT_LIST = null
export const GET_TRIP_MANAGEMENT_DETAILS = null
export const GET_TRIP_MANAGEMENT_LIST = null
export const GET_DRIVER_INFORMATION_DETAILS = null
export const GET_DRIVER_INFORMATION_LIST = null
export const SELECTED_USER_ID = null
export const SELECTED_DETAILS = null
export const EMPTY_SELECTED_DETAILS = null



