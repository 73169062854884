import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./documentManagementApis";
import { AxiosResponse } from "axios";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";

/* GET EMPLOYEE DOCUMENTS */
export type GetEmployeeDocumentsTypes = {
  orderBy:boolean, 
  pageNumber:number, 
  pageSize:number, 
  searchString:string
}
export const getEmployeeDocumentManagementAction = (dataToBeSent:GetEmployeeDocumentsTypes) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getEmployeeDocuments(dataToBeSent)
    dispatch(Actions.createAction(Actions.GET_EMPLOYEE_DOCUMENT_MANAGEMENT_LIST, response?.data));
    return response
  };
  

/* GET EMPLOYEE DOCUMENT DETAIL */
export const getEmployeeDocumentManagementByIdAction =
  (
    dataToBeSent:{UserDocumentId:number}
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getEmployeeDocumentManagementById(dataToBeSent)
    return response 
  };


/* GET COMPANY DOCUMENTS */
  export type GetCompanyDocumentsTypes = {
    orderBy:boolean, 
    pageNumber:number, 
    pageSize:number, 
    searchString:string
  }
export const getCompanyDocumentManagementAction =
(
  dataToBeSent:GetCompanyDocumentsTypes
) =>
async (dispatch: any): Promise<AxiosResponse<any> | null> => {
  const response:any = await requestFromServer.getCompanyDocuments(dataToBeSent)
    dispatch(Actions.createAction(Actions.GET_COMPANY_DOCUMENT_MANAGEMENT_LIST, response?.data));
    return response
};

/* GET COMPANY DOCUMENT DETAIL */
export const getCompanyDocumentManagementByIdAction =
  (
    dataToBeSent:{CompanyDocumentId:number}
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getCompanyDocumentManagementById(dataToBeSent)
    return response 
  };


/* GET DOCUMENT TYPE SELECT LIST */
export const getDocumentTypeSelectListAction =
(
  dataToBeSent:{ShowCompanyDocuments:boolean}
) =>
async (dispatch: any): Promise<AxiosResponse<any> | null> => {
  const response:any = await requestFromServer.getDocumentTypeSelectList(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_DOCUMENT_TYPE_SELECT_LIST, {documentTypes:response?.data}));
    return response 
};


/* ADD EMPLOYEE DOCUMENTS */
export const addEmployeeDocumentManagementAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent:FormData
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.addEmployeeDocument(dataToBeSent)
    navigate(AppRoutingConfig.APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_LIST)
    showToast({
      message: response?.message,
      variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
    });
    return response 
  };


/* ADD EMPLOYEE DOCUMENTS */
export const addCompanyDocumentManagementAction =
(
  navigate: NavigateFunction,
  showToast: (value: { message: string; variant: string }) => void,
  dataToBeSent:FormData
) =>
async (dispatch: any): Promise<AxiosResponse<any> | null> => {
  const response:any = await requestFromServer.addCompanyDocument(dataToBeSent)
  navigate(AppRoutingConfig.APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_LIST)
  showToast({
    message: response?.message,
    variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
  });
  return response 
};