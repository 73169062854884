import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const DepartmentList = lazy(()=> import("./DepartmentList"))

const DepartmentConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_ADMIN_DEPARTMENT_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_DEPARTMENT_MODULE_LIST,
      element: <DepartmentList />,
      handle: { crumb: () => "Department" },
    },
  ],
};

export default DepartmentConfig