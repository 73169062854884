import Actions from "../actions";
import { produce } from "immer";

interface State {
  roleSelectList: [];
  suffixSelectList: [];
  baseWageTypeSelectList: [];
  typeSelectList: [];
  statusSelectList: [];
  educationLevelSelectList: [];
  educationDegreeSelectList: [];
  reportingToSelectList: [];
  quoteTypeSelectList:[];
  probabilitySelectList:[];
  leadSourceSelectList:[];
  customerTypeSelectList:[];
  stageSelectList:[];
  quoteStatusSelectList:[];
  activityTypeSelectList:[];
  unitOfMeasureSelectList:[];
  titleSelectList:[];
  projectScheduleStatusSelectList:[];
  confirmStatus : {
    id:number,
    isActive:boolean
  };
  selectedNotes: string
}

const initialState: State = {
  roleSelectList: [],
  suffixSelectList: [],
  baseWageTypeSelectList: [],
  typeSelectList: [],
  statusSelectList: [],
  educationLevelSelectList: [],
  educationDegreeSelectList: [],
  reportingToSelectList: [],
  quoteTypeSelectList:[],
  probabilitySelectList:[],
  leadSourceSelectList:[],
  customerTypeSelectList:[],
  stageSelectList:[],
  quoteStatusSelectList:[],
  activityTypeSelectList:[],
  unitOfMeasureSelectList:[],
  titleSelectList:[],
  projectScheduleStatusSelectList:[],
  confirmStatus:{
    id:0,
    isActive:false
  },
  selectedNotes: "",
};

const common = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_ROLE_SELECT_LIST: {
      draft.roleSelectList = payload.roles;
      break;
    }

    case Actions.GET_SUFFIX_SELECT_LIST: {
      draft.suffixSelectList = payload.suffix;
      break;
    }

    case Actions.GET_BASE_WAGE_TYPE_SELECT_LIST: {
      draft.baseWageTypeSelectList = payload.baseWageTypes;
      break;
    }

    case Actions.GET_TYPE_SELECT_LIST: {
      draft.typeSelectList = payload.types;
      break;
    }

    case Actions.GET_STATUS_SELECT_LIST: {
      draft.statusSelectList = payload.status;
      break;
    }

    case Actions.GET_EDUCATION_LEVEL_SELECT_LIST: {
      draft.educationLevelSelectList = payload.educationLevels;
      break;
    }

    case Actions.GET_EDUCATION_DEGREE_SELECT_LIST: {
      draft.educationDegreeSelectList = payload.educationDegrees;
      break;
    }

    case Actions.GET_REPORTING_TO_SELECT_LIST: {
      draft.reportingToSelectList = payload.reportingTo;
      break;
    }

    case Actions.GET_QUOTE_TYPE_SELECT_LIST: {
      draft.quoteTypeSelectList = payload.quoteTypes;
      break;
    }

    case Actions.GET_PROBABILITY_SELECT_LIST: {
      draft.probabilitySelectList = payload.probabilities;
      break;
    }

    case Actions.GET_LEAD_SOURCE_SELECT_LIST: {
      draft.leadSourceSelectList = payload.leadSources;
      break;
    }

    case Actions.GET_CUSTOMER_TYPE_SELECT_LIST: {
      draft.customerTypeSelectList = payload.customerTypes;
      break;
    }

    case Actions.GET_STAGE_SELECT_LIST: {
      draft.stageSelectList = payload?.stages;
      break;
    }

    case Actions.GET_QUOTE_STATUS_SELECT_LIST: {
      draft.quoteStatusSelectList = payload.quoteStatus;
      break;
    }

    case Actions.GET_ACTIVITY_TYPE_SELECT_LIST: {
      draft.activityTypeSelectList = payload.activityTypes;
      break;
    }

    case Actions.GET_UNIT_OF_MEASURE_SELECT_LIST: {
      draft.unitOfMeasureSelectList = payload.unitOfMeasures;
      break;
    }

    case Actions.GET_TITLE_SELECT_LIST: {
      draft.titleSelectList = payload.titles;
      break;
    }

    case Actions.GET_PROJECT_SCHEDULE_STATUS_SELECT_LIST: {
      draft.projectScheduleStatusSelectList = payload.projectScheduleStatus;
      break;
    }

    case Actions.RESET_TITLE_SELECT_LIST: {
      draft.titleSelectList = [];
      break;
    }

    case Actions.CONFIRM_STATUS: {
      draft.confirmStatus = payload?.confirmStatus;
      break;
    }

    case Actions.SELECTED_NOTES: {
      draft.selectedNotes = payload?.selectedNotes;
      break;
    }

    case Actions.EMPTY_SELECTED_NOTES: {
      draft.selectedNotes = "";
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default common;
