import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const ProjectsList = lazy(()=> import("../../operations/projectSummary/ProjectSummaryList"))
const ProjectsForm = lazy(()=> import("../../operations/projectSummary/ProjectSummaryForm"))
const ProjectsDetail = lazy(()=> import("../../operations/projectSummary/ProjectSummaryDetail"))

const ProjectsConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_SALES_CUSTOMERS_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_PROJECTS_MODULE_LIST,
      element: <ProjectsList />,
      handle: { crumb: () => "Projects" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_SALES_PROJECTS_MODULE,
      element: <ProjectsForm/>,
      handle: { crumb: () => "Add Project" },
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_PROJECTS_MODULE_DETAIL,
      element: <ProjectsDetail/>,
      handle: { crumb: () => "Project Detail" },
    }
  ],
};

export default ProjectsConfig