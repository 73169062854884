// Project Scheduling List
export const GET_PROJECT_SCHEDULING_LIST = null
export const ADD_EDIT_PROJECT_SCHEDULING_DETAILS = null
export const EDIT_PROJECT_SCHEDULING_STATUS = null
export const ACTIVE_INACTIVE_PROJECT_SCHEDULE=null
export const GET_PROJECT_SELECT_LIST= null
export const GET_PROJECT_DETAILS_BY_ID=null
export const GET_EMPLOYEE_SELECT_LIST=null
export const GET_ACTIVITY_STATUS_SELECT_LIST= null
export const GET_VEHICLE_SELECT_LIST= null
export const ADD_PROJECT_SCHEDULING_ACTIVITY = null
export const DELETE_PROJECT_SCHEDULING_ACTIVITY = null
export const CONCAT_PROJECT_SCHEDULING_ACTIVITY=null
export const CLEAR_PROJECT_SCHEDULING_ACTIVITY=null
export const UPDATE_PROJECT_SCHEDULING_ACTIVITY_STATUS= null;
export const SELECT_PROJECT_SCHEDULING_ID = null
export const GET_PROJECT_SCHEDULING_DETAIL = null
export const CONFIRM_PROJECT_SCHEDULE_STATUS = null
export const SELECTED_PROJECT_SCHEDULING_ID = null
export const UPDATE_ACTIVITY_STATUS_FOR_FIELD_DATA_LIST = null
export const UPDATE_ACTIVITY_CHECKBOX_FOR_FIELD_DATA_LIST = null

// Field Data List
export const GET_FIELD_DATA_DETAIL_LIST = null
export const GET_FIELD_DATA_LIST = null
export const EMPTY_FIELD_DATA_LIST = null
export const UPDATE_FIELD_DATA_LIST = null
export const ADD_EDIT_FIELD_DATA_DETAILS = null
export const GET_FIELD_DATA_DETAILS = null
export const CLEAR_FIELD_DATA_DETAILS = null
export const UPDATE_FIELD_DATA_STATUS= null;
export const UPDATE_ACTIVITY_DATA_STATUS= null;
export const CONFIRM_FIELD_DATA_STATUS = null

