import { Navigate } from "react-router-dom";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { lazy } from "react";

const FleetManagementList = lazy(() => import("./FleetManagementList"));
const FleetManagementForm = lazy(() => import("./FleetManagementForm"));
const VehicleInformationForm = lazy(
  () => import("./VehicleInformationForm")
);
const MaintenanceAndRepairsForm = lazy(
    () => import("./MaintenanceAndRepairsForm")
  );
  const FuelManagementForm = lazy(
    () => import("./FuelManagementForm")
  );
  const DriverInformationForm = lazy(
    () => import("./DriverInformationForm")
  );
  const TripManagementForm = lazy(
    () => import("./TripManagementForm")
  );

const FleetManagementConfig = {
  routes: [
    {
      index: true,
      element: (<Navigate to={AppRoutingConfig.APP_URL_FLEET_FLEET_MANAGEMENT_MODULE_LIST} replace/>),
    },
    {
      path: AppRoutingConfig.APP_URL_FLEET_FLEET_MANAGEMENT_MODULE_LIST,
      element: <FleetManagementList />,
      handle: { crumb: () => "Fleet Management" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_FLEET_FLEET_MANAGEMENT_MODULE,
      element: (<Navigate to={AppRoutingConfig.APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_VEHICLE_INFORMATION_MODULE}replace/>),
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_FLEET_FLEET_MANAGEMENT_MODULE,
      element: <FleetManagementForm />,
      children: [
        {
          path: AppRoutingConfig.APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_VEHICLE_INFORMATION_MODULE,
          element: <VehicleInformationForm />,
          handle: { crumb: () => "Add Fleet" },
        },           
        {
          path: AppRoutingConfig.APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_MAINTENANCE_AND_REPAIRS_MODULE,
          element: <MaintenanceAndRepairsForm />,
          handle: { crumb: () => "Add Fleet" },
        },
        {
            path: AppRoutingConfig.APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_FUEL_MANAGEMENT_MODULE,
            element: <FuelManagementForm />,
            handle: { crumb: () => "Add Fleet" },
        },
        {
            path: AppRoutingConfig.APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_DRIVER_INFORMATION_MODULE,
            element: <DriverInformationForm />,
            handle: { crumb: () => "Add Fleet" },
        },
        {
            path: AppRoutingConfig.APP_URL_MOD_ADD_FLEET_FLEET_MANAGEMENT_TRIP_MANAGEMENT_MODULE,
            element: <TripManagementForm />,
            handle: { crumb: () => "Add Fleet" },
        },
      ],
    },
  ],
};

export default FleetManagementConfig;
