import React, { useCallback, useEffect, useMemo, useState } from "react";
import Dialog from "../../../../common/dialog";
import Title from "../../../../common/title";
import { Controller, useForm } from "react-hook-form";

import Grid from "../../../../common/grid";

import Button from "../../../../common/button";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useNavigate } from "react-router-dom";


import CheckBox from "../../../../common/checkbox";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";

import Table from "../../../../common/table";
import Select from "../../../../common/select";
import {
  getActivityStatusSelectListAction,
  getFieldDataListAction,
} from "../../../../../redux/services/workflow/workFlowServices";
import { useToast } from "../../../../../hooks/useToast";
import Actions from "../../../../../redux/actions";
import TableLoader from "../../../../common/tableLoader";
import "./WorkFlow.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

const ActivityListDetailDialog = ({ open, onClose }: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */
  const [loading, setLoading] = useState(false);

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */
  const { fieldDataList, activityStatusSelectList } = useAppSelector(
    (state) => state.workFlow
  );
  /* For navigating between pages */
  const navigate = useNavigate();
  /* For navigating between pages */
  const [columns, setColumns] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [pageNo, setPageNo] = useState(
    AppConstConfig.DEFAULT_TABLE_PAGE_NUMBER
  );
  const [pageSize, setPageSize] = useState(
    AppConstConfig.DEFAULT_TABLE_PAGE_SIZE
  );

  const { showToast } = useToast();

  const { selectedProjectSchedulingId } = useAppSelector(
    (state) => state.workFlow
  );
  /* Dependencies for default values and validation */
  type DefaultValueTypes = {
    status: { value: string; label: string }[];
    sameAsAbove: boolean[];
  };
  const defaultValues = {
    status: [{ value: "", label: "" }],
    sameAsAbove: [],
  };

  const { control, watch, setValue, getValues } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
  });
  const { status, sameAsAbove } = watch();


  const onCheckboxChanged = (event: React.SyntheticEvent, row: any) => {
    const tempIsChecked = (event.target as HTMLInputElement).checked;
    const tempActivityId = row?.original?.value;
    const tempRowIndex = row?.index;
    const selRowStatus = row?.original.status;

    let dataToBeSent = {
      id: tempActivityId,
      status: selRowStatus?.value,
    };
    if (tempIsChecked) {
      dispatch(
        Actions.createAction(Actions.ADD_PROJECT_SCHEDULING_ACTIVITY, {
          addActivity: dataToBeSent,
        })
      );
    } else if (!tempIsChecked) {
      dispatch(
        Actions.createAction(Actions.DELETE_PROJECT_SCHEDULING_ACTIVITY, {
          deleteActivity: tempActivityId,
        })
      );
    }
  };

  const onStatusChanged = (option: { value: any; label: string }, row: any) => {
    // const tempIsChecked = (event.target as HTMLInputElement).checked;

    let dataToBeSent = {
      option: option,
      activityId: row?.original?.value,
    };
    dispatch(
      Actions.createAction(Actions.UPDATE_FIELD_DATA_LIST, {
        updateFieldDataList: dataToBeSent,
      })
    );
    dispatch(
      Actions.createAction(Actions.UPDATE_PROJECT_SCHEDULING_ACTIVITY_STATUS, {
        updateFieldDataList: dataToBeSent,
      })
    );
  };


  /* Dependencies for default values and validation */
  useEffect(() => {
    const tempTableColumns = [
      {
        header: t(
          "Operations.WorkFlow.ProjectSchedulingForm.ActivityList.ActivityId"
        ),
        accessorFn: (row: any) => row?.value || "-",
      },
      {
        header: t(
          "Operations.WorkFlow.ProjectSchedulingForm.ActivityList.Status"
        ),
        accessorFn: (row: any) => row?.status?.value || "-",

      },
      {
        header: t(
          "Operations.WorkFlow.ProjectSchedulingForm.ActivityList.ActivityName"
        ),
        accessorFn: (row: any) => row?.label || "-",
      },
      {
        header: t(
          "Operations.WorkFlow.ProjectSchedulingForm.ActivityList.Select"
        ),
        cell: ({ row }: any) => {
          const tempActivityId = row?.original?.value;
          const tempRowIndex = row?.index;
          return (
            <div className="flex_box flex_box--justify-center">
              <CheckBox
                name={`sameAsAbove[${tempActivityId}]`}
                direction="row"
                disabled
                control={control}
                defaultChecked={row?.original?.isChecked}
                onCustomChange={(event) => onCheckboxChanged(event, row)}
              />
            </div>
          );
        },
      },
    ];
    /* table column config */
    setColumns(tempTableColumns);
  }, []);

  return (
    <Dialog open={open} onClose={onClose} width="lg">
      <div className="workFlow-form-container flex_box flex_box--column gap-1">
        <Title textDecoration="underline" justifyContent="center">
          {/* {t("HR.LeaveManagement.LeaveManagementForm.ApplyLeaveTitle")}
           */}
          Activity List
        </Title>
        {loading ? (
          <TableLoader />
        ) : (
          <Table
            data={fieldDataList}
            columns={columns}
            sorting={sorting}
            setSorting={setSorting}
          />
        )}
        {/* <Grid item xs={12} textAlign="center">
          <Button type="button" onClick={onClose}>
            {t("CommonUtils.Button.Submit")}
          </Button>
        </Grid> */}
      </div>
    </Dialog>
  );
};

export default React.memo(ActivityListDetailDialog);
