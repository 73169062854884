import Actions from "../actions";
import { produce } from "immer";

interface State {
    adminDashboardDetails : {
        monthlyLeads:{
            key:string;
            leadCounts:number[];
            months:string[];
        };
        projectStatuses:{
            key:string;
            projectCounts:number[];
            statuses:string[];
        };
        leadStages:{
            key:string;
            leadsCounts:number[];
            stages:string[];
        };
        userRoles:{
            key:string;
            userCounts:number[];
            roleNames:string[];
        };
    }
}

const initialState: State = {
    adminDashboardDetails : {
        monthlyLeads:{
            key:'',
            leadCounts:[],
            months:[]
        },
        projectStatuses:{
            key:'',
            projectCounts:[],
            statuses:[]
        },
        leadStages:{
            key:'',
            leadsCounts:[],
            stages:[]
        },
        userRoles:{
            key:'',
            userCounts:[],
            roleNames:[]
        }
    }   
}

const adminDashboard = produce((draft: State, action: any) => {
    const { payload } = action;
    switch (action.type) {

        case Actions.GET_ADMIN_DASHBOARD_DATA: {
            draft.adminDashboardDetails = payload?.adminDashboardDetails;
            break;
        }

        default: {
            break;
        }
    }

}, initialState);
  
export default adminDashboard;