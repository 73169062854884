import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Button from '../../../../common/button'
import Grid from '../../../../common/grid'
import FileAttachment from '../../../../common/fileAttachment'
import AppConstConfig from '../../../../../assets/config/AppConstConfig'
import IconButton from '../../../../common/iconButton'
import { AttachmentIcon, SearchIcon } from '../../../../../assets/svgs/svg-components'
import Label from '../../../../common/label'
import InputField from '../../../../common/inputField'
import Select from '../../../../common/select'
import Card from '../../../../common/card'
import TableLoader from '../../../../common/tableLoader'
import { useTranslation } from 'react-i18next'
import { useToast } from '../../../../../hooks/useToast'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux-hooks'
import { useLocation, useNavigate } from 'react-router'
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import AppAssetsConfig from '../../../../../assets/config/AppAssetsConfig'
import { getUserFullNameSelectListAction } from '../../../../../redux/services/employeeManagement/employeeManagementServices'
import { dateObjToDispViewIsoFormat, dateToDispViewIsoFormat, getFilteredObjectFromId, isoToDispViewDate, isoToDispViewDateObj } from '../../../../../utils/appCommonUtils'
import { addCompanyDocumentManagementAction, addEmployeeDocumentManagementAction, getCompanyDocumentManagementByIdAction, getDocumentTypeSelectListAction, getEmployeeDocumentManagementByIdAction } from '../../../../../redux/services/documentManagement/documentManagementServices'
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig'
import { checkIfFileSizeIsValidForAttachment, checkIfFileTypeIsValidForAttachment, formulateAttachmentPreview } from '../../../../../utils/appFileUtils'
import AppApiConfig from '../../../../../assets/config/AppApiConfig'
import "./DocumentManagement.scss"
import Datepicker from '../../../../common/datepicker'

type Props = {}

export type DefaultValueTypes = {
  id: number;
  status: string;
  user: null;
  documentType: null;
  expiryDate: null | Date;
};

const DocumentManagementEditForm = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  /* loading dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  /* For navigating between pages */

  /* searchable select dependencies */
  const { userFullNameSelectList } = useAppSelector(
    (state) => state.employeeManagement
  );
  const {
    documentTypeSelectList,
    selectedDocumentManagementId,
    selectedDocumentManagementStatus,
  } = useAppSelector((state) => state.documentManagement);
  /* searchable select dependencies */

  /* module attachment handling dependencies for attachment */
  const defaultAttachmentUrl = AppAssetsConfig.DEFAULT_ATTACHMENT;
  const [attachment, setAttachment] = useState<{
    attFileName: string,
    attFileObj: File | null,
    attFileSizeBytes: number,
    attFileImagePreview: string,
    attFileUrl: string,
    isFileChanged:boolean
  } | null>(null);
  const attachmentInputRef = useRef<HTMLInputElement>(null);
  /* module attachment handling dependencies for attachment */

  /* Dependencies for default values and validation */
  const defaultValues: DefaultValueTypes = {
    id: 0,
    status: selectedDocumentManagementStatus,
    user: null,
    documentType: null,
    expiryDate: null
  };

  const schema = yup.object().shape({
    user: selectedDocumentManagementStatus === t("HR.DocumentManagement.DocumentManagementList.Employee") ?
      yup.object().required(t("HR.DocumentManagement.DocumentManagementForm.PleaseSelectEmployeeName")) :
      yup.object().nullable(),
    documentType: yup.object().required(t("HR.DocumentManagement.DocumentManagementForm.PleaseSelectDocumentType")),
    expiryDate: yup.date().required(t("HR.DocumentManagement.DocumentManagementForm.PleaseSelectExpiryDate")),
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { id, user, documentType, expiryDate } = watch();
  /* Dependencies for default values and validation */

  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {
    // User Full name select list
    if (selectedDocumentManagementStatus === t("HR.DocumentManagement.DocumentManagementList.Employee")) {
        dispatch(getUserFullNameSelectListAction()).then((apiRes) => {
          details && apiRes?.data && getFilteredObjectFromId(details?.userId, apiRes?.data).then((user: any) => {
          setValue("user", user);
        });
      });
    }

    // User Document Type select list
    const dataToBeSent = {
      ShowCompanyDocuments: selectedDocumentManagementStatus ===
            t("HR.DocumentManagement.DocumentManagementList.Employee")
          ? false
          : true,
    };
    dispatch(getDocumentTypeSelectListAction(dataToBeSent)).then((apiRes) => {
      details && apiRes?.data && getFilteredObjectFromId(details?.documentTypeId, apiRes?.data).then((documentType: any) => {
            setValue("documentType", documentType);
          }
        );
    });
  };
  /* function definition for select list API calls */

  useEffect(() => {

    /* load initial dependencies - Edit */
    if (selectedDocumentManagementId && selectedDocumentManagementId !== 0) {
      switch (selectedDocumentManagementStatus) {
        case t("HR.DocumentManagement.DocumentManagementList.Employee"):
          setLoadingComponent(true)
          const dataToBeSentForEmployeeDocument = {
            UserDocumentId: selectedDocumentManagementId,
          };
          dispatch(getEmployeeDocumentManagementByIdAction(dataToBeSentForEmployeeDocument)).then((apiRes) => {
            const userDocumentDetail = apiRes?.data;
            if (userDocumentDetail) {
              selectListApiCalls(userDocumentDetail);
              reset({
                ...defaultValues,
                expiryDate: isoToDispViewDateObj(userDocumentDetail?.expiryDate),
                id: selectedDocumentManagementId,
              });
              setLoadingComponent(false)

              
              /* setting the dependencies for attachments */
              let attachmentFileName = userDocumentDetail?.fileName;
              const attachmentFileSizeBytes = 0;
              const attachmentFileUrl = `${AppApiConfig.API_ENDPOINT}${userDocumentDetail?.documentPath}`;
              addModuleAttachment( null,attachmentFileName,attachmentFileSizeBytes,attachmentFileUrl);
              /* setting the dependencies for attachments */
            }
          });
          break;

        case t("HR.DocumentManagement.DocumentManagementList.Company"):
          setLoadingComponent(true)
          const dataToBeSentForCompanyDocument = {
            CompanyDocumentId: selectedDocumentManagementId,
          };
          dispatch(getCompanyDocumentManagementByIdAction(dataToBeSentForCompanyDocument)
          ).then((apiRes) => {
            const userDocumentDetail = apiRes?.data;
            if (userDocumentDetail) {
              selectListApiCalls(userDocumentDetail);
              reset({
                ...defaultValues,
                expiryDate: isoToDispViewDateObj(userDocumentDetail?.expiryDate),
                id: selectedDocumentManagementId,
              });
              setLoadingComponent(false)

              /* setting the dependencies for attachments */
              let attachmentFileName = userDocumentDetail?.fileName;
              const attachmentFileSizeBytes = 0;
              const attachmentFileUrl = `${AppApiConfig.API_ENDPOINT}${userDocumentDetail?.documentPath}`;
              addModuleAttachment( null,attachmentFileName,attachmentFileSizeBytes,attachmentFileUrl);
              /* setting the dependencies for attachments */
            }
          });
          break;

        default:
          break;
      }
    } 
    /* load initial dependencies - Edit */
  }, [selectedDocumentManagementStatus]);

  /* Open Attachment Picker On Button Click */
  const openAttachmentPicker = (event: React.SyntheticEvent) => {
    event.preventDefault();
    attachmentInputRef?.current?.click();
  };
  /* Open Attachment Picker On Button Click */

  /* Handling & Validation of File When Picked */
  const onAttachmentChanged = (event: ChangeEvent<HTMLInputElement>) => {
    let target = event.target as HTMLInputElement;
    const attachmentFileObj = (target.files as FileList)[0];
    target.value = ""

    const attachmentFileName = attachmentFileObj.name;
    const attachmentFileSizeBytes = attachmentFileObj.size;
    const attachmentFileUrl = "";

    if (attachmentFileName !== "") {
      const fileTypeIsValidRes = checkIfFileTypeIsValidForAttachment(attachmentFileName);
      const fileSizeIsValidRes = checkIfFileSizeIsValidForAttachment(attachmentFileSizeBytes);
      
      if (fileTypeIsValidRes.isValid === false) {
        showToast({
          message: fileTypeIsValidRes.isInvalidMessage,
          variant: AppConstConfig.TOAST_VARIANT_ERROR,
        });
      } else if (fileSizeIsValidRes.isValid === false) {
        showToast({
          message: fileSizeIsValidRes.isInvalidMessage,
          variant: AppConstConfig.TOAST_VARIANT_ERROR,
        });
      } else {
        addModuleAttachment(attachmentFileObj,attachmentFileName,attachmentFileSizeBytes,attachmentFileUrl);
      }
    }
  };
  /* Handling & Validation of File When Picked */

  /* function definition to formulate attachment preview */
  const addModuleAttachment = (
    attachmentFileObj: File | null,
    attachmentFileName: string,
    attachmentFileSizeInBytes: number,
    attachmentFileUrl: string
  ) => {
    formulateAttachmentPreview(attachmentFileObj,attachmentFileName,attachmentFileUrl).then((fileImagePreviewResponse:any) => {
      const attachmentFileImagePreview = fileImagePreviewResponse;

      let isFileChanged = true;
        if (attachmentFileUrl !== '') {
          isFileChanged = false;
        }

      const newAttachment = {
        attFileName: attachmentFileName,
        attFileObj: attachmentFileObj,
        attFileSizeBytes: attachmentFileSizeInBytes,
        attFileImagePreview: attachmentFileImagePreview,
        attFileUrl: attachmentFileUrl,
        isFileChanged
      };

      setAttachment(newAttachment);
    });
  };
  /* function definition to formulate attachment preview */

  /* function definition to remove the file attachment */
  const removeFileAttachment = () => {
    setAttachment(null)
  }
  /* function definition to remove the file attachment */

  /* Form submit handler */
  const onSubmit = () => {
    if(!attachment) {
      showToast({
      message:t("CommonUtils.Validations.PleaseSelectAttachment"),
      variant:AppConstConfig.TOAST_VARIANT_ERROR
      }) 
      return;
    }
    setLoadingComponent(true)
    let formData:any = new FormData();
    let index = 0;

    /* Sending data in the form of formData */
    formData.append(`request[${index}].Id`, id);
    formData.append(`request[${index}].UserId`, user && user["value"]);
    formData.append(`request[${index}].DocumentTypeId`, documentType && documentType["value"]);
    formData.append(`request[${index}].ExpiryDate`, dateObjToDispViewIsoFormat(expiryDate));
    formData.append(`request[${index}].Document`, attachment?.attFileObj as File || null);
    formData.append(`request[${index}].FileName`, `${attachment?.attFileName}`);
    formData.append(`request[${index}].IsFileChanged`, attachment?.isFileChanged);
    /* Sending data in the form of formData */

    const dispatchAction = selectedDocumentManagementStatus === t("HR.DocumentManagement.DocumentManagementList.Employee")
      ? addEmployeeDocumentManagementAction
      : addCompanyDocumentManagementAction;

    dispatch(dispatchAction(navigate, showToast, formData)).then(()=>{
      setLoadingComponent(false)
    })
  }
  /* Form submit handler */

  return (
    <Card>
      {
        loadingComponent ? 
        <TableLoader/>
        :
      <div className="document-management-form-container flex_box flex_box--column">
        <form onSubmit={handleSubmit(onSubmit)} name="departmentForm" id="departmentForm">
          <Grid container spacing="sm">
            {
              selectedDocumentManagementStatus === t("HR.DocumentManagement.DocumentManagementList.Employee") &&
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Label required>{t("HR.DocumentManagement.DocumentManagementForm.EmployeeName")}</Label>
                <Select
                  name="user"
                  options={userFullNameSelectList}
                  control={control}
                  customIcon={<SearchIcon />}
                  isClearable
                  isLoading={apiLoader}
                />
              </Grid>
            }

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label required>{t("HR.DocumentManagement.DocumentManagementForm.DocumentType")}</Label>
              <Select
                name="documentType"
                options={documentTypeSelectList}
                control={control}
                isClearable
                isLoading={apiLoader}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Label required>{t("HR.DocumentManagement.DocumentManagementForm.ExpiryDate")}</Label>
              {/* <InputField name="expiryDate" type="date" onKeyDown={(e)=>e.preventDefault()} min={isoToDispViewDate(new Date(), AppConstConfig.ISO_TO_DISP_VIEW_DATE_FORMAT)} control={control} /> */}
              <Datepicker name="expiryDate" control={control} minDate={new Date()} isClearable />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Grid
                container
                alignItems="center"
                style={{ width: "calc(100% - 60px)" }}
              >
                <Grid item sm={6} md={6} lg={6}>
                  <Label required>{t("CommonUtils.Attachments")}</Label>
                </Grid>
                <Grid item sm={6} md={6} lg={6} textAlign="end">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={attachmentInputRef}
                    onChange={onAttachmentChanged}
                  />
                  <IconButton onClick={openAttachmentPicker}>
                    <AttachmentIcon
                      fillColor={AppConstConfig.APP_PRIMARY_COLOR}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              {attachment &&
                attachment !== null &&
                attachment !== undefined &&
                Object.keys(attachment).length !== 0 && (
                  <Grid container>
                    <Grid item lg={4}>
                      <FileAttachment attachmentObj={attachment} removeAttachment={removeFileAttachment}/>
                    </Grid>
                  </Grid>
                )}
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Button
                type="submit"
                form="departmentForm"
              >
                {t("CommonUtils.Button.Submit")}
              </Button>
              
              <Button variant="outlined" onClick={(event:React.SyntheticEvent)=> {
                event.preventDefault()
                reset({
                  ...defaultValues
                })
                setAttachment(null)
              }}>
                {t("CommonUtils.Button.Reset")}
              </Button>
              
              <Button variant="outlined" onClick={(event:React.SyntheticEvent)=>{
                event.preventDefault()
                navigate(AppRoutingConfig.APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_LIST)
              }}>
                {t("CommonUtils.Button.Back")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      }
    </Card>
  )
}

export default DocumentManagementEditForm