import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./regionApis";
import { AxiosResponse } from "axios";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import { Dispatch, SetStateAction } from "react";

/* GET REGION */
export const getRegionsAction =
  (
    dataToBeSent: {isActive:boolean | null,orderBy: boolean;pageNumber: number;pageSize: number, searchString:string}
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getRegions(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_REGIONS_LIST, {regions:response?.data}));
    return response 
  };

/* GET REGION DETAIL BY ID */
export const getRegionDetailAction =
  (
    dataToBeSent: {RegionId:number}
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getRegionDetail(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_REGION_DETAIL, {regionDetails:response?.data}));
    return response 
  };

/* ADD REGION */
export type AddRegionTypes = {
  id: number,
  regionName: string,
  regionDescription: string,
  phone: string,
  contactName: string,
  contactNo: string,
  address1: string,
  address2: string,
  country: number,
  state: number,
  city: number,
  zip: string,
  fax: string,
  departmentId: number,
  services: string
}
export const addRegionAction =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddRegionTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.addRegion(dataToBeSent)
    if(response) {
      navigate(AppRoutingConfig.APP_URL_ADMIN_REGION_MODULE_LIST)
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response 
  };

  /* CHANGE REGION STATUS */
  export const changeRegionStatusAction =
  (
    navigate: NavigateFunction,
    showToast:(value:{message:string, variant:string})=>void,
    dataToBeSent: {regionId:number, isActive:boolean}
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.changeRegionStatus(dataToBeSent)
    if (response) {
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response 
  };

/* GET REGION SELECT LIST */
export const getRegionSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getRegionSelectList()
    dispatch(Actions.createAction(Actions.GET_REGION_SELECT_LIST, {regions:response?.data}));
    return response 
  };

/* GET COUNTRY SELECT LIST */
export const getCountrySelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getCountrySelectList()
    response && dispatch(Actions.createAction(Actions.GET_COUNTRY_SELECT_LIST, {countries:response?.data}));
    return response 
  };


  /* GET STATE SELECT LIST */
export const getStateSelectListAction = (dataToBeSent:{CountryId:number}) =>
async (dispatch: any): Promise<AxiosResponse<any> | null> => {
  const response:any = await requestFromServer.getStateSelectList(dataToBeSent)  
  response && dispatch(Actions.createAction(Actions.GET_STATE_SELECT_LIST, {states:response?.data}));
  return response 
};


  /* GET CITY SELECT LIST */
  export const getCitySelectListAction = (dataToBeSent:{StateId:number}) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getCitySelectList(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_CITY_SELECT_LIST, {cities:response?.data}));
    return response 
  };