import AppModuleConfig from "../../assets/config/AppModuleConfig";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";
import { translate } from "../../i18n/i18n";

export const useFinanceMenuUtils = () => {
  const tempMenuGroup = [];

  /* menuItemFinanceDashboard  */
  const menuItemFinanceDashboard = {
    id: "financeDashboard",
    title: AppModuleConfig.FINANCE_MODULES.DASHBOARD,
    url: AppRoutingConfig.APP_URL_FINANCE_DASHBOARD,
  };
  tempMenuGroup.push(menuItemFinanceDashboard);
  /* menuItemFinanceDashboard  */

  return tempMenuGroup;
};
