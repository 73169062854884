import { configureStore } from '@reduxjs/toolkit'
import rootReducer from '../reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage"

const persistConfig = {
  key:"persist",
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer())

const store = configureStore({
  reducer: persistedReducer,
  middleware:(getDefaultMiddleWare) => getDefaultMiddleWare({
    serializableCheck: false,
  }),
})

const persistor = persistStore(store)

export {store, persistor}

// export const store = configureStore({
//   reducer: rootReducer(),
// })

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
