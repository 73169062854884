import SearchableSelect from "react-select";
import { components } from "react-select";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import { Props as ReactSelectProps } from "react-select";
import {
  SelectArrowDownIndicatorIcon,
  SelectArrowUpIndicatorIcon,
} from "../../../assets/svgs/svg-components";
import { Control, useController } from "react-hook-form";
import ErrorText from "../errorText";
import { isObjectEmpty } from "../../../utils/appCommonUtils";
import { ReactElement } from "react";
import "./Select.scss"

type SelectValueType = {
  label: string;
  value: any;
};
type Props = {
  name: string;
  options: SelectValueType[];
  onCustomChange?: (option: SelectValueType,isClearAction:boolean, triggeredAction?: any) => void;
  customIcon?: ReactElement;
  control: Control<any>;
  placeholder?:string;
  width?:string;
  height?:string;
  isDisabled?:boolean;
  fontSize?:string;
} & ReactSelectProps;

const Select = ({
  name,
  value,
  options,
  width,
  height,
  onCustomChange,
  control,
  customIcon,
  placeholder,
  isDisabled,
  fontSize,
  ...props
}: Props) => {
  /* Changing the default indicator of react select as per our conventions */
  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {customIcon && customIcon !== undefined && customIcon !== null ? (
            customIcon 
          ) : props.selectProps.menuIsOpen ? (
            <SelectArrowUpIndicatorIcon fillColor={AppConstConfig.GRAY_COLOR} />
          ) : (
            <SelectArrowDownIndicatorIcon
              fillColor={AppConstConfig.GRAY_COLOR}
            />
          )}
        </components.DropdownIndicator>
      )
    );
  };
  /* Changing the default indicator of react select as per our conventions */

  /* Changing the default styles of react select as per our conventions */
  const searchableSelectStyle = {
    control: (base: any, state: any) => ({
      ...base,
      border:
        errors && errors[name]
          ? `1px solid ${AppConstConfig.RED_COLOR}`
          : `1px solid ${AppConstConfig.LIGHT_BLUE_GRAY_COLOR}`,
      width: !width ? "calc(100% - 3rem)" : width,
      minHeight:!height ? "42px" : height,
      background: isDisabled ? AppConstConfig.LIGHT_GRAYISH_BLUE_COLOR : AppConstConfig.WHITE_COLOR,
      color:AppConstConfig.GRAY_COLOR,
      fontSize:!fontSize ? "14px" : fontSize,
      borderRadius: "6px",
      cursor: "pointer",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        border:
          errors && errors[name]
            ? `1px solid ${AppConstConfig.RED_COLOR}`
            : `1px solid ${AppConstConfig.LIGHT_BLUE_GRAY_COLOR}`,
      },
    }),
    indicatorSeparator: (styles: any) => ({ display: "none" }),
    menu: (base: any, state: any) => ({
      ...base,
      width: ! width ? "calc(100% - 3rem)" : width,
      padding: 0,
      margin: 0,
      borderRadius: "0 0 6px 6px",
      zIndex:'9999'
    }),
    option: (styles:any, state:any) => {
      return {
        ...styles,
        backgroundColor: state?.isSelected ? AppConstConfig.APP_PRIMARY_COLOR : state?.isFocused ? "#d1edff" : null,
        cursor:'pointer'
      };
    }
  };
  /* Changing the default styles of react select as per our conventions */


  const {
    field,
    formState: { errors },
  } = useController({ name, control });

  const handleChange = (
    option: { value: string | number; label: string },
    triggeredAction: any
  ) => {
    const isClearAction = triggeredAction.action === "clear"
    field.onChange(option);
    onCustomChange && onCustomChange(option, isClearAction, triggeredAction);
  };

  return (
    <>
      <SearchableSelect
        {...field}
        {...props}
        id={name}
        options={options && options !== null && options !== undefined ? options : []}
        isDisabled={isDisabled}
        styles={searchableSelectStyle}
        components={{ DropdownIndicator }}
        getOptionLabel={ element => element.label}
        getOptionValue={ element => element.value}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : ""}
        className="searchable-select"
        />
      {!isObjectEmpty(errors) && errors[name] ? (
        <ErrorText>{`${errors[name]?.message}`}</ErrorText>
      ) : (
        ""
      )}
    </>
  );
};

export default Select;
