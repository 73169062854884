import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import LeaveManagementConfig from "./leaveManagement/LeaveManagementConfig";
import { Navigate } from "react-router";

const LeaveConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_LEAVE,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_LEAVE_MANAGEMENT_MODULE_LIST} replace />
      ),
    },
    ...LeaveManagementConfig.routes
  ]
};

export default LeaveConfig;
