import { Location, NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./customerManagementApis";
import { Dispatch, SetStateAction } from "react";
import { AxiosResponse } from "axios";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";

//GET CUSTOMER LIST
export const getCustomerManagementAction =
  (
    dataToBeSent: {
      isActive: null;
      orderBy: boolean;
      pageNumber: number;
      pageSize: number;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCustomer(dataToBeSent)
    response &&
      dispatch(
        Actions.createAction(Actions.GET_CUSTOMER_MANAGEMENT_LIST, {
          customers: response?.data,
        })
      );
    return response;
  };

//Add Edit Customer
export type AddEditCustomer = {
  customerId: string;
  customerName: string;
  addressLine1: string;
  addressLine2: string;
  country: null;
  state: null;
  city: null;
  zipCode: string;
  phone: string;
  fax: string;
  email: string;
  website: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingCountry: null;
  billingState: null;
  billingCity: null;
  billingZipcode: number;
  contactName: string;
  contactPosition: string;
  contactWorkPhone: string;
  contactMobile: string;
  contactEmail: string;
  regionId: number;
};

export const addEditCustomerManagementAction =
  (
    showToast: (value: { message: string; variant: string }) => void,
    navigate: NavigateFunction,
    setLoading: Dispatch<SetStateAction<boolean>>,
    dataToBeSent: AddEditCustomer,
    location:Location
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.addEditCustomer(dataToBeSent)

    if (response) {
      if(location.pathname.includes("sales")){
        navigate(AppRoutingConfig.APP_URL_SALES_CUSTOMERS_MODULE_LIST);
      }
      else if(location.pathname.includes("admin")){
        navigate(AppRoutingConfig.APP_URL_ADMIN_CUSTOMERS_MODULE_LIST);
      }
      else {
        navigate(AppRoutingConfig.APP_URL_CUSTOMER_MANAGEMENT_MODULE_LIST);
      }
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response;
  };
//Add Edit Customer

//Get Customer By Id

export const getCustomerByIdAction =
  (
    dataToBeSent: { CustomerId: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCustomerById(dataToBeSent)
    return response;
  };
//Get Customer By Id

export const getCustomerIdAction =
  () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCustomerId()
    response &&
      dispatch(Actions.createAction(Actions.GET_CUSTOMER_ID, response?.data));
    return response;
  };

/* GET CUSTOMER SELECT LIST */
export const getCustomerSelectListAction =
  (
    dataToBeSent: { RegionId: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getCustomerSelectList(dataToBeSent)

    response &&
      dispatch(
        Actions.createAction(Actions.GET_CUSTOMER_SELECT_LIST, {
          customerSelectList: response?.data,
        })
      );
    return response;
  };
/* GET CUSTOMER SELECT LIST */

/* UPDATE CUSTOMER STATUS*/
export const updateCustomerStatusAction =
  (
    dataToBeSent: {
      customerId: number;
      isActive: boolean;
    }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.updateCustomerStatus(dataToBeSent)
    return response;
  };
/* UPDATE CUSTOMER STATUS*/
