import Actions from "../actions";
import { produce } from "immer";

interface State {
  customers: {
    totalCount: number;
    customerDetails: [];
  };
  customerSelectList : [],
  addCustomer:[];
  generatedCustomerId:[];
  customerStatus:[];
  confirmCustomerStatus:{
    customerId:number,
    isActive:boolean
  };
}
const initialState: State = {
    customers: {
      totalCount: 0,
      customerDetails: [],
    },
    addCustomer:[],
    generatedCustomerId:[],
  customerSelectList : [],
  customerStatus:[],
  confirmCustomerStatus:{
    customerId:0,
    isActive:false
  }

};

const customerManagement = produce((draft: State, action: any) => {
    const { payload } = action;
    switch (action.type) {
      case Actions.GET_CUSTOMER_MANAGEMENT_LIST: {
        draft.customers = payload?.customers;
        break;
      }
      case Actions.ADD_EDIT_CUSTOMER: {
        draft.addCustomer = payload;
        break;
      }
      case Actions.GET_CUSTOMER_ID: {
        draft.generatedCustomerId = payload;
        break;
      }
      case Actions.GET_CUSTOMER_SELECT_LIST: {
        draft.customerSelectList = payload?.customerSelectList;
        break;
      }

      case Actions.RESET_CUSTOMER_SELECT_LIST: {
        draft.customerSelectList = []
        break;
      }

      case Actions.UPDATE_CUSTOMER_STATUS: {
        draft.customerStatus = payload.customerStatus;
        break;
      }
      case Actions.CONFIRM_CUSTOMER_STATUS: {
        draft.confirmCustomerStatus = payload;
        break;
      }
      default: {
        break;
      }
    }
  }, initialState);
  
export default customerManagement;