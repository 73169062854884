import Actions from "../actions";
import { produce } from "immer";

interface State {
  crmDashboard: {
    leadPool:number;
    assigned:number;
    ongoingCampaigns:number;
    agents:number;
    totalCount: number;
    campaignDetail: [];
  };
}

const initialState: State = {
  crmDashboard: {
    leadPool:0,
    assigned:0,
    ongoingCampaigns:0,
    agents:0,
    totalCount: 0,
    campaignDetail: [],
  }
};

const crmDashboard = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {

    case Actions.GET_CRM_DASHBOARD_LIST: {
      draft.crmDashboard = payload?.crmDashboard;
      break;
    }

    case Actions.EMPTY_CRM_DASHBOARD_LIST: {
      draft.crmDashboard = {
        leadPool:0,
        assigned:0,
        ongoingCampaigns:0,
        agents:0,
        totalCount: 0,
        campaignDetail: [],
      }
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default crmDashboard;
