import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./app/App";
import "./i18n/i18n";
import "./assets/styles/reset.css";
import "./assets/styles/theme-variables.css";
import "./assets/styles/utility-classes.scss";
import "./index.css";
import store from "./redux/store";
import { persistor } from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
  </React.StrictMode>
);
