import React, { FC, useCallback, useEffect, useState } from "react";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import {
  Controller,
  UseControllerProps,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorText from "../../../../common/errorText";
import Select from "../../../../common/select";
import Button from "../../../../common/button";
import TableLoader from "../../../../common/tableLoader";
import InputField from "../../../../common/inputField";
import TextArea from "../../../../common/textArea";
import { useToast } from "../../../../../hooks/useToast";

import {
  getFilteredArrayForMultiSelect,
  getFilteredObjectFromId,
  isoToDispViewDate,
} from "../../../../../utils/appCommonUtils";
import {
  addProjectSchedulingAction,
  getEmployeeSelectListAction,
  getProjectByIdAction,
  getProjectSchedulingDetailAction,
  getVehicleSelectListAction,
} from "../../../../../redux/services/workflow/workFlowServices";
import Actions from "../../../../../redux/actions";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { getProjectListAction } from "../../../../../redux/services/projectSummary/projectSummaryServices";
import ActivityListDetailDialog from "./ActivityListDetailDialog";
import "./WorkFlow.scss";

type Props = {};

const ProjectSchedulingDetail = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  /* loading dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  const params = location?.state;
  /* For navigating between pages */

  /* multi select dependencies */
  const [tempVehicle, setTempVehicle] = useState<any>([]);
  /* multi select dependencies */

  /* add edit dialog dependencies */
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  const [projectSchedule, setProjectSchedule] = useState<any>();
  const [projectName, setProjectName] = useState<any>();

  /* add edit dialog dependencies */
  const {
    projectSelect,
    projectById,
    vehicleSelect,
    employeeSelect,
    addActivity,
    fieldDataList,
    projectScheduleDetail,
  } = useAppSelector((state) => state.workFlow);
  // const {customerId} =projectById
  /* Dependencies for default values and validation */
  type SelectListProps = { value: any; label: string };
  type DefaultValueTypes = {
    id: number;
    project: null;
    customerName: string;
    serviceOrderNo: number;
    addressLine1: string;
    addressLine2: string;
    countryName: string;
    stateName: string;
    cityName: string;
    zipCode: number;
    amount: string;
    status: string;
    typeOfWork: string;
    startDate: string;
    endDate: string;
    employee: SelectListProps[];
    vehicleId: SelectListProps[];

    workDetails: string;
    fieldDataDetails: Array<{
      id: number;
      status: string;
    }>;
  };

  const defaultValues: DefaultValueTypes = {
    id: 0,
    project: null,
    customerName: "",
    serviceOrderNo: 0,
    addressLine1: "",
    addressLine2: "",
    countryName: "",
    stateName: "",
    cityName: "",
    zipCode: 0,
    amount: "",
    status: "",
    typeOfWork: "",
    startDate: "",
    endDate: "",
    employee: [],
    vehicleId: [],
    workDetails: "",
    fieldDataDetails: [],
  };

  const schema = yup.object().shape({
    project: yup
      .object()
      .required(
        t("Operations.WorkFlow.ProjectSchedulingForm.ProjectIsRequired")
      ),
    employee: yup
      .array()
      .min(1, t("Operations.WorkFlow.ProjectSchedulingForm.EmployeeIsRequired"))
      .required(
        t("Operations.WorkFlow.ProjectSchedulingForm.EmployeeIsRequired")
      ),
    vehicleId: yup
      .array()
      .min(1, t("Operations.WorkFlow.ProjectSchedulingForm.VehicleIsRequired"))
      .required(
        t("Operations.WorkFlow.ProjectSchedulingForm.VehicleIsRequired")
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    setValue,
    watch,
    reset,
    resetField,
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();
  const { project, employee, vehicleId } = form;

  /* Dependencies for default values and validation */
  const selectListApiCalls = (details: any) => {
    dispatch(getEmployeeSelectListAction());
    dispatch(getVehicleSelectListAction());
  };

  /* load initial dependencies - Add */
  useEffect(() => {
    dispatch(getProjectListAction({CustomerId: ""}));
  }, []);
  /* load initial dependencies - Add */

  /* load initial dependencies - Edit */
  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { projectSchedulingId } = params;

      setLoadingComponent(true);
      const dataToBeSent = { ProjectScheduleId: projectSchedulingId };
      if (projectSchedulingId) {
        dispatch(getProjectSchedulingDetailAction(dataToBeSent)
        ).then((apiRes) => {
          const projectScheduleDetail = apiRes?.data[0];
          setProjectSchedule(projectScheduleDetail);
          if (projectScheduleDetail) {
            const {projectScheduleVehicle, projectScheduleUser,projectScheduleFieldData, endDate, startDate,projectId, id, ...rest } = projectScheduleDetail;
            /* to rest employe and vehicle select*/
            selectListApiCalls(rest);

            getFilteredArrayForMultiSelect( projectScheduleVehicle,vehicleSelect).then((vehicle: any) => {
              setValue("vehicleId", vehicle);
            });

            getFilteredArrayForMultiSelect( projectScheduleUser, employeeSelect).then((employee: any) => {
              setValue("employee", employee);
            });
            
            getFilteredArrayForMultiSelect( projectScheduleFieldData, fieldDataList).then((fieldData: any) => {
              dispatch(Actions.createAction(Actions.CLEAR_PROJECT_SCHEDULING_ACTIVITY));
            });

            const option = {
              value: projectId,
            };
            getFilteredObjectFromId(projectId, projectSelect).then((project: any) => {
                setProjectName(project);
              }
            );
            /* to rest employe and vehicle select*/
            handleDispatchId(option);

            reset({
              endDate: isoToDispViewDate(endDate, "yyyymmdd"),
              startDate: isoToDispViewDate(startDate, "yyyymmdd"),
            });
            setLoadingComponent(false)
          }
        });
      }
    }
  }, []);
  /* load initial dependencies - Edit */

  /*dispatch id's on project select */
  const handleDispatchId = (option: any) => {
    const dataToBeSent = {
      projectId: option.value,
      projectScheduleId: !params?.projectSchedulingId
        ? 0
        : params?.projectSchedulingId,
    };
    dispatch(
      Actions.createAction(Actions.SELECT_PROJECT_SCHEDULING_ID, {
        selectedProjectSchedulingId: dataToBeSent,
      })
    );
  };
  /*dispatch id's on project select */
  /* function definition to handle add/edit - Field Data */
  const handleActivityListDialogOpen = (e: React.SyntheticEvent, row: any) => {
    setOpenAddEditDialog(true);
  };
  /* function definition to handle add/edit - Field Data */

  /* function definition to handle add/edit - Dialog close */
  const handleActivityListDialogClose = useCallback(() => {
    setOpenAddEditDialog(false);
  }, []);
  /* function definition to handle add/edit - Dialog close */

  /*Handle Back Navigate */
  const handleBackNavigate = () => {
    navigate(AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE_LIST);
  };
  /*Handle Back Navigate */


  return loadingComponent ? (
    <Card>
      <TableLoader />
    </Card>
  ) : (
    <>
      <Card>
        <div className="workflow-form-container flex_box flex_box--column">
          <Grid container>
            <Title>Add Project Scheduling</Title>
          </Grid>
   
            <Grid container spacing="sm">
              {/* Form Field Start */}
              {/* Project */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Project")}
                </Label>
                <>
                <div className="label_info">
                    {projectName?.label || "-"}
                  </div>
                </>
              </Grid>
              {/* Customer */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Customer")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.customerName || "-"}
                </div>
              </Grid>
              {/* ServiceOrderNo */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t(
                    "Operations.WorkFlow.ProjectSchedulingForm.ServiceOrderNo"
                  )}
                </Label>
                <div className="label_info">
                  {projectSchedule?.serviceOrderNo || "-"}
                </div>
              </Grid>
              {/* AddressLine1 */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.AddressLine1")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.addressLine1 || "-"}
                </div>
              </Grid>
              {/* AddressLine2 */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.AddressLine2")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.addressLine2 || "-"}
                </div>
              </Grid>
              {/* Country */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Country")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.countryName || "-"}
                </div>
              </Grid>
              {/* State */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.State")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.stateName || "-"}
                </div>
              </Grid>
              {/* City */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.City")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.cityName || "-"}
                </div>
              </Grid>
              {/* ZipCode */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.ZipCode")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.zipCode || "-"}
                </div>
              </Grid>
              {/* Amount */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Amount")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.amount || "-"}
                </div>
              </Grid>
              {/* Status */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Status")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.status || "-"}
                </div>
              </Grid>
              {/* StartDate */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.StartDate")}
                </Label>
                <div className="label_info">
                  {isoToDispViewDate(projectSchedule?.startDate, "yyyymmdd") ||
                    "-"}
                </div>
              </Grid>
              {/* End Date */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.EndDate")}
                </Label>
                <div className="label_info">
                  {isoToDispViewDate(projectSchedule?.endDate, "yyyymmdd") ||
                    "-"}
                </div>
              </Grid>
              {/* Type of work */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.TypeOfWork")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.typeOfWork || "-"}
                </div>
              </Grid>
              {/* Employee */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Employee")}
                </Label>
                <>
                  <ol type={"1"}>
                    {employee?.length > 0
                      ? employee.map((element: any, index: number) => {
                          return (
                            <li className="label_info">
                              {element?.label}
                              {index !== employee?.length - 1 ? ",  " : ""}
                            </li>
                          );
                        })
                      : ""}
                  </ol>
                </>
              </Grid>
              {/* Vehicle */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Vehicle")}
                </Label>
                <>
                  <ol type={"1"}>
                    {vehicleId?.length > 0
                      ? vehicleId.map((element: any, index: number) => {
                          return (
                            <li className="label_info">
                              {element?.label}
                              {index !== employee?.length - 1 ? ",  " : ""}
                            </li>
                          );
                        })
                      : ""}
                  </ol>
                </>
              </Grid>
              {/* ActivityList Button */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Button
                  variant="outlined"
                  type="button"
                  disabled={project === null ? true : false}
                  onClick={(e) => handleActivityListDialogOpen(e, null)}
                >
                  Activity List
                </Button>
                {errors["fieldDataDetails"]?.message !== undefined ? (
                  <ErrorText>{`${errors["fieldDataDetails"]?.message}`}</ErrorText>
                ) : (
                  ""
                )}
              </Grid>
              {/* Work Details */}
              <Grid item xs={12}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.WorkDetails")}
                </Label>
                <div className="label_info">
                  {projectSchedule?.workDetail || "-"}
                </div>
              </Grid>
              {/* Form Field End */}
              {/* Submit / Reset / Cancel Button */}
              <Grid item xs={12} textAlign="center">
                <Button variant="outlined" onClick={handleBackNavigate}>
                  {t("CommonUtils.Button.Back")}
                </Button>
              </Grid>
            </Grid>


          {openAddEditDialog ? (
            <ActivityListDetailDialog
              open={openAddEditDialog}
              onClose={() => handleActivityListDialogClose()}
            />
          ) : (
            ""
          )}
        </div>
      </Card>
    </>
  );
};

export default ProjectSchedulingDetail;
