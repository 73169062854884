export const GET_PROJECT_SUMMARY_LIST = null
export const ADD_PROJECT_SUMMARY_DETAILS = null
export const GET_PROJECT_ID = null
export const GET_CUSTOMER_SELECT_LIST  = null
export const GET_OPERATION_STATUS_SELECT_LIST = null
export const GET_PROJECT_SUMMARY_DETAIL = null
export const UPDATE_PROJECT_SUMMARY_ACTIVE_INACTIVE_STATUS= null;
export const UPDATE_PROJECT_SUMMARY_STATUS = null
export const SELECTED_PROJECT_SUMMARY_DETAIL = null;
export const SELECTED_PROJECT_SUMMARY_ID = null
export const PROJECT_SUMMARY_UPDATE_STATUS = null