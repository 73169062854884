import AppAssetsConfig from "../../../assets/config/AppAssetsConfig";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loading-container">
      <div className="loader-wrapper">
        <div className="Loader">
          <img src={AppAssetsConfig.DEFAULT_COMPANY_LOGO} alt="Ops-Acuity" />
        </div>
        <div className="bouncing-loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
