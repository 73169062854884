import { Navigate } from "react-router-dom";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { lazy } from "react";
import CustomerManagementForm from "./CustomerManagementForm";
import CustomerManagementDetail from "./CustomerManagementDetail";
// import ProjectSummaryForm from './ProjectSummaryForm';

const CustomerManagementList = lazy(() => import("./CustomerManagementList"));

const CustomerManagementConfig = {
  routes: [
    {
      index: true,
      element: (
        <Navigate
          to={AppRoutingConfig.APP_URL_CUSTOMER_MANAGEMENT_MODULE_LIST}
          replace
        />
      ),
    },
    {
      path: AppRoutingConfig.APP_URL_CUSTOMER_MANAGEMENT_MODULE_LIST,
      element: <CustomerManagementList />,
      handle: { crumb: () => "Customers" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_OPERATIONS_CUSTOMER_MODULE,
      element: <CustomerManagementForm />,
      handle: { crumb: () => "Add Customer" },
    },
    {
      path: AppRoutingConfig.APP_URL_OPERATIONS_CUSTOMER_MODULE_DETAIL,
      element: <CustomerManagementDetail />,
      handle: { crumb: () => "Customer Detail" },
    },
  ],
};

export default CustomerManagementConfig;
