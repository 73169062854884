
import Actions from "../actions";
import { produce } from "immer";

interface State {
  projectSchedulings: {
    totalCount: number;
    projectScheduleDetails: [];
  };
  projectSchedulingStatus: [];
  activeInactiveProjectScheduling: [];
  addEditProjectSchedulings: [];
  projectScheduleDetail: {};
  confirmProjectScheduleStatus: {
    projectScheduleId:number,
    isActive:boolean
  };
  projectSelect: [];
  vehicleSelect: [];
  fieldDataList: Array<{
    value: number;
    label: string;
    status: object;
    isChecked: boolean;
  }>;
  employeeSelect: [];
  selectedProjectSchedulingId: number;
  activityStatusSelectList: [];
  projectById: {};
  addActivity: Array<{
    id: number;
    status: string;
  }>;
  fieldDatas: {
    totalCount: number;
    fieldDataDetails: [];
  };
  addEditFieldData: [];
  fieldDataDetail: [];
  fieldDataStatus: [];
  confirmFieldDataStatus: {
    fieldDataId:number,
    isActive:boolean
  };
  tempSelectedProjectSchedulingId:0
}

const initialState: State = {
  projectSchedulings: {
    totalCount: 0,
    projectScheduleDetails: [],
  },
  projectSelect: [],
  projectScheduleDetail: {},
  confirmProjectScheduleStatus: {
    projectScheduleId:0,
    isActive:false
  },
  fieldDataList: [{ value: 0, label: "", status: {}, isChecked: false }],
  vehicleSelect: [],
  employeeSelect: [],
  activityStatusSelectList: [],
  projectSchedulingStatus: [],
  selectedProjectSchedulingId: 0,
  addEditProjectSchedulings: [],
  projectById: {},
  activeInactiveProjectScheduling: [],
  addActivity: [],
  fieldDatas: {
    totalCount: 0,
    fieldDataDetails: [],
  },
  addEditFieldData: [],
  fieldDataDetail: [],
  fieldDataStatus: [],
  confirmFieldDataStatus: {
    fieldDataId:0,
    isActive:false
  },
  tempSelectedProjectSchedulingId:0
};

const workFlow = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_PROJECT_SCHEDULING_LIST: {
      draft.projectSchedulings = payload;
      break;
    }
    case Actions.ACTIVE_INACTIVE_PROJECT_SCHEDULE: {
      draft.activeInactiveProjectScheduling = payload.projectScheduleStatus;
      break;
    }
    case Actions.EDIT_PROJECT_SCHEDULING_STATUS: {
      draft.projectSchedulingStatus = payload;
      break;
    }
    case Actions.ADD_EDIT_ROJECT_SCHEDULING_DETAILS: {
      draft.addEditProjectSchedulings = payload;
      break;
    }
    case Actions.GET_PROJECT_SCHEDULING_DETAIL: {
      draft.projectScheduleDetail = payload?.projectScheduleDetail[0];
      break;
    }
    case Actions.CONFIRM_PROJECT_SCHEDULE_STATUS: {
      draft.confirmProjectScheduleStatus = payload;
      break;
    }
    case Actions.GET_VEHICLE_SELECT_LIST: {
      draft.vehicleSelect = payload;
      break;
    }
    case Actions.GET_FIELD_DATA_DETAIL_LIST: {
      draft.fieldDatas = payload?.fieldDatas;
      break;
    }
    case Actions.GET_PROJECT_SELECT_LIST: {
      draft.projectSelect = payload.CustomerId;
      break;
    }
    case Actions.GET_PROJECT_DETAILS_BY_ID: {
      draft.projectById = payload.ProjectId;
      break;
    }
    case Actions.GET_EMPLOYEE_SELECT_LIST: {
      draft.employeeSelect = payload?.employees;
      break;
    }
    case Actions.GET_ACTIVITY_STATUS_SELECT_LIST: {
      draft.activityStatusSelectList = payload?.activityStatusSelectList;
      break;
    }

    case Actions.SELECTED_PROJECT_SCHEDULING_ID: {
      draft.selectedProjectSchedulingId = payload?.selectedProjectSchedulingId;
      break;
    }

    case Actions.GET_FIELD_DATA_LIST: {
      draft.fieldDataList = payload?.fieldDataList;
      break;
    }

    case Actions.EMPTY_FIELD_DATA_LIST: {
      draft.fieldDataList = [];
      break;
    }

    case Actions.UPDATE_ACTIVITY_STATUS_FOR_FIELD_DATA_LIST: {
      const updatedFieldDataList = draft?.fieldDataList?.map((element)=>{
        if(element?.value === payload?.activityStatus?.activityId){
          return {
            ...element,
            status:payload?.activityStatus?.status?.value
          }
        }
        return {...element}
      })
      draft.fieldDataList = updatedFieldDataList;
      break;
    }

    case Actions.UPDATE_ACTIVITY_CHECKBOX_FOR_FIELD_DATA_LIST: {
      const updatedFieldDataList = draft?.fieldDataList?.map((element)=>{
        if(element?.value === payload?.activityCheckbox?.activityId){
          return {
            ...element,
            isChecked:payload?.activityCheckbox?.checked
          }
        }
        return {...element}
      })
      draft.fieldDataList = updatedFieldDataList;
      break;
    }

    case Actions.UPDATE_FIELD_DATA_LIST: {
      const updatedFieldActivities = draft?.fieldDataList?.map((item) => {
        const { status, ...rest } = item;
        if (item?.value === payload.updateFieldDataList.activityId) {
          return {
            status: payload.updateFieldDataList.option,
            ...rest,
          };
        } else {
          return JSON.parse(JSON.stringify(item));
        }
      });

      draft.fieldDataList = updatedFieldActivities;

      break;
    }
    case Actions.ADD_PROJECT_SCHEDULING_ACTIVITY: {
      const isDuplicate = draft?.addActivity?.find(
        (item) => item.id === payload.addActivity.activityId
      );
      if (!isDuplicate) {
        draft.addActivity = [...draft.addActivity, payload.addActivity];
      }
      break;
    }
    case Actions.DELETE_PROJECT_SCHEDULING_ACTIVITY: {
      const filteredArray = draft?.addActivity?.filter(
        (item) => item.id !== payload?.deleteActivity
      );
      draft.addActivity = filteredArray;

      break;
    }

    case Actions.CONCAT_PROJECT_SCHEDULING_ACTIVITY: {
      if (payload.length > 0) {
        draft.addActivity = draft.addActivity.concat(payload);
      }

      break;
    }
    case Actions.CLEAR_PROJECT_SCHEDULING_ACTIVITY: {
      draft.addActivity = []
      break;
    }
    
    case Actions.SELECT_PROJECT_SCHEDULING_ID: {
      draft.selectedProjectSchedulingId = payload.selectedProjectSchedulingId;
      break;
    }

    case Actions.ADD_EDIT_FIELD_DATA_DETAILS: {
      draft.addEditFieldData = payload?.addEditFieldData;
      break;
    }

    case Actions.GET_FIELD_DATA_DETAILS: {
      draft.fieldDataDetail = payload;
      break;
    }
    case Actions.CLEAR_FIELD_DATA_DETAILS: {
      draft.fieldDataList = [];
      break;
    }

    case Actions.UPDATE_FIELD_DATA_STATUS: {
      draft.fieldDataStatus = payload;
      break;
    }
    case Actions.CONFIRM_FIELD_DATA_STATUS: {
      draft.confirmFieldDataStatus = payload;
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default workFlow;
