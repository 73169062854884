export default class AppAssetsConfig {
    static DEFAULT_COMPANY_LOGO = "/assets/images/company-logo.png"
    static DEFAULT_COMPANY_LOGO_WITH_NAME = "/assets/images/company-logo-with-name.png"
    static DEFAULT_PROFILE_ICON = "/assets/images/profile-icon.png"
    static DEFAULT_SIGN_IN_IMAGE = "/assets/images/sign-in.png"
    static DEFAULT_ATTACHMENT = "/assets/images/icon-attachment.png"

    /* DEFAULT PREVIEW ATTACHMENTS */
    static FILE_TYPE_PREVIEW_BASE_PATH = "/assets/appCustomImages/defaults/"
    static IMAGE_TYPE_FILE_PREVIEW = this.FILE_TYPE_PREVIEW_BASE_PATH + 'file-type-image-outline.png';
    static PDF_TYPE_FILE_PREVIEW = this.FILE_TYPE_PREVIEW_BASE_PATH + 'file-type-pdf-outline.png';
    static TXT_TYPE_FILE_PREVIEW = this.FILE_TYPE_PREVIEW_BASE_PATH + 'file-type-txt-outline.png'
    static DOC_TYPE_FILE_PREVIEW = this.FILE_TYPE_PREVIEW_BASE_PATH + 'file-type-doc-outline.png';
    static XLS_TYPE_FILE_PREVIEW = this.FILE_TYPE_PREVIEW_BASE_PATH + 'file-type-xls-outline.png';
    static PPT_TYPE_FILE_PREVIEW = this.FILE_TYPE_PREVIEW_BASE_PATH + 'file-type-ppt-outline.png';
    static RANDOM_TYPE_FILE_PREVIEW = this.FILE_TYPE_PREVIEW_BASE_PATH + 'file-type-document-outline.png';
    /* DEFAULT PREVIEW ATTACHMENTS */
}