import Actions from "../actions";
import { produce } from "immer";

interface State {
  activities:{
    totalCount:number;
    activityDetails:[]
  };
  selectedActivityId:number;
  activityDetails:{}
}

const initialState: State = {
  activities: {
    totalCount:0,
    activityDetails:[]
  },
  selectedActivityId:0,
  activityDetails:{}
};

const activity = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_ACTIVITIES_LIST: {
      draft.activities = payload?.activities;
      break;
    }

    case Actions.SELECTED_ACTIVITY_ID: {
      draft.selectedActivityId = payload?.selectedActivityId;
      break;
    }

    case Actions.GET_ACTIVITY_DETAIL: {
      draft.activityDetails = payload?.activityDetails;
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default activity;
