import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';
import DocumentManagementEditForm from './DocumentManagementEditForm';

const DocumentManagementList = lazy(()=> import("./DocumentManagementList"))
const DocumentManagementAddForm = lazy(()=> import("./DocumentManagementAddForm"))
const DocumentManagementDetails = lazy(()=> import("./DocumentManagementDetails"))

const DocumentManagementConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_LIST,
      element: <DocumentManagementList />,
      handle: { crumb: () => "Document Management" },
    },
    {
      path: AppRoutingConfig.APP_URL_ADD_HR_DOCUMENT_MANAGEMENT_MODULE,
      element: <DocumentManagementAddForm/>,
      handle: { crumb: () => "Add Document" },
    },
    {
      path: AppRoutingConfig.APP_URL_EDIT_HR_DOCUMENT_MANAGEMENT_MODULE,
      element: <DocumentManagementEditForm/>,
      handle: { crumb: () => "Add Document" },
    },
    {
      path: AppRoutingConfig.APP_URL_HR_DOCUMENT_MANAGEMENT_MODULE_DETAIL,
      element: <DocumentManagementDetails/>,
      handle: { crumb: () => "Document detail" },
    }
  ],
};

export default DocumentManagementConfig