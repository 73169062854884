import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const CustomersList = lazy(()=> import("../../operations/customerManagement/CustomerManagementList"))
const CustomersForm = lazy(()=> import("../../operations/customerManagement/CustomerManagementForm"))
const CustomersDetail = lazy(()=> import("../../operations/customerManagement/CustomerManagementDetail"))

const CustomersConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_ADMIN_CUSTOMERS_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_CUSTOMERS_MODULE_LIST,
      element: <CustomersList />,
      handle: { crumb: () => "Customers" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_ADMIN_CUSTOMERS_MODULE,
      element: <CustomersForm/>,
      handle: { crumb: () => "Add Customer" },
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_CUSTOMERS_MODULE_DETAIL,
      element: <CustomersDetail/>,
      handle: { crumb: () => "Customer Detail" },
    }
  ],
};

export default CustomersConfig