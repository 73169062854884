import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const ActivityList = lazy(()=> import("./ActivityList"));
const ActivityForm = lazy(()=> import("./ActivityForm"));

const ActivityConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_ADMIN_ACTIVITY_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_ACTIVITY_MODULE_LIST,
      element: <ActivityList />,
      handle: { crumb: () => "Activity" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_ADMIN_ACTIVITY_MODULE,
      element: <ActivityForm />,
      handle: { crumb: () => "Add Activity" },
    },
  ],
};

export default ActivityConfig