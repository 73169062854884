import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import AppApiConfig from "../assets/config/AppApiConfig";
import UserSessionService from "../services/UserSessionService";
import { useEffect, useState, useTransition } from "react";
import { useLocation, useNavigate } from "react-router";
import { useToast } from "../hooks/useToast";
import AppRoutingConfig from "../assets/config/AppRoutingConfig";
import AppConstConfig from "../assets/config/AppConstConfig";
import { useAppDispatch } from "../hooks/redux-hooks";
import Actions from "../redux/actions";
import { useTranslation } from "react-i18next";
// Add a request interceptor

const axiosConfigInstance: AxiosInstance = axios.create({
  baseURL: AppApiConfig.API_ENDPOINT,
});

function AxiosInterceptor({ children }: any) {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* navigation dependencies */
  const navigate = useNavigate();
  /* navigation dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  const dispatch = useAppDispatch();

  const [isSet, setIsSet] = useState(false);

  useEffect(() => {
    let isErrorShown = false;

    /* Add a request interceptor */
    const requestInterceptor = (config: InternalAxiosRequestConfig) => {
      const token = UserSessionService.getUserSessionToken();
      if (token) {
        // document.querySelector(".spinner-container")?.classList.add("spinner-container-active")
        dispatch(Actions.createAction(Actions.API_LOADER, { isLoading: true }));
        config.headers!.Authorization = `Bearer ${token}`;
      }
      return config;
    };

    const errorReqInterceptor = (error: AxiosError) => {
      dispatch(Actions.createAction(Actions.API_LOADER, { isLoading: false }));
      showToast({
        message: error?.message,
        variant: AppConstConfig.TOAST_VARIANT_ERROR,
      });
    };

    const reqInterceptor = axiosConfigInstance.interceptors.request.use(
      requestInterceptor,
      errorReqInterceptor
    );
    /* Add a request interceptor */

    /* Add a response interceptor */
    const responseInterceptor = (response: AxiosResponse<any>) => {
      // document.querySelector(".spinner-container")?.classList.remove("spinner-container-active")
      dispatch(Actions.createAction(Actions.API_LOADER, { isLoading: false }));
      const { statusCode } = response?.data;
      if (statusCode === 200) {
        return response?.data;
      } 
      else if (statusCode !== 404) {
        showToast({
          message: response?.data?.message,
          variant: AppConstConfig.TOAST_VARIANT_ERROR,
        });
        return null;
      }
    };

    const errorResInterceptor = (error: AxiosError) => {
      dispatch(Actions.createAction(Actions.API_LOADER, { isLoading: false }));
      if (error.response && error.response.status === 401) {
        if (isErrorShown === false) {
          isErrorShown = true;
          UserSessionService.removeUserSession();
          navigate(AppRoutingConfig.APP_URL_SIGN_IN);
          showToast({
            message: t("CommonUtils.SessionExpired"),
            variant: AppConstConfig.TOAST_VARIANT_ERROR,
          });
        }
      } 
      
      else {
        showToast({
          message: error?.message,
          variant: AppConstConfig.TOAST_VARIANT_ERROR,
        });
      }
    };

    const resInterceptor = axiosConfigInstance.interceptors.response.use(
      responseInterceptor,
      errorResInterceptor
    );
    /* Add a response interceptor */

    setIsSet(true);

    return () => {
      isErrorShown = false;
      axiosConfigInstance.interceptors.request.eject(reqInterceptor);
      axiosConfigInstance.interceptors.response.eject(resInterceptor);
    };
  }, [navigate]);

  return <>{isSet && children}</>;
}

export default axiosConfigInstance;
export { AxiosInterceptor };
