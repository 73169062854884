import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const QuotesList = lazy(()=> import("./QuotesList"))
const QuotesForm = lazy(()=>import("./QuotesForm"))
const QuotesDetails = lazy(()=>import("./QuotesDetails"))


const QuotesConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_SALES_QUOTES_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_QUOTES_MODULE_LIST,
      element: <QuotesList />,
      handle: { crumb: () => "Quotes" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_SALES_QUOTES_MODULE,
      element: <QuotesForm />,
      handle: { crumb: () => "Add Quote" },
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_QUOTES_MODULE_DETAIL,
      element: <QuotesDetails />,
      handle: { crumb: () => "Quote Detail" },
    }
  ],
};

export default QuotesConfig