import Actions from "../actions";
import { produce } from "immer";

interface State {
  agents: {
    totalCount: number;
    agentDetails: [];
  };
  campaigns: {
    totalCount: number;
    campaingDetails: [];
  };
  selectedAgentId: number;
  selectedCampaignIdForNotes: number;
}

const initialState: State = {
  agents: {
    totalCount: 0,
    agentDetails: [],
  },
  campaigns: {
    totalCount: 0,
    campaingDetails: [],
  },
  selectedAgentId: 0,
  selectedCampaignIdForNotes: 0
};

const agents = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_AGENTS_LIST: {
      draft.agents = payload?.agents;
      break;
    }
    
    case Actions.EMPTY_AGENTS_LIST: {
      draft.agents = {
        totalCount: 0,
        agentDetails: [],
      }
      break;
    }

    case Actions.SELECTED_AGENT_ID: {
      draft.selectedAgentId = payload?.selectedAgentId;
      break;
    }

    case Actions.GET_CAMPAIGN_DETAIL_BY_AGENT_ID_LIST: {
      draft.campaigns = payload?.campaigns;
      break;
    }

    case Actions.EMPTY_CAMPAIGN_DETAIL_BY_AGENT_ID_LIST: {
      draft.campaigns = {
        totalCount: 0,
        campaingDetails: [],
      };
      break;
    }

    case Actions.SELECTED_CAMPAIGN_ID_FOR_NOTES: {
      draft.selectedCampaignIdForNotes = payload?.selectedCampaignIdForNotes;
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default agents;
