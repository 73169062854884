import Actions from "../actions";
import { produce } from "immer";

interface State {
quotesList : {
  totalCount: number;
  quoteDetails:[];
},
selectedQuotesId:number;
quoteDetails:{}
}

const initialState: State = {
    quotesList : {
      totalCount: 0,
      quoteDetails:[]
    },
    selectedQuotesId:0,
    quoteDetails:{}
};

const quotes = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_QUOTES_LIST: {
      draft.quotesList = payload?.quotes;
      break;
    }

    case Actions.SELECTED_QUOTES_ID: {
      draft.selectedQuotesId = payload?.selectedQuotesId;
      break;
    }

    case Actions.GET_QUOTE_DETAILS: {
      draft.quoteDetails = payload?.quoteDetails;
      break;
    }
    
    default: {
      break;
    }
  }
}, initialState);

export default quotes;
