import Actions from "../actions";
import { produce } from "immer";

interface State {
  salesRep: {
    totalCount: number;
    salesRepDetailList: [];
  };
  selectedSalesRepresentive: {
    salesRepId:number;
    salesRepListType:string;
  };
  projects: {
    totalCount: number;
    repId: string;
    salesRep: string;
    projectDetailList: [];
  };
  customers: {
    totalCount: number;
    repId: string;
    salesRep: string;
    customerDetailList: [];
  };
  leads: {
    totalCount: number;
    repId: string;
    salesRep: string;
    leadDetailsList: [];
  };
}
const initialState: State = {
    salesRep: {
      totalCount: 0,
      salesRepDetailList: [],
    },
    selectedSalesRepresentive: {
      salesRepId: 0,
      salesRepListType: ""
    },
    projects: {
      totalCount: 0,
      repId: "",
      salesRep: "",
      projectDetailList: []
    },
    customers: {
      totalCount: 0,
      repId: "",
      salesRep: "",
      customerDetailList: []
    },
    leads: {
      totalCount: 0,
      repId: "",
      salesRep: "",
      leadDetailsList: []
    }
};

const salesRep = produce((draft: State, action: any) => {
    const { payload } = action;
    switch (action.type) {
      case Actions.GET_SALES_REP_LIST: {
        draft.salesRep = payload?.salesRep;
        break;
      }

      case Actions.SELECTED_SALES_REP_ID: {
        draft.selectedSalesRepresentive = payload?.selectedSalesRepresentive;
        break;
      }

      case Actions.GET_PROJECT_DETAIL_BY_SALES_REP_ID_LIST: {
        draft.projects = payload?.projects;
        break;
      }

      case Actions.EMPTY_PROJECT_DETAIL_BY_SALES_REP_ID_LIST: {
        draft.projects = {
          totalCount: 0,
          repId: "",
          salesRep: "",
          projectDetailList: []
        };
        break;
      }

      case Actions.GET_CUSTOMER_DETAIL_BY_SALES_REP_ID_LIST: {
        draft.customers = payload?.customers;
        break;
      }

      case Actions.EMPTY_CUSTOMER_DETAIL_BY_SALES_REP_ID_LIST: {
        draft.customers = {
          totalCount: 0,
          repId: "",
          salesRep: "",
          customerDetailList: []
        };
        break;
      }

      case Actions.GET_LEAD_DETAIL_BY_SALES_REP_ID_LIST: {
        draft.leads = payload?.leads;
        break;
      }

      case Actions.EMPTY_LEAD_DETAIL_BY_SALES_REP_ID_LIST: {
        draft.leads = {
          totalCount: 0,
          repId: "",
          salesRep: "",
          leadDetailsList: []
        };
        break;
      }

      default: {
        break;
      }
    }
  }, initialState);
  
export default salesRep;