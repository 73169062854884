import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';
import CampaignsForm from './CampaignsForm';
import CampaignsDetails from './CampaignsDetail';

const CampaignsList = lazy(()=> import("./CampaignsList"))

const CrmCampaignsConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_CRM_CAMPAIGNS_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_CRM_CAMPAIGNS_MODULE_LIST,
      element: <CampaignsList />,
      handle: { crumb: () => "Campaigns" }
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_CRM_CAMPAIGNS_MODULE,
      element: <CampaignsForm />,
      handle: { crumb: () => "Add Campaign" }
    },
    {
      path: AppRoutingConfig.APP_URL_CRM_CAMPAIGNS_MODULE_DETAIL,
      element: <CampaignsDetails />,
      handle: { crumb: () => "Campaign Criteria" }
    },
  ],
};

export default CrmCampaignsConfig