export default class AppConstConfig{
    
    /* Custom Colors */
    
    // default primary color - app level 
    static APP_PRIMARY_COLOR = "#0097E9"
    static APP_PRIMARY_HOVER_COLOR = "#51aee0"
    // default primary color - app level 

    static LIGHT_GRAY_COLOR = "#BBBBBB"
    static GRAY_COLOR = "#797979"
    static DARK_INDIGO_COLOR = "#353751"
    static LIGHT_BLUE_GRAY_COLOR = "#CDD4D8"
    static SKY_BLUE_COLOR = "#3D96E7"
    static BLACK_COLOR = "#000"
    static JET_BLACK_COLOR = "#181818"
    static WHITE_COLOR = "#fff"
    static LIME_GREEN_COLOR = "#00ff00"
    static TEAL_COLOR = "#02BF90"
    static MAROON_COLOR = "#B11313"
    static RED_COLOR = "#ff0000"
    static CYAN_COLOR = "#46DFE3"
    static LIGHT_BLUE_COLOR = "#c7e9ff"
    static LIGHT_GRAYISH_BLUE_COLOR = "#f1f5fa"
    static ORANGE_COLOR = "#ffc046"
    static DOGER_BLUE_COLOR = "#1b68ff"
    static VIVID_ORANGE_COLOR = "#FFA009"
    static CRIMSON_COLOR = "#d9534f"
    static MEDIUM_GRAY_COLOR ='#6d6d6e'
    static FERN_COLOR = "#5cb85c"
    static MIDNIGHT_BLUE_COLOR = "#202a3c"
    static COLOR_CODES_ARRAY = [
        this.JET_BLACK_COLOR,this.GRAY_COLOR,this.ORANGE_COLOR,this.MAROON_COLOR,
        this.DOGER_BLUE_COLOR,this.VIVID_ORANGE_COLOR,
        this.CRIMSON_COLOR,this.MEDIUM_GRAY_COLOR,this.FERN_COLOR,this.MIDNIGHT_BLUE_COLOR
    ];

    static PINK_COLOR_NAME = "pink";
    static BLUE_COLOR_NAME = "blue";
    static ORANGE_COLOR_NAME = "orange";
    static TEAL_COLOR_NAME = "teal";
    static DEEP_PINK_COLOR_NAME = "deep-pink";
    static CANDY_PINK_COLOR_NAME = "candy-pink";
    static LIME_GREEN_COLOR_NAME = "lime-green";
    static ORANGE_VIOLET_COLOR_NAME = "orange-voilet";
    

    static COLOR_NAMES_ARRAY = [
        this.PINK_COLOR_NAME,
        this.BLUE_COLOR_NAME,
        this.ORANGE_COLOR_NAME,
        this.TEAL_COLOR_NAME,
        this.DEEP_PINK_COLOR_NAME,
        this.CANDY_PINK_COLOR_NAME,
        this.LIME_GREEN_COLOR_NAME,
        this.ORANGE_VIOLET_COLOR_NAME
    ]
    /* Custom Colors */    


    /* Table */
    static DEFAULT_TABLE_PAGE_NUMBER = 1;
    static DEFAULT_TABLE_PAGE_SIZE = 10;
    static TABLE_COLUMN_NAME_ACTIONS = "actions"
    static TABLE_COLUMN_NAME_ACTIVE_INACTIVE = "active/ inactive"
    /* Table */

    /* Toast */
    static TOAST_VARIANT_ERROR = "error"
    static TOAST_VARIANT_SUCCESS = "success"
    static SESSION_EXPIRED_MESSAGE = "Session Expired! Please sign-in again."
    /* Toast */


    /* Date Picker */
    static ISO_TO_DISP_VIEW_DATE_FORMAT = "yyyymmdd"
    /* Date Picker */
}