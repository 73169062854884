import { Navigate } from "react-router-dom";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { lazy } from "react";
import LeadForm from "./LeadForm";
import LeadDetail from "./LeadDetail";

const LeadList = lazy(() => import("./LeadList"));

const LeadConfig = {
  routes: [
    {
      index: true,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_SALES_LEAD_MODULE_LIST} replace />
      ),
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_LEAD_MODULE_LIST,
      element: <LeadList />,
      handle: { crumb: () => "Lead" },
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_ADD_LEAD_MODULE,
      element: <LeadForm/>,
      handle: { crumb: () => "Add Lead" },
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_LEAD_DETAIL_MODULE,
      element: <LeadDetail/>,
      handle: { crumb: () => "Lead Detail" }
    },
  ],
};

export default LeadConfig;
