import React, { useCallback, useEffect, useRef } from "react";
import "./Dialog.scss";
import IconButton from "../iconButton";
import { CloseIcon } from "../../../assets/svgs/svg-components";
import AppConstConfig from "../../../assets/config/AppConstConfig";
import Grid from "../grid";
import { containerCSS } from "react-select/dist/declarations/src/components/containers";

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
};

const Dialog = ({ children, ...props }: Props) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { open, onClose, width } = props;

  /* Function definition to close the dialog when cancel is detected */
  const onCancel = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      if ((event.target as HTMLElement) === dialogRef.current) onClose();
    },
    [onClose]
  );
  /* Function definition to close the dialog when cancel is detected */

  /* Function definition to identify the click event and close the dialog accordingly */
  const onClick = useCallback(
    (event: React.SyntheticEvent) => {
      if ((event.target as HTMLElement) === dialogRef.current) onClose();
    },
    [onClose]
  );
  /* Function definition to identify the click event and close the dialog accordingly */

  /* Function definition to close the dialog on animation end */
  const onAnimationEnd = useCallback(() => {
    if (!open) dialogRef.current?.close();
  }, [open]);
  /* Function definition to close the dialog on animation end */

  /* Initial dependency to show the dialog */
  useEffect(() => {
    if (open) dialogRef.current?.showModal();
  }, [open]);
  /* Initial dependency to show the dialog */

  return (
    <dialog
      ref={dialogRef}
      onClick={onClick}
      onCancel={onCancel}
      className={`dialog ${!open ? "dialog-close" : ""} ${width ? `dialog-width-${width}` : ''}`}
      onClose={onClose}
      onAnimationEnd={onAnimationEnd}
    >
      <Grid container justifyContent="flex-end">
        <IconButton onClick={onClose}>
          <CloseIcon fillColor={AppConstConfig.APP_PRIMARY_COLOR} />
        </IconButton>
      </Grid>
      {children}
    </dialog>
  );
};

export default Dialog;
