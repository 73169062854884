import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const PartnersList = lazy(()=> import("./PartnersList"))
const PartnersForm = lazy(()=> import("./PartnersForm"))
const PartnersDetails = lazy(()=> import("./PartnersDetails"))

const PartnersConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_ADMIN_PARTNERS_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_PARTNERS_MODULE_LIST,
      element: <PartnersList />,
      handle: { crumb: () => "Partners" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_ADMIN_PARTNERS_MODULE,
      element: <PartnersForm />,
      handle: { crumb: () => "Add Partner" },
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_PARTNERS_MODULE_DETAIL,
      element: <PartnersDetails />,
      handle: { crumb: () => "Partner Detail" },
    },
  ],
};

export default PartnersConfig