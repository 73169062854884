import AppApiConfig from "../../../assets/config/AppApiConfig";
import axiosConfigInstance from "../../../axios/axiosInstance";

export const getLeads = (dataToBeSent: {
  isActive: null;
  orderBy: boolean;
  pageNumber: number;
  pageSize: number;
}) => {
  return axiosConfigInstance.post(AppApiConfig.API_LEAD_DETAIL_LIST_URL, dataToBeSent);
};

export const addLead = (dataToBeSent: FormData) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_ADD_EDIT_LEAD_DETAIL_URL,
    dataToBeSent
  );
};

export const getLeadSourceSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_SALES_LEAD_SOURCE_SELECT_LIST_URL
  );
};
export const getStageSelectList = () => {
  return axiosConfigInstance.post(AppApiConfig.API_STAGE_SELECT_LIST_URL);
};

export const getLeadByCampaignIdSelectList = (dataToBeSent: {CampaignId:number}) => {
  return axiosConfigInstance.post(
      AppApiConfig.API_LEAD_BY_CAMPAIGN_ID_SELECT_LIST_URL, 
      {},
      { params: dataToBeSent }
    );
};

export const getCustomerTypeSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_CUSTOMER_TYPE_SELECT_LIST_URL
  );
};

export const getLeadDetail = (dataToBeSent: { LeadId: number }) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_LEAD_DETAIL_URL,
    {},
    { params: dataToBeSent }
  );
};
export const getLeadStatusSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_LEAD_STATUS_SELECT_LIST_URL
  );
};

export const updateLeadStatus = (dataToBeSent: {
  leadId: number;
  leadStatus: string;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_UPDATE_LEAD_STATUS_URL,
    dataToBeSent
  );
};
export const updateLeadStage = (dataToBeSent: {
  leadId: number;
  stageId: number;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_UPDATE_LEAD_STAGE_URL,
    dataToBeSent
  );
};

export const importLeadpoolFile = (dataToBeSent: FormData) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_LEADPOOL_IMPORT_DETAIL_URL,
    dataToBeSent
  );
};