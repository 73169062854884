import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const SalesRepList = lazy(()=> import("./SalesRepList"))


const SalesRepConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_SALES_REP_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_SALES_REP_MODULE_LIST,
      element: <SalesRepList />,
      handle: { crumb: () => "Sales Rep" },
    }
  ]
};

export default SalesRepConfig