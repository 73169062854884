import AppApiConfig from '../../../assets/config/AppApiConfig'
import axiosConfigInstance from '../../../axios/axiosInstance'
import { AddActivityFieldsTypes } from './activityFieldServices'

export const getActivitySelectList = () => {
  return axiosConfigInstance.post(AppApiConfig.API_ACTIVITY_FIELD_MODULE_SELECT_LIST_URL)
}

export const addActivityFields = (dataToBeSent:AddActivityFieldsTypes) => {
  return axiosConfigInstance.post(AppApiConfig.API_SAVE_ACTIVITY_FIELD_DETAIL_URL, dataToBeSent)
}
