import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../common/button";
import InputField from "../../../../common/inputField";
import Select from "../../../../common/select";
import { getProjectListAction } from "../../../../../redux/services/projectSummary/projectSummaryServices";
import { getFilteredObjectFromId } from "../../../../../utils/appCommonUtils";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import TableLoader from "../../../../common/tableLoader";
import { useToast } from "../../../../../hooks/useToast";
import TextArea from "../../../../common/textArea";
import IconButton from "../../../../common/iconButton";
import { AttachmentIcon } from "../../../../../assets/svgs/svg-components";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";
import {
  checkIfFileSizeIsValidForAttachment,
  checkIfFileTypeIsValidForAttachment,
  formulateAttachmentPreview,
} from "../../../../../utils/appFileUtils";
import AppAssetsConfig from "../../../../../assets/config/AppAssetsConfig";
import FileAttachment from "../../../../common/fileAttachment";
import { getRegionSelectListAction } from "../../../../../redux/services/region/regionServices";
import {
  addLeadAction,
  getCustomerTypeListAction,
  getLeadDetailAction,
  getLeadSourceListAction,
  getStageListAction,
} from "../../../../../redux/services/lead/leadServices";
import { getCustomerSelectListAction } from "../../../../../redux/services/customerManagement/customerManagementServices";
import "./Lead.scss";
import AppApiConfig from "../../../../../assets/config/AppApiConfig";
import Actions from "../../../../../redux/actions";
import "./Lead.scss";

type Props = {};

type SelectListProps = { value: any; label: string };

type DefaultValueTypes = {
  id: number;
  region: null;
  customer: null;
  title: string;
  contactName: string;
  contactPosition: string;
  contactEmail: string;
  contactPhone: string;
  leadSource: null;
  customerType: null;
  addStage: null;
  documentTitle: string;
  notes: string;
};

const LeadForm = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  /* loading dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  const params = location?.state;
  const { showToast } = useToast();
  /* For navigating between pages */

  /* searchable select dependencies */
  const { regionSelectList } = useAppSelector((state) => state.region);
  const { customerSelectList } = useAppSelector(
    (state) => state.customerManagement
  );
  const { selectLeadSource, selectCustomerTypes, selectStages } =
    useAppSelector((state) => state.lead);
  /* searchable select dependencies */
  /* module attachment handling dependencies for attachment */
  const defaultAttachmentUrl = AppAssetsConfig.DEFAULT_ATTACHMENT;
  const [attachment, setAttachment] = useState<{
    attFileName: string;
    attFileObj: File | null;
    attFileSizeBytes: number;
    attFileImagePreview: string;
    attFileUrl: string;
    isFileChanged: boolean;
  } | null>({
    attFileName: "",
    attFileObj: null,
    attFileSizeBytes: 0,
    attFileImagePreview: "",
    attFileUrl: "",
    isFileChanged: false
  });
  const attachmentInputRef = useRef<HTMLInputElement>(null);
  /* module attachment handling dependencies for attachment */

  /* Dependencies for default values and validation */

  const defaultValues: DefaultValueTypes = {
    id: 0,
    region: null,
    customer: null,
    title: "",
    contactName: "",
    contactPosition: "",
    contactEmail: "",
    contactPhone: "",
    leadSource: null,
    customerType: null,
    addStage: null,
    documentTitle: "",
    notes: "",
  };

  const schema = yup.object().shape({
    title: yup.string().max(100, `${t("Sales.Lead.LeadForm.Title")} ${t(`CommonUtils.Validations.MustNotExceed100Characters`)}` ).required(t("Sales.Lead.LeadForm.TitleIsRequired")),
    leadSource: yup.object().required(t("Sales.Lead.LeadForm.LeadSourceIsRequired")),
    customerType: yup.object().required(t("Sales.Lead.LeadForm.CustomerTypeIsRequired")),
    addStage: yup.object().required(t("Sales.Lead.LeadForm.AddStageIsRequired")),
    contactName:yup.string().nullable().max(100, `${t("Sales.Lead.LeadForm.ContactName")} ${t(`CommonUtils.Validations.MustNotExceed100Characters`)}`),
    contactPosition:yup.string().nullable().max(50, `${t("Sales.Lead.LeadForm.ContactPosition")} ${t(`CommonUtils.Validations.MustNotExceed50Characters`)}`),
    contactPhone:yup.string().nullable().max(20, `${t("Sales.Lead.LeadForm.ContactPhone")} ${t(`CommonUtils.Validations.ShouldNotExceed20Numbers`)}`),
    contactEmail:yup.string().nullable().max(100, `${t("Sales.Lead.LeadForm.ContactEmail")} ${t(`CommonUtils.Validations.MustNotExceed100Characters`)}`),
    documentTitle:yup.string().nullable().max(100, `${t("Sales.Lead.LeadForm.DocumentTitle")} ${t(`CommonUtils.Validations.MustNotExceed100Characters`)}`)
  });

  const {handleSubmit, control, setValue, reset} = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  /* Dependencies for default values and validation */

  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {

    // Region select list
    dispatch(getRegionSelectListAction()).then(
      (apiRes) => {
        details && apiRes?.data && getFilteredObjectFromId(details?.regionId, apiRes?.data).then(
            (region: any) => {
              region && loadDispatchCustomerForRegion(region);
              setValue("region", region);
            }
          );
      }
    );

    // Customer select list based on region
    const loadDispatchCustomerForRegion = (region: {
      value: any;
      label: string;
    }) => {
      dispatch(getCustomerSelectListAction({RegionId: region?.value})).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.customerId, apiRes?.data).then(
            (customer: any) => {
              setValue("customer", customer);
            }
          );
      });
    };


    dispatch(getLeadSourceListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.leadSource, apiRes?.data).then(
            (leadSource: any) => {
              setValue("leadSource", leadSource);
            }
          );
      }
    );

    dispatch(getStageListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.stage, apiRes?.data).then(
            (addStage: any) => {

              setValue("addStage", addStage);
            }
          );
      }
    );

    dispatch(getCustomerTypeListAction()).then((apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.customerType, apiRes?.data).then(
          (customerType: any) => {
            setValue("customerType", customerType);
          }
        );
    });
  };
  /* function definition for select list API calls */

  /* load initial dependencies - Edit */
  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { LeadId } = params;

      setLoadingComponent(true)
      const dataToBeSent = { LeadId: LeadId };
      dispatch(getLeadDetailAction(dataToBeSent)).then((apiRes) => {
        const leadDetail = apiRes?.data;
        if (leadDetail) {
          selectListApiCalls(leadDetail);

          reset(leadDetail);

          /* setting the dependencies for attachments */
          if(leadDetail?.attachmentPath && leadDetail?.attachmentPath!=="" && leadDetail?.attachmentPath!==null && leadDetail?.attachmentPath!==undefined){
          let attachmentFileName = leadDetail?.attachmentName;
          const attachmentFileSizeBytes = 0;
          const attachmentFileUrl = `${AppApiConfig.API_ENDPOINT}${leadDetail?.attachmentPath}`;
          addModuleAttachment(null,attachmentFileName,attachmentFileSizeBytes,attachmentFileUrl);
          /* setting the dependencies for attachments */
          }
          setLoadingComponent(false)
        }
      });
    }
  }, []);
  /* load initial dependencies - Edit */

  /* load initial dependencies - Add */
  useEffect(() => {
    if (!params || params === null || params === undefined) {
      selectListApiCalls(null);
    }
  }, []);
  /* load initial dependencies - Add */

  /* On Region changed function */
  const onRegionChanged = (option: SelectListProps, isClearAction: boolean) => {
    if (option && option !== null && option !== undefined) {
      loadCustomerForRegion(option);
    }
    if (isClearAction) {
      dispatch(Actions.createAction(Actions.RESET_CUSTOMER_SELECT_LIST));
    }
    setValue("customer", null);
  };

  /* for loading customer based on region */
  const loadCustomerForRegion = (option: SelectListProps) => {
    dispatch(Actions.createAction(Actions.RESET_CUSTOMER_SELECT_LIST));
    const dataToBeSent = {
      RegionId: option.value,
    };
    dispatch(getCustomerSelectListAction(dataToBeSent));
  };
  /* for loading customer based on region */
  /* On Region changed function */

  /* Open Attachment Picker On Button Click */
  const openAttachmentPicker = (event: React.SyntheticEvent) => {
    event.preventDefault();
    attachmentInputRef?.current?.click();
  };
  /* Open Attachment Picker On Button Click */
  const onAttachmentChanged = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    let target = event.target as HTMLInputElement;
    const attachmentFileObj = (target.files as FileList)[0];
    target.value = "";

    const attachmentFileName = attachmentFileObj.name;
    const attachmentFileSizeBytes = attachmentFileObj.size;
    const attachmentFileUrl = "";

    if (attachmentFileName !== "") {
      const fileTypeIsValidRes =
        checkIfFileTypeIsValidForAttachment(attachmentFileName);
      const fileSizeIsValidRes = checkIfFileSizeIsValidForAttachment(
        attachmentFileSizeBytes
      );

      if (fileTypeIsValidRes.isValid === false) {
        showToast({
          message: fileTypeIsValidRes.isInvalidMessage,
          variant: AppConstConfig.TOAST_VARIANT_ERROR,
        });
      } else if (fileSizeIsValidRes.isValid === false) {
        showToast({
          message: fileSizeIsValidRes.isInvalidMessage,
          variant: AppConstConfig.TOAST_VARIANT_ERROR,
        });
      } else {
        addModuleAttachment(
          attachmentFileObj,
          attachmentFileName,
          attachmentFileSizeBytes,
          attachmentFileUrl
        );
      }
    }
  };

  /* function definition to formulate attachment preview */
  const addModuleAttachment = (
    attachmentFileObj: File | null,
    attachmentFileName: string,
    attachmentFileSizeInBytes: number,
    attachmentFileUrl: string
  ) => {
    formulateAttachmentPreview(
      attachmentFileObj,
      attachmentFileName,
      attachmentFileUrl
    ).then((fileImagePreviewResponse: any) => {
      const attachmentFileImagePreview = fileImagePreviewResponse;

      let isFileChanged = true;
      if (attachmentFileUrl !== "") {
        isFileChanged = false;
      }

      const newAttachment = {
        attFileName: attachmentFileName,
        attFileObj: attachmentFileObj,
        attFileSizeBytes: attachmentFileSizeInBytes,
        attFileImagePreview: attachmentFileImagePreview,
        attFileUrl: attachmentFileUrl,
        isFileChanged,
      };

      setAttachment(newAttachment);
    });
  };
  /* function definition to formulate attachment preview */

  /* function definition to remove the file attachment */
  const removeFileAttachment = () => {
    addModuleAttachment(null, "", 0, "")
  };
  /* function definition to remove the file attachment */

  /* Form submit handler */
  const onSubmit = (data: any) => {
    let formData: any = new FormData();

    formData.append(`Id`, !params?.LeadId ? 0 : params?.LeadId);
    formData.append(`RegionId`, data?.region ? data?.region?.value : 0);
    formData.append(`CustomerId`, data?.customer ? data?.customer?.value : 0);
    formData.append(`Title`, data?.title || "");
    formData.append(`ContactName`, data?.contactName || "");
    formData.append(`ContactPosition`, data?.contactPosition || "");
    formData.append(`ContactPhone`, data?.contactPhone || "");
    formData.append(`ContactEmail`, data?.contactEmail || "");
    formData.append(`LeadSource`, data?.leadSource ? data?.leadSource?.value : 0);
    formData.append(`CustomerType`, data?.customerType ? data?.customerType?.value : 0);
    formData.append(`Stage`, data?.addStage ? data?.addStage?.value : 0);
    formData.append(`Notes`, data?.notes || "");
    formData.append(`DocumentTitle`, data?.documentTitle || "");
    formData.append(`Attachment`, (attachment?.attFileObj as File) || null);
    formData.append(`IsFileChanged`, attachment?.isFileChanged || false);
    
    dispatch(addLeadAction(navigate, showToast, formData));
  };

  /* Form submit handler */

  return loadingComponent ? (
    <Card>
      <TableLoader />
    </Card>
  ) : (
    <Card>
      <div className="lead-list-container flex_box flex_box--column">
        <Grid container>
          <Title>{t("Sales.Lead.LeadUtils.AddLead")}</Title>
        </Grid>
        <form onSubmit={handleSubmit(onSubmit)} name="leadForm" id="leadForm">
          <Grid container spacing="sm">
            {/*REGION*/}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Sales.Lead.LeadForm.Region")}</Label>

              <Select
                name="region"
                options={regionSelectList}
                control={control}
                isClearable
                isLoading={apiLoader}
                onCustomChange={(option, isClearAction) => {
                  onRegionChanged(option, isClearAction);
                }}
                placeholder={t("Sales.Lead.LeadForm.SelectRegion")}
              />
            </Grid>
            {/* REGION*/}
            {/* CUSTOMER */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Sales.Lead.LeadForm.Customer")}</Label>
              <Select
                name="customer"
                options={customerSelectList}
                control={control}
                isClearable
                isLoading={apiLoader}
                placeholder={t("Sales.Lead.LeadForm.SelectCustomer")}
              />
            </Grid>
            {/* CUSTOMER */}
            {/* TITLE */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Sales.Lead.LeadForm.Title")}</Label>

              <InputField
                name="title"
                control={control}
                placeholder={t("Sales.Lead.LeadForm.EnterTitle")}
              />
            </Grid>
            {/* TITLE */}
            {/* Contact Name */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Sales.Lead.LeadForm.ContactName")}</Label>
              <InputField
                name="contactName"
                control={control}
                placeholder={t("Sales.Lead.LeadForm.EnterContactName")}
              />
            </Grid>
            {/*  Contact Name  */}
            {/*  Contact Position  */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Sales.Lead.LeadForm.ContactPosition")}</Label>
              <InputField
                name="contactPosition"
                control={control}
                placeholder={t("Sales.Lead.LeadForm.EnterContactPosition")}
              />
            </Grid>
            {/*  Contact Position  */}
            {/*  Contact Phone */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label> {t("Sales.Lead.LeadForm.ContactPhone")}</Label>
              <InputField
                name="contactPhone"
                type="number"
                control={control}
                placeholder={t("Sales.Lead.LeadForm.EnterContactPhone")}
              />
            </Grid>
            {/* Contact Phone */}
            {/*  Contact Email */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Sales.Lead.LeadForm.ContactEmail")}</Label>
              <InputField
                name="contactEmail"
                type="email"
                control={control}
                placeholder={t("Sales.Lead.LeadForm.EnterContactEmail")}
              />
            </Grid>
            {/*  Contact Email*/}
            {/* Lead Source*/}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Sales.Lead.LeadForm.LeadSource")}</Label>
              <Select
                name="leadSource"
                options={selectLeadSource}
                control={control}
                isClearable
                isLoading={apiLoader}
                placeholder={t("Sales.Lead.LeadForm.SelectLeadSource")}
              />
            </Grid>
            {/*  Lead Source */}
            {/* Customer Type */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Sales.Lead.LeadForm.CustomerType")}</Label>
              <Select
                name="customerType"
                options={selectCustomerTypes}
                control={control}
                isClearable
                isLoading={apiLoader}
                placeholder={t("Sales.Lead.LeadForm.SelectCustomerType")}
              />
            </Grid>
            {/*  Customer Type */}
            {/* Add Stage */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label required>{t("Sales.Lead.LeadForm.AddStage")}</Label>
              <Select
                name="addStage"
                options={selectStages}
                control={control}
                isClearable
                isLoading={apiLoader}
                placeholder={t("Sales.Lead.LeadForm.SelectAddStage")}
              />
            </Grid>
            {/* Add Stage */}
            {/*Document Title */}
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Label>{t("Sales.Lead.LeadForm.DocumentTitle")}</Label>
              <InputField
                name="documentTitle"
                control={control}
                placeholder={t("Sales.Lead.LeadForm.EnterDocumentTitle")}
              />
            </Grid>
            {/*Document Title*/}
            {/* Attachment */}
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Grid
                container
                alignItems="center"
                style={{ width: "calc(100% - 60px)" }}
              >
                <Grid item sm={6} md={6} lg={6}>
                  <Label>{t("CommonUtils.Attachments")}</Label>
                </Grid>
                <Grid item sm={6} md={6} lg={6} textAlign="end">
                  <input
                    type="file"
                    style={{ display: "none" }}
                    ref={attachmentInputRef}
                    onChange={onAttachmentChanged}
                    onKeyDown={(e)=>{
                      if (e.key=='Enter') {
                            e.preventDefault();
                            return false;
                    }
                    }}
                  />
                  <IconButton onClick={openAttachmentPicker} onKeyDown={(e)=>{
                    if (e.key=='Enter') {
                          e.preventDefault();
                          return false;
                  }
                  }}>
                    <AttachmentIcon
                      fillColor={AppConstConfig.APP_PRIMARY_COLOR}
                    />
                  </IconButton>
                </Grid>
              </Grid>
              {attachment &&
                attachment !== null &&
                attachment !== undefined &&
                (attachment.attFileObj !== null || attachment.attFileUrl !== "")  ?  (
                  <Grid container>
                    <Grid item lg={4}>
                      <FileAttachment
                        attachmentObj={attachment}
                        removeAttachment={removeFileAttachment}
                      />
                    </Grid>
                  </Grid>
                ) : <></>}      
            </Grid>
            {/* Attachment */}
            {/* Notes */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Label>{t("Sales.Lead.LeadForm.Notes")}</Label>
              <TextArea
                rows={6}
                name="notes"
                control={control}
                placeholder={t("Sales.Lead.LeadForm.EnterNotes")}
              />
            </Grid>
            {/* Notes */}

            <Grid item xs={12} textAlign="center">
              <Button type="submit" form="leadForm">
                {t("CommonUtils.Button.Submit")}
              </Button>
              <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  reset(defaultValues);
                }}
              >
                {t("CommonUtils.Button.Reset")}
              </Button>
              <Button
                variant="outlined"
                onClick={(event: React.SyntheticEvent) => {
                  event.preventDefault();
                  navigate(AppRoutingConfig.APP_URL_SALES_LEAD_MODULE_LIST);
                }}
              >
                {t("CommonUtils.Button.Back")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Card>
  );
};

export default LeadForm;
