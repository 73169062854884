import React from "react";
import { PageNotFoundPage } from "../../../assets/svgs/svg-components";
import Button from "../../common/button";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import "./PageNotFound.scss";

type Props = {};

const PageNotFound = (props: Props) => {

  /* navigation dependencies */
  const navigate = useNavigate()
  /* navigation dependencies */

  /* i18n dependencies */
  const {t} = useTranslation()
  /* i18n dependencies */

  return (
    <div className="page-not-found-container flex_box flex_box--items-center flex_box--justify-center flex_box--column gap-2">
      <div className="heading">{t("PageNotFound.404")}</div>
      <div className="sorry-page-not-found"> {t("PageNotFound.OopsPageNotFound")} </div>
      <PageNotFoundPage />
      <Button onClick={() => navigate(AppRoutingConfig.APP_URL_DEFAULT_HOME)}> {t("PageNotFound.BackToHomePage")}</Button>
    </div>
  );
};

export default PageNotFound;
