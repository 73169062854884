import AppApiConfig from "../../../assets/config/AppApiConfig";
import axiosConfigInstance from "../../../axios/axiosInstance";

export const GET_CUSTOMER_URL = AppApiConfig.API_CUSTOMER_MANAGEMENT_LIST_URL;
export const ADD_EDIT_CUSTOMER_URL = AppApiConfig.API_ADD_EDIT_CUSTOMER_URL;
export const GET_CUSTOMER_BY_ID_URL = AppApiConfig.API_GENERATE_CUSTOMER_ID_URL;
export const GET_CUSTOMER_ID_URL = AppApiConfig.API_GET_CUSTOMER_ID_URL;

export const getCustomer = (dataToBeSent: {
  isActive: null;
  orderBy: boolean;
  pageNumber: number;
  pageSize: number;
}) => {
  return axiosConfigInstance.post(GET_CUSTOMER_URL, dataToBeSent);
};
export const addEditCustomer = (dataToBeSent: {
  customerId: string;
  customerName: string;
  addressLine1: string;
  addressLine2: string;
  country: null;
  state: null;
  city: null;
  zipCode: string;
  phone: string;
  fax: string;
  email: string;
  website: string;
  billingAddressLine1: string;
  billingAddressLine2: string;
  billingCountry: null;
  billingState: null;
  billingCity: null;
  billingZipcode: number;
  contactName: string;
  contactPosition: string;
  contactWorkPhone: string;
  contactMobile: string;
  contactEmail: string;
  regionId: number;
}) => {
  return axiosConfigInstance.post(ADD_EDIT_CUSTOMER_URL, dataToBeSent);
};
export const getCustomerById = (dataToBeSent: { CustomerId: string }) => {
  return axiosConfigInstance.post(GET_CUSTOMER_BY_ID_URL, {}, { params: dataToBeSent });
};
export const getCustomerId = () => {
  return axiosConfigInstance.post(GET_CUSTOMER_ID_URL);
};

export const getCustomerSelectList = (dataToBeSent:{RegionId: string}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_CUSTOMER_SELECT_LIST_URL,{},{params:dataToBeSent}
  )
}
export const updateCustomerStatus = (dataToBeSent: {
  customerId: number;
  isActive: boolean;
}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_UPDATE_CUSTOMER_STATUS_URL,
    dataToBeSent
  );
};
