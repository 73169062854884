import Actions from "../actions";
import { produce } from "immer";

interface State {
attendanceList : {
  totalCount: number;
  attendanceDetails:[];
},
selectedAttendanceManagementId:number
}

const initialState: State = {
    attendanceList : {
      totalCount: 0,
      attendanceDetails:[]
    },
    selectedAttendanceManagementId:0
};

const attendanceManagement = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_ATTENDANCE_MANAGEMENT_LIST: {
      draft.attendanceList = payload
      break;
    }

    case Actions.SELECTED_ATTENDANCE_MANAGEMENT_ID: {
      draft.selectedAttendanceManagementId = payload?.selectedAttendanceManagementId
      break;
    }
    
    default: {
      break;
    }
  }
}, initialState);

export default attendanceManagement;
