import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';
import ProjectSummaryForm from './ProjectSummaryForm';
import ProjectSummaryDetails from './ProjectSummaryDetail';

const ProjectSummaryList = lazy(()=> import("./ProjectSummaryList"))

const ProjectSummaryConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_LIST,
      element: <ProjectSummaryList />,
      handle: { crumb: () => "Project Summary" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_OPERATIONS_PROJECT_SUMMARY_MODULE,
      element: <ProjectSummaryForm />,
      handle: { crumb: () => "Add Project Summary" },
    },
    {
      path: AppRoutingConfig.APP_URL_OPERATIONS_PROJECT_SUMMARY_MODULE_DETAIL,
      element: <ProjectSummaryDetails/>,
      handle: { crumb: () => "Project Summary detail" },
    }
  ],
};

export default ProjectSummaryConfig