export const GET_CAMPAIGNS_LIST = null;
export const EMPTY_CAMPAIGNS_LIST = null;
export const GET_CAMPAIGNS_DETAIL = null;
export const ADD_EDIT_CAMPAIGNS_DETAILS = null;
export const GET_BUSINESS_TYPE_SELECT_LIST = null;
export const GET_SOURCE_SELECT_LIST = null;
export const GET_AGENT_NAME_SELECT_LIST = null;
export const GET_LEAD_NAME_SELECT_LIST = null;
export const GET_CAMPAIGNS_SELECT_LIST = null;
export const ADD_MENUAL_ASSIGN_DETAILS = null;
export const ADD_ASSIGN_AGENT_DETAILS = null;
export const GET_CAMPAIGN_STATUS_SELECT_LIST = null;
export const UPDATE_CAMPAIGN_STATUS = null;
export const SELECTED_CAMPAIGN_ID = null;
export const GET_SEARCH_LEAD_COUNT_DETAIL = null;
