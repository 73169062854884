import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import { Navigate } from "react-router";
import FinanceDashboardConfig from './dashboard/DashboardConfig'

const FinanceConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_FINANCE,
      element: (
        <Navigate to={AppRoutingConfig.APP_URL_FINANCE_DASHBOARD} replace />
      ),
    },
    ...FinanceDashboardConfig.routes
  ]
};

export default FinanceConfig;
