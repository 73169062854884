import { NavigateFunction } from "react-router";
import Actions from "../../actions";
import * as requestFromServer from "./employeeManagementApis";
import { AxiosResponse } from "axios";
import AppRoutingConfig from "../../../assets/config/AppRoutingConfig";
import AppConstConfig from "../../../assets/config/AppConstConfig";


/* GET EMPLOYEES */
export const getEmployeeManagementAction =
  (
    dataToBeSent: {isActive: boolean;orderBy: boolean;pageNumber: number;pageSize: number}
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getEmployees(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_EMPLOYEE_MANAGEMENT_LIST, {employees:response?.data}));
    return response 
  }

/* GET EMPLOYEE DETAIL - PHASE 1 */
export const getEmployeeManagementDetailPhase1Action =
  (
    dataToBeSent: { userId: number }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getEmployeeDetailPhase1(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_EMPLOYEE_MANAGEMENT_DETAIL_PHASE1, {employeeManagemetnPhase1Details:response?.data}));
    return response 
  };

/* GET EMPLOYEE DETAIL - PHASE 2 */
export const getEmployeeManagementDetailPhase2Action =
  (
    dataToBeSent: { userId: number }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getEmployeeDetailPhase2(dataToBeSent)
    return response 
  };

/* ADD EMPLOYEE DETAIL - PHASE 1 */
export type AddEmployeeManagementDetailPhase1Types = {
  id: number;
  loginUser: number;
  regionId: number;
  departmentId: number;
  roleId: number;
  employeeCode: string;
  suffix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  dob: string;
  socialSecurityNumber: string;
  gender: string;
  homePhone: string;
  mobilePhone: string;
  workPhone: string;
  email: string;
  address1: string;
  address2: string;
  country: number;
  state: number;
  city: number;
  zip: string;
  maillingAddress: string;
  emgyContactName: string;
  emgyContactRelation: string;
  emgy1Phone1: string;
  emgy1Phone2: string;
};
export const addEmployeeManagementDetailPhase1Action =
  (
    navigate: NavigateFunction,
    showToast: (value: { message: string; variant: string }) => void,
    dataToBeSent: AddEmployeeManagementDetailPhase1Types
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.addEmployeeDetailPhase1(dataToBeSent)
    
    dispatch(Actions.createAction(Actions.EMPLOYEE_MANAGEMENT_DETAILS_FOR_SECONDARY_FORM,{ employeeManagementDetailsForSecondaryForm: response?.data }));
    if(response?.statusCode === 400){
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_ERROR,
      });
    }
    else if(response?.statusCode === 200) {
      navigate(AppRoutingConfig.APP_URL_MOD_ADD_HR_EMPLOYEE_MANAGEMENT_SECONDARY_MODULE);
      window.scrollTo({top:0})
      showToast({
        message: response?.message,
        variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
      });
    }
    return response 
  };

/* ADD EMPLOYEE DETAIL - PHASE 2 */
export type AddEmployeeManagementDetailPhase2Types = {
  userId: number;
  loginUserId: number;
  jobTitle: string;
  jobDescription: string;
  baseWageType: string;
  baseWage: string;
  type: string;
  status: string;
  frequency: string;
  reportingTo: number;
  hireDate: string;
  terminationDate: string;
  educationDetail: {
    educationLevelId: number;
    educationDegreeId: number;
    decription: string;
    dateCompleted: string;
  }[];
};
export const addEmployeeManagementDetailPhase2Action = (
  navigate: NavigateFunction,
  showToast: (value: { message: string; variant: string }) => void,
  dataToBeSent: AddEmployeeManagementDetailPhase2Types
) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
  const response: any = await requestFromServer.addEmployeeDetailPhase2(dataToBeSent);
  response && showToast({
    message: response?.message,
    variant: AppConstConfig.TOAST_VARIANT_SUCCESS,
  });
  navigate(AppRoutingConfig.APP_URL_HR_EMPLOYEE_MANAGEMENT_MODULE_LIST);

  return response;
};

/* CHANGE EMPLOYEE MANAGEMENT STATUS */
export type ChangeEmployeeManagementStatusTypes = {
  id: number;
  loginUserId: number;
  terminationDate: string;
};

export const changeEmployeeManagementStatusAction =
  (
    dataToBeSent: ChangeEmployeeManagementStatusTypes
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.changeEmployeeManagementStatus(dataToBeSent)
    return response;
  };

/* GENERATE EMPLOYEE CODE */
export const getEmployeeCodeAction =
  (
    dataToBeSent: { regionName: string }
  ) =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response: any = await requestFromServer.getEmployeeCode(dataToBeSent)
    response && dispatch(Actions.createAction(Actions.GET_EMPLOYEE_CODE, response?.data));
    return response;
  };

/* GET USER FULL NAME SELECT LIST */
export const getUserFullNameSelectListAction = () =>
  async (dispatch: any): Promise<AxiosResponse<any> | null> => {
    const response:any = await requestFromServer.getUserFullNameSelectList()
    response && dispatch(Actions.createAction(Actions.GET_USER_FULL_NAME_SELECT_LIST, {userFullNames:response?.data}));
    return response 
  };
