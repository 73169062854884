import Actions from "../actions";
import { produce } from "immer";

interface State {
  projectSummarys: {
    totalCount: number;
    projectList: [];
  };
  projectSummaryDetail: [];
  projectId: string;
  customerSelectList: [];
  statusSelectList: [];
  getprojectSummaryDetail: [];
  projectSelectList: [];
  projectSummaryActiveInActiveStatus : [];
  projectSummaryStatus: [];
  selectedProjectSummaryDetail: any;
  selectedProjectSummaryId : number;
  selectedProjectSummaryStatus :string;
  updateStatusDetails:{
    selectedProjectSummaryId:number,
    selectedProjectSummaryStatus:boolean;
  }
}

const initialState: State = {
  projectSummarys: {
    totalCount: 0,
    projectList: [],
  },
  projectSummaryDetail: [],
  projectId: "",
  customerSelectList: [],
  statusSelectList: [],
  getprojectSummaryDetail: [],
  projectSelectList: [],
  projectSummaryActiveInActiveStatus : [],
  projectSummaryStatus: [],
  selectedProjectSummaryDetail: null,
  selectedProjectSummaryId:0,
  selectedProjectSummaryStatus:"",
  updateStatusDetails:{
    selectedProjectSummaryId:0,
    selectedProjectSummaryStatus:false,
  }
};

const projectSummary = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action?.type) {
    case Actions.GET_PROJECT_SUMMARY_LIST: {
      draft.projectSummarys = payload?.projectSummarys;
      break;
    }
    case Actions.ADD_PROJECT_SUMMARY_LIST: {
      draft.projectSummaryDetail = payload?.projectSummaryDetail;
      break;
    }
    case Actions.GET_PROJECT_ID: {
      draft.projectId = payload;
      break;
    }
    case Actions.GET_CUSTOMER_SELECT_LIST: {
      draft.customerSelectList = payload?.RegionId;
      break;
    }
    case Actions.GET_OPERATION_STATUS_SELECT_LIST: {
      draft.statusSelectList = payload?.statusSelectList;
      break;
    }
    case Actions.GET_PROJECT_SUMMARY_DETAIL: {
  
      draft.getprojectSummaryDetail = payload?.projectId;
    
      break;
    }

    case Actions.UPDATE_PROJECT_SUMMARY_ACTIVE_INACTIVE_STATUS: {
      draft.projectSummaryActiveInActiveStatus = payload?.projectSummaryActiveInActiveStatus;
      break;
    }

    case Actions.SELECTED_PROJECT_SUMMARY_DETAIL: {
      draft.selectedProjectSummaryDetail = payload.selectedProjectSummaryDetail;
      break;
    }

    case Actions.UPDATE_PROJECT_SUMMARY_STATUS: {
      draft.projectSummaryStatus = payload;
      break;
    }

    case Actions.SELECTED_PROJECT_SUMMARY_ID: {
      draft.selectedProjectSummaryId = payload?.selectedProjectSummaryId;
      draft.selectedProjectSummaryStatus = payload?.selectedProjectSummaryStatus;
      break;
    }

    case Actions.PROJECT_SUMMARY_UPDATE_STATUS: {
      draft.updateStatusDetails = payload?.updateStatusDetails;
      break;
    }


    default: {
      break;
    }
  }
}, initialState);

export default projectSummary;
