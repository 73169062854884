import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import { lazy } from 'react';

const ForgotPassword = lazy(()=> import("./ForgotPassword"))

const ForgotPasswordConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
  ],
};

export default ForgotPasswordConfig