import { useEffect } from "react";

const useClickOutside = (ref:any, onClickOutside:()=>void) => {
    useEffect(()=>{
        function handleClickOutside(event:MouseEvent){
            if ((ref?.current && !ref?.current?.contains(event.target))) {
                onClickOutside()
              }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document?.removeEventListener("click", handleClickOutside);
          };
    },[])
}

export default useClickOutside;