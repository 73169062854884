
import AppApiConfig from '../../../assets/config/AppApiConfig'
import { AddEmployeeManagementDetailPhase1Types, AddEmployeeManagementDetailPhase2Types, ChangeEmployeeManagementStatusTypes } from './employeeManagementServices'
import axiosConfigInstance from '../../../axios/axiosInstance'


export const getEmployees = (dataToBeSent:{isActive:boolean, orderBy:boolean, pageNumber:number, pageSize:number}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_EMPLOYEE_MANAGEMENT_MODULE_LIST_URL,
    dataToBeSent
  )
}

export const getEmployeeDetailPhase1 = (dataToBeSent:{userId:number}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_LOAD_EMPLOYEE_MANAGEMENT_PHASE1_DETAIL_URL,{},{params:dataToBeSent}
  )
}

export const getEmployeeDetailPhase2 = (dataToBeSent:{userId:number}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_LOAD_EMPLOYEE_MANAGEMENT_PHASE2_DETAIL_URL,{},{params:dataToBeSent}
  )
}

export const addEmployeeDetailPhase1 = (dataToBeSent:AddEmployeeManagementDetailPhase1Types) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_SAVE_EMPLOYEE_MANAGEMENT_PHASE1_DETAIL_URL,
    dataToBeSent
  )
}

export const addEmployeeDetailPhase2 = (dataToBeSent:AddEmployeeManagementDetailPhase2Types) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_SAVE_EMPLOYEE_MANAGEMENT_PHASE2_DETAIL_URL,
    dataToBeSent
  )
}


export const getEmployeeCode = (dataToBeSent:{regionName:string}) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_GENERATE_EMPLOYEE_CODE_EMPLOYEE_MANAGEMENT_MODULE_URL,
    dataToBeSent
  )
}

export const changeEmployeeManagementStatus = (dataToBeSent:ChangeEmployeeManagementStatusTypes) => {
  return axiosConfigInstance.post(
    AppApiConfig.API_CHANGE_EMPLOYEE_MANAGEMENT_STATUS_URL,
    dataToBeSent
  )
}

export const getUserFullNameSelectList = () => {
  return axiosConfigInstance.post(
    AppApiConfig.API_USER_FULL_NAME_SELECT_LIST_URL
  )
}
