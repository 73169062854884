import AppRoutingConfig from "../../../../assets/config/AppRoutingConfig";
import { lazy } from 'react';

const SignIn = lazy(()=> import("./SignIn"))

const SignInConfig = {
  routes: [
    {
      path: AppRoutingConfig.APP_URL_SIGN_IN,
      element: <SignIn />,
    },
  ],
};

export default SignInConfig