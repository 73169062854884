import { useEffect, useState } from "react";
import Dialog from "../../../../common/dialog";
import Title from "../../../../common/title";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Label from "../../../../common/label";
import Grid from "../../../../common/grid";
import Button from "../../../../common/button";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../common/inputField";
import { useToast } from "../../../../../hooks/useToast";
import TableLoader from "../../../../common/tableLoader";
import { assignAgentAction, getAgentNameAction, getCampaignsSelectListAction } from "../../../../../redux/services/campaigns/campaignsServices";
import Select from "../../../../common/select";
import "./Campaigns.scss";
import { getFilteredObjectFromId } from "../../../../../utils/appCommonUtils";


type Props = {
  selectedCampaignId?: string;
  open: boolean;
  onClose: () => void;
};

type DefaultValueTypes = {
  agentName:null;
  campaign:null;
  assignLeadNo:string
  };

const CampaignsDetailAssignAgentDialog = ({ open, onClose }: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* loading dependencies */
  const [loadingComponent , setLoadingComponent] = useState(false);
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen)
  /* loading dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  /* For navigating between pages */

  /* edit dependencies */
  const { selectedCampaignId , campaignsSelectList, agentNameSelectList} = useAppSelector(
    (state) => state.campaigns
  );
  /* edit dependencies */


  /* Dependencies for default values and validation */
  const defaultValues = {
    campaign : null,
    agent : null,
    assignLeadNo : ""
  };

  const schema = yup.object().shape({
    campaign : yup.object().required(t("Crm.Campaigns.CampaignsDetail.PleaseSelectCampaign")),
    agent : yup.object().required(t("Crm.Campaigns.CampaignsDetail.PleaseSelectAgentName")),
    assignLeadNo : yup.string().required(t("Crm.Campaigns.CampaignsDetail.PleaseEnterNoOfLeadsToAssign"))
  });

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    reset,
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });
  /* Dependencies for default values and validation */

  useEffect(() => {
      dispatch(getCampaignsSelectListAction({RegionId: ""})).then((apiRes)=>{
        apiRes?.data &&
          getFilteredObjectFromId(selectedCampaignId, apiRes?.data).then(
            (campaign: any) => {
              setValue("campaign", campaign);
            }
          );
      })
      dispatch(getAgentNameAction());
  }, [open])

  /* function definition for dialog close to reset additional dependencies */
  const handleDialogClose = () => {
    onClose();
    reset();
    clearErrors();
  };
  /* function definition for dialog close to reset additional dependencies */

  /* Form submit handler */
  const onSubmit = async(formData: any) => {
    if(selectedCampaignId && selectedCampaignId !== 0){
      const dataToBeSent = {
        campaignId:selectedCampaignId,
        agentId:formData?.agent ? formData?.agent?.value : 0,
        assignLeadNo: formData?.assignLeadNo || ""
      }
      await dispatch(assignAgentAction(navigate, showToast, dataToBeSent))
      return handleDialogClose()
    }
  };
  /* Form submit handler */

  return (
    <Dialog open={open} onClose={handleDialogClose} width="md">
      {loadingComponent ? (
        <TableLoader />
      ) : (
        <div className="campaign-detail-assign-agent-form-container flex_box flex_box--column flex_box--space-around gap-2">
          <Title textDecoration="underline" justifyContent="center">
          {t("Crm.Campaigns.CampaignsDetail.AssignAgents")}
          </Title>
          <form
            onSubmit={handleSubmit(onSubmit)}
            name="campaignDetailAssignAgentForm"
            id="campaignDetailAssignAgentForm"
          >
            <Grid container spacing="sm" style={{ margin: "0 1rem" }}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label required>
                {t("Crm.Campaigns.CampaignsDetail.AgentName")}
              </Label>
              <Select
                name="agent"
                options={agentNameSelectList}
                control={control}
                isClearable
                placeholder={t("Crm.Campaigns.CampaignsDetail.SelectAgentName")}
                isLoading={apiLoader}
                maxMenuHeight={100}
              />
            </Grid>

              <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label required>
                {t("Crm.Campaigns.CampaignsDetail.NoOfLeadsToAssign")}
              </Label>
              <InputField
                name="assignLeadNo"
                control={control}
                placeholder={t("Crm.Campaigns.CampaignsDetail.EnterNoOfLeadsToAssign")}
              />
            </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}>
              <Label required>
                {t("Crm.Campaigns.CampaignsDetail.Campaigns")}
              </Label>
              <Select
                name="campaign"
                options={campaignsSelectList}
                control={control}
                isClearable
                placeholder={t("Crm.Campaigns.CampaignsDetail.SelectCampaign")}
                isLoading={apiLoader}
                maxMenuHeight={100}
                isDisabled
              />
            </Grid>
              <Grid item xs={12} textAlign="center">
                <Button type="submit" form="campaignDetailAssignAgentForm">
                  {t("CommonUtils.Button.Submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
    </Dialog>
  );
};

export default CampaignsDetailAssignAgentDialog;
