import Actions from "../actions";
import { produce } from "immer";

interface State {
    selectedOperationsDashboardProjectId:number
}

const initialState: State = {
  selectedOperationsDashboardProjectId:0
};

const operationsDashboard = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action?.type) {
    case Actions.SELECTED_OPERATIONS_DASHBOARD_PROJECT_ID: {
      draft.selectedOperationsDashboardProjectId = payload?.selectedOperationsDashboardProjectId;
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default operationsDashboard;
