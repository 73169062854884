import React, { createContext, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import Toast from "./Toast";
import "./Toast.scss";

type Props = {
  children: React.ReactNode;
};

type ContextTypes = {
  showToast: (value:{message:string, variant:string}) => void;
};

export const ToastContext = createContext<ContextTypes>({
  showToast: ({}) => undefined,
});

const ToastProvider = ({ children }: Props) => {
  const [toasts, setToasts] = useState<{ id: number; message:string, variant:string }[]>([]);
  let id = 0;

  /* function definition to open toast */
  const showToast = ({message, variant}: any) => {
    setToasts((prevValue) => [...prevValue, { id: id++, message, variant }]);
  };
  /* function definition to open toast */

  /* function definition to close toast */
  const closeToast = (id: number) => {
    setToasts((prevValue) => prevValue.filter((toast) => toast.id !== id));
  };
  /* function definition to close toast */

  const value = useMemo(
    () => ({
      showToast,
    }),
    []
  );

  return (
    <ToastContext.Provider value={value}>
      {children}
      {createPortal(
        <div className="toast-wrapper">
          {toasts.map((toast) => {
            return (
              <Toast key={toast.id} variant={toast.variant} close={() => closeToast(toast.id)}>
                {toast.message}
              </Toast>
            );
          })}
        </div>,
        document.body
      )}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
