import { Navigate } from 'react-router-dom';
import AppRoutingConfig from '../../../../../assets/config/AppRoutingConfig';
import { lazy } from 'react';

const RegionList = lazy(()=> import("./RegionList"))
const RegionForm = lazy(()=> import("./RegionForm"))

const RegionConfig = {
  routes: [
    {
        index:true,
        element:<Navigate to={AppRoutingConfig.APP_URL_ADMIN_REGION_MODULE_LIST} replace/>
    },
    {
      path: AppRoutingConfig.APP_URL_ADMIN_REGION_MODULE_LIST,
      element: <RegionList />,
      handle: { crumb: () => "Region" },
    },
    {
      path: AppRoutingConfig.APP_URL_MOD_ADMIN_REGION_MODULE,
      element: <RegionForm />,
      handle: { crumb: () => "Add Region" },
    },
  ],
};

export default RegionConfig