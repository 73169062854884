import React from "react";
import "./Label.scss";

type Props = {
  children: React.ReactNode;
  required?:boolean;
} & React.HTMLAttributes<HTMLDivElement>

const Label = ({ required, children, ...props }: Props) => {
  return (
    <div className="label-container" {...props}>
      <label className="label">{children}</label>
      {
        required &&
      <span className="label-required">*</span>
      }
    </div>
  );
};

export default Label;
