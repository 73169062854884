import React, { FC, useCallback, useEffect, useState } from "react";
import Card from "../../../../common/card";
import Title from "../../../../common/title";
import Grid from "../../../../common/grid";
import Label from "../../../../common/label";
import {useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorText from "../../../../common/errorText";
import Select from "../../../../common/select";
import Button from "../../../../common/button";
import TableLoader from "../../../../common/tableLoader";
import InputField from "../../../../common/inputField";
import TextArea from "../../../../common/textArea";
import { useToast } from "../../../../../hooks/useToast";
import {
  dateObjToDispViewIsoFormat,
  getFilteredArrayForMultiSelect,
  getFilteredObjectFromId,
  isoToDispViewDate,
  isoToDispViewDateObj,
} from "../../../../../utils/appCommonUtils";
import {
  addProjectSchedulingAction,
  getActivityStatusSelectListAction,
  getEmployeeSelectListAction,
  getFieldDataListAction,
  getProjectByIdAction,
  getProjectSchedulingDetailAction,
  getVehicleSelectListAction,
} from "../../../../../redux/services/workflow/workFlowServices";
import Actions from "../../../../../redux/actions";
import ActivityListDialog from "./ActivityListDialog";
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import { getProjectListAction } from "../../../../../redux/services/projectSummary/projectSummaryServices";
import "./WorkFlow.scss";
import Datepicker from "../../../../common/datepicker";
import { getCitySelectListAction, getCountrySelectListAction, getStateSelectListAction } from "../../../../../redux/services/region/regionServices";
import { getCustomerSelectListAction } from "../../../../../redux/services/customerManagement/customerManagementServices";
import AppConstConfig from "../../../../../assets/config/AppConstConfig";
import { getProjectScheduleStatusSelectListAction } from "../../../../../redux/services/common/commonServices";

type Props = {};

type SelectListProps = { value: any; label: string };
type DefaultValueTypes = {
    id: number;
    project: null;
    customer: null;
    serviceOrderNo: number;
    addressLine1: string;
    addressLine2: string;
    country: null;
    state: null;
    city: null;
    zipCode: number;
    amount: string;
    status: null;
    typeOfWork: string;
    startDate: Date | null;
    endDate: Date | null;
    employee: SelectListProps[];
    vehicleId: SelectListProps[];
    workDetails: string;
    fieldDataDetails: Array<{
      id: number;
      status: string;
    }>;
  };

const ProjectSchedulingForm = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  const {apiLoader} = useAppSelector((state)=>state.loadingScreen);
  const [submitLoader, setSubmitLoader] = useState(false);
  /* loading dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */

  /* searchable select dependencies */
  const {
    regionSelectList,
    countrySelectList,
    stateSelectList,
    citySelectList,
  } = useAppSelector((state) => state?.region);
  const { customerSelectList } = useAppSelector(
    (state) => state.customerManagement
  );
  /* searchable select dependencies */

  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  const location = useLocation();
  const params = location?.state;
  /* For navigating between pages */

  /* id dependencies */
  const {selectedProjectSchedulingId} = useAppSelector((state)=>state.workFlow)
  /* id dependencies */

  /* add edit dialog dependencies */
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);
  /* add edit dialog dependencies */

  /* select list dependencies */
  const {
    projectSelect,
    vehicleSelect,
    employeeSelect,
    fieldDataList,
  } = useAppSelector((state) => state.workFlow);
  const {projectScheduleStatusSelectList} = useAppSelector((state) => state.common);
  /* select list dependencies */

  /* Dependencies for default values and validation */
  const defaultValues: DefaultValueTypes = {
    id: 0,
    project: null,
    customer: null,
    serviceOrderNo: 0,
    addressLine1: "",
    addressLine2: "",
    country: null,
    state: null,
    city: null,
    zipCode: 0,
    amount: "",
    status: null,
    typeOfWork: "",
    startDate: null,
    endDate: null,
    employee: [],
    vehicleId: [],
    workDetails: "",
    fieldDataDetails: [],
  };

  const schema = yup.object().shape({
    project: yup
      .object()
      .required(
        t("Operations.WorkFlow.ProjectSchedulingForm.ProjectIsRequired")
      ),
    status: yup
      .object()
      .required(
        t("Operations.WorkFlow.ProjectSchedulingForm.StatusIsRequired")
      ),
    employee: yup
      .array()
      .min(1, t("Operations.WorkFlow.ProjectSchedulingForm.EmployeeIsRequired"))
      .required(
        t("Operations.WorkFlow.ProjectSchedulingForm.EmployeeIsRequired")
      ),
    vehicleId: yup
      .array()
      .min(1, t("Operations.WorkFlow.ProjectSchedulingForm.VehicleIsRequired"))
      .required(
        t("Operations.WorkFlow.ProjectSchedulingForm.VehicleIsRequired")
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { isValid, errors },
    setValue,
    watch,
    reset,
  } = useForm<DefaultValueTypes>({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();
  const { project, employee, vehicleId } = form;

  /* Dependencies for default values and validation */
  const selectListApiCalls = (details: any) => {
    // employee select list
    dispatch(getEmployeeSelectListAction()).then((apiRes)=>{
      details && apiRes?.data && getFilteredArrayForMultiSelect(details?.projectScheduleUser, employeeSelect).then((employee: any) => {
        setValue("employee", employee);
      });
    })

    // vehicle select list
    dispatch(getVehicleSelectListAction()).then((apiRes)=>{
      details && apiRes?.data && getFilteredArrayForMultiSelect(details?.projectScheduleVehicle, vehicleSelect).then((vehicle: any) => {
        setValue("vehicleId", vehicle);
      });
    })

    // project select list
    dispatch(getProjectListAction({CustomerId: ""})).then((apiRes)=>{
      details && apiRes?.data && getFilteredObjectFromId(details?.projectId, projectSelect).then((project: any) => {
        project && loadActivityDetailsForProject(project)
        setValue("project", project);
      });
    })

    // Country select list
    dispatch(getCountrySelectListAction()).then((apiRes) => {
      details && apiRes?.data && getFilteredObjectFromId(details?.country, apiRes?.data).then((country: any) => {
      country && loadDispatchStateForCountry(country);
      setValue("country", country);
      })
    })

    // State select list based on country
    const loadDispatchStateForCountry = (country: {value: any;label: string;}) => {
      dispatch(getStateSelectListAction({CountryId: country?.value})).then((apiRes) => {
      details && apiRes?.data && getFilteredObjectFromId(details?.state, apiRes?.data).then((state: any) => {
      state && loadDispatchCityForState(state);
      setValue("state", state);
      })
    });
    };

    // City select list based on state
    const loadDispatchCityForState = (state: { value: any; label: string }) => {
      dispatch(getCitySelectListAction({StateId: state?.value})).then((apiRes) => {
        details && apiRes?.data && getFilteredObjectFromId(details?.city, apiRes?.data).then((city: any) => {
        setValue("city", city);
        })
      });
    };

    // Customer select list 
    dispatch(getCustomerSelectListAction({RegionId: ""})).then((apiRes) => {
      details && apiRes?.data && getFilteredObjectFromId(details?.customerId, apiRes?.data).then((customer: any) => {    
        setValue("customer", customer);
      });
    });

    // Project schedule select list 
    dispatch(getProjectScheduleStatusSelectListAction()).then((apiRes) => {
      details && apiRes?.data && getFilteredObjectFromId(details?.status, apiRes?.data).then((projectScheduleStatus: any) => {    
        setValue("status", projectScheduleStatus)
      });
    });
  };

  /* load initial dependencies - Edit */
  useEffect(() => {
    if (selectedProjectSchedulingId && selectedProjectSchedulingId !== 0) {
      setLoadingComponent(true)
      const dataToBeSent = { ProjectScheduleId: selectedProjectSchedulingId };
      if (selectedProjectSchedulingId) {
        dispatch(getProjectSchedulingDetailAction(dataToBeSent)).then((apiRes) => {
          const projectScheduleDetail = apiRes?.data;

          if (projectScheduleDetail) {
            const { endDate, startDate, ...rest } = projectScheduleDetail;

            selectListApiCalls(projectScheduleDetail);

            reset({
              ...rest,
              endDate: isoToDispViewDateObj(endDate),
              startDate: isoToDispViewDateObj(startDate)
            });
            setLoadingComponent(false)
          }
        });
      }
    }
    /* load initial dependencies - Edit */

    /* load initial dependencies - Add */
    else if (!selectedProjectSchedulingId || selectedProjectSchedulingId === 0) {
      selectListApiCalls(null);
    }
    /* load initial dependencies - Add */
  }, []);


  /* On project changed function */
  const onProjectChanged = (option: SelectListProps, isClearAction: boolean) => {
    if (option && option !== null && option !== undefined) {
      dispatch(Actions.createAction(Actions.CLEAR_PROJECT_SCHEDULING_ACTIVITY));
      loadProjectDetails(option);
      loadActivityDetailsForProject(option)
    }
  };
  /* On project changed function */


  /* function definition to handle add/edit - Field Data */
  const handleActivityListDialogOpen = (e: React.SyntheticEvent, row: any) => {
    setOpenAddEditDialog(true);
  };
  /* function definition to handle add/edit - Field Data */

  /* function definition to handle add/edit - Dialog close */
  const handleActivityListDialogClose = () => {
    setOpenAddEditDialog(false);
  }
  /* function definition to handle add/edit - Dialog close */

  /* load project details for project */
  const loadProjectDetails = (option: SelectListProps) => {
    setLoadingComponent(true)
    const dataToBeSent = {
      ProjectId: option.value,
    };
    dispatch(getProjectByIdAction(dataToBeSent)).then((apiRes: any) => {
      const projectDetail = apiRes?.data;
      if (projectDetail) {
        const { notes, customerId, po, status, ...rest } = projectDetail;
        selectListApiCalls(projectDetail);
        reset({
            ...rest,
            startDate: isoToDispViewDateObj(projectDetail?.startDate),
            endDate: isoToDispViewDateObj(projectDetail?.endDate),
          },{ keepDirtyValues: true });
          setLoadingComponent(false)
      }
    });
  };
  /* load project details for project */

  /* load activity details for project */
  const loadActivityDetailsForProject = (option: SelectListProps) => {

    /* Empty the field Data list to prevent persist */
    dispatch(Actions.createAction(Actions.EMPTY_FIELD_DATA_LIST));
    /* Empty the field Data list to prevent persist */

    // Activity select list 
    dispatch(getActivityStatusSelectListAction())
 
    const dataToBeSent = {
      projectId: option?.value,
      projectScheduleId: selectedProjectSchedulingId,
    };
    
    dispatch(getFieldDataListAction(dataToBeSent));
  }
  /* load activity details for project */

  /* Form submit handler */
  const onSubmit = (formData: any) => {
    const tempFieldDataList = fieldDataList?.filter((element )=>element.isChecked === true)?.map((element)=>({id:element?.value, status:element?.status}))

    if(!tempFieldDataList || tempFieldDataList?.length <=0){
      showToast({
        message: t("Operations.WorkFlow.ProjectSchedulingForm.PleaseSelectAtleastOneActivity"),
        variant: AppConstConfig.TOAST_VARIANT_ERROR,
      });
      return;
    }
    setSubmitLoader(true)
    const dataToBeSent = {
      id:formData?.id && formData?.id!==undefined ? formData?.id : 0,
      status: formData?.status?.value || 0,
      workDetails:formData?.workDetails,
      projectId: formData?.project?.value || 0,
      customerId: formData?.customer?.value || 0,
      startDate: dateObjToDispViewIsoFormat(formData?.startDate) || "",
      endDate: dateObjToDispViewIsoFormat(formData?.endDate) || "",
      employee: formData?.employee?.map((element:{value:number, label:string}) => element.value),
      vehicleId: formData?.vehicleId?.map((element:{value:number, label:string}) => element.value),
      fieldDataDetails:tempFieldDataList
    };

    dispatch(addProjectSchedulingAction( showToast, navigate, dataToBeSent)).then(()=>{
      setSubmitLoader(false)
    })
  };
  /* Form submit handler */

  return loadingComponent ? (
    <Card>
      <TableLoader />
    </Card>
  ) : (
    <>
      <Card>
        <div className="workflow-form-container flex_box flex_box--column">
          <Grid container>
            <Title>Add Project Scheduling</Title>
          </Grid>
          <form
            onSubmit={handleSubmit(onSubmit)}
            name="projectSchedulingForm"
            id="projectSchedulingForm"
          >
            <Grid container spacing="sm">
              {/* Form Field Start */}
              {/* Project */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label required>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Project")}
                </Label>
                <Select
                  name="project"
                  options={projectSelect}
                  control={control}
                  isDisabled={selectedProjectSchedulingId !== 0}
                  placeholder={t(
                    "Operations.WorkFlow.ProjectSchedulingForm.SelectProject"
                  )}
                  onCustomChange={(option, isClearAction) => {
                    onProjectChanged(option, isClearAction);
                  }}
                  isLoading={apiLoader}
                />
              </Grid>
              {/* Customer */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Customer")}
                </Label>
                <Select
                  name="customer"
                  options={customerSelectList}
                  control={control}
                  isLoading={apiLoader}
                  isDisabled
                />
              </Grid>
              {/* ServiceOrderNo */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t(
                    "Operations.WorkFlow.ProjectSchedulingForm.ServiceOrderNo"
                  )}
                </Label>
                <InputField disabled name="serviceOrderNo" control={control} />
              </Grid>
              {/* AddressLine1 */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.AddressLine1")}
                </Label>
                <InputField disabled name="addressLine1" control={control} />
              </Grid>
              {/* AddressLine2 */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.AddressLine2")}
                </Label>
                <InputField disabled name="addressLine2" control={control} />
              </Grid>
              {/* Country */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Country")}
                </Label>
                <Select
                  name="country"
                  options={countrySelectList}
                  control={control}
                  isLoading={apiLoader}
                  isDisabled
                />
              </Grid>
              {/* State */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.State")}
                </Label>
                <Select
                  name="state"
                  options={stateSelectList}
                  control={control}
                  isLoading={apiLoader}
                  isDisabled
                />
              </Grid>
              {/* City */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.City")}
                </Label>
                <Select
                  name="city"
                  options={citySelectList}
                  control={control}
                  isLoading={apiLoader}
                  isDisabled
                />
              </Grid>
              {/* ZipCode */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.ZipCode")}
                </Label>
                <InputField
                  disabled
                  name="zipCode"
                  type="number"
                  control={control}
                />
              </Grid>
              {/* Amount */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Amount")}
                </Label>
                <InputField disabled name="amount" control={control} />
              </Grid>
              {/* Status */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label required>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Status")}
                </Label>
                <Select
                  name="status"
                  options={projectScheduleStatusSelectList}
                  control={control}
                  isLoading={apiLoader}
                  placeholder={t(
                    "Operations.WorkFlow.ProjectSchedulingForm.SelectStatus"
                  )}
                />
              </Grid>
              {/* StartDate */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.StartDate")}
                </Label>
                <Datepicker name="startDate" control={control} disabled/>
              </Grid>
              {/* End Date */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.EndDate")}
                </Label>
                <Datepicker name="endDate" control={control} disabled/>
              </Grid>
              {/* Type of work */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.TypeOfWork")}
                </Label>
                <InputField disabled name="typeOfWork" control={control} />
              </Grid>
              {/* Employee */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label required>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Employee")}
                </Label>
                <Select
                  name="employee"
                  options={employeeSelect}
                  control={control}
                  isClearable
                  isMulti
                  isLoading={apiLoader}
                  placeholder="Select Employee"
                />
              </Grid>
              {/* Vehicle */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Label required>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.Vehicle")}
                </Label>
                <Select
                  name="vehicleId"
                  options={vehicleSelect}
                  control={control}
                  isClearable
                  isMulti
                  isLoading={apiLoader}
                  placeholder="Select Vehicle"
                />
              </Grid>
              {/* ActivityList Button */}
              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Button
                  variant="outlined"
                  type="button"
                  disabled={project === null ? true : false}
                  onClick={(e) => handleActivityListDialogOpen(e, null)}
                >
                  Activity List
                </Button>
                {errors["fieldDataDetails"]?.message !== undefined ? (
                  <ErrorText>{`${errors["fieldDataDetails"]?.message}`}</ErrorText>
                ) : (
                  ""
                )}
              </Grid>
              {/* Work Details */}
              <Grid item xs={12}>
                <Label>
                  {t("Operations.WorkFlow.ProjectSchedulingForm.WorkDetails")}
                </Label>
                <TextArea disabled name="workDetails" control={control} />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Button type="submit" form="projectSchedulingForm" loading={submitLoader} disabled={submitLoader}>
                  {t("CommonUtils.Button.Submit")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={(e) => {
                    e.preventDefault();
                    params && params?.projectSchedulingId !== null && params?.projectSchedulingId !== undefined
                      ? reset(
                          { employee: [], vehicleId: [] },
                          { keepDefaultValues: true }
                        )
                      : reset({ ...defaultValues });
                  }}
                >
                  {t("CommonUtils.Button.Reset")}
                </Button>
                <Button variant="outlined" onClick={()=>{
                  navigate(AppRoutingConfig.APP_URL_PROJECT_SCHEDULING_MODULE_LIST);
                }}>
                  {t("CommonUtils.Button.Back")}
                </Button>
              </Grid>
            </Grid>
          </form>

          {openAddEditDialog ? (
            <ActivityListDialog
              open={openAddEditDialog}
              onClose={() => handleActivityListDialogClose()}
            />
          ) : (
            ""
          )}
        </div>
      </Card>
    </>
  );
};

export default ProjectSchedulingForm;
