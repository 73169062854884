import AppModuleConfig from "../../assets/config/AppModuleConfig";
import AppRoutingConfig from "../../assets/config/AppRoutingConfig";

export const useFleetMenuUtils = () => {
    const tempMenuGroup = [];

    /* menuItemFleetDashboard  */
    const menuItemFleetDashboard = {
        id: "fleetDashboard",
        title: AppModuleConfig.FLEET_MODULES.DASHBOARD,
        url: AppRoutingConfig.APP_URL_FLEET_DASHBOARD,
    };
    tempMenuGroup.push(menuItemFleetDashboard);
    /* menuItemFleetDashboard  */
         
    /* menuItemFleetManagement */
    const menuItemFleetManagement = {
        id: "fleetManagement",
        title: AppModuleConfig.FLEET_MODULES.FLEET_MANAGEMENT,
        url: AppRoutingConfig.APP_URL_FLEET_FLEET_MANAGEMENT_MODULE_LIST,
    };
    tempMenuGroup.push(menuItemFleetManagement);
    /* menuItemFleetManagement */

    return tempMenuGroup;
};
