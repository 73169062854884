import Actions from "../actions";
import { produce } from "immer";

interface State {
  activities:[]
}

const initialState: State = {
    activities: []
};

const activityField = produce((draft: State, action: any) => {
  const { payload } = action;
  switch (action.type) {
    case Actions.GET_ACTIVITY_SELECT_LIST: {
      draft.activities = payload?.activities;
      break;
    }

    default: {
      break;
    }
  }
}, initialState);

export default activityField;
