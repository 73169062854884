import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Grid from "../../../../common/grid";

import Label from "../../../../common/label";

import Card from "../../../../common/card";
import TableLoader from "../../../../common/tableLoader";
import { useTranslation } from "react-i18next";
import { useToast } from "../../../../../hooks/useToast";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux-hooks";
import { useLocation, useNavigate } from "react-router";

import {
  getFilteredObjectFromId
} from "../../../../../utils/appCommonUtils";
import {
  getRegionSelectListAction
} from "../../../../../redux/services/region/regionServices";
import Title from "../../../../common/title";
import {
  getCustomerSelectListAction,
} from "../../../../../redux/services/customerManagement/customerManagementServices";
import {
  getCustomerTypeListAction,
  getLeadDetailAction,
  getLeadSourceListAction,
  getStageListAction,
} from "../../../../../redux/services/lead/leadServices";
import { formulateAttachmentPreview } from "../../../../../utils/appFileUtils";
import AppApiConfig from "../../../../../assets/config/AppApiConfig";
import FileAttachment from "../../../../common/fileAttachment";
import "./Lead.scss"
import AppRoutingConfig from "../../../../../assets/config/AppRoutingConfig";
import Button from "../../../../common/button";

type Props = {};

const LeadDetail = (props: Props) => {
  /* i18n dependencies */
  const { t } = useTranslation();
  /* i18n dependencies */

  /* loading dependencies */
  const [loadingComponent, setLoadingComponent] = useState(false);
  /* loading dependencies */

  /* toast dependencies */
  const { showToast } = useToast();
  /* toast dependencies */
  const location = useLocation();
  const params = location?.state;
  /* dispatch an action */
  const dispatch = useAppDispatch();
  /* dispatch an action */

  /* For navigating between pages */
  const navigate = useNavigate();
  /* For navigating between pages */

  /* detail page dependencies */
  const [leadDetail, setLeadDetail] = useState<any>();
  const [leadSelectDetail, setLeadSelectDetail] = useState({
    region: "",
    customer: "",
    stage: "",
    customerType: "",
    leadSource: "",
  });
  /* detail page dependencies */
  /* module attachment handling dependencies for attachment */
  const [attachment, setAttachment] = useState<{
    attFileName: string;
    attFileObj: File | null;
    attFileSizeBytes: number;
    attFileImagePreview: string;
    attFileUrl: string;
    isFileChanged: boolean;
  } | null>({
    attFileName: "",
    attFileObj: null,
    attFileSizeBytes: 0,
    attFileImagePreview: "",
    attFileUrl: "",
    isFileChanged: false
  });
  /* module attachment handling dependencies for attachment */
  /* searchable select dependencies */
  const { generatedCustomerId } = useAppSelector(
    (state) => state.customerManagement
  );
  /* searchable select dependencies */

  /* function definition for select list API calls */
  const selectListApiCalls = (details: any) => {
    // Region select list
    dispatch(getRegionSelectListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.regionId, apiRes?.data).then(
            (region: any) => {
              setLeadSelectDetail((prevValue) => ({
                ...prevValue,
                region: region?.label,
              }));
            }
          );
      }
    );

    // Customer select list

    dispatch(
      getCustomerSelectListAction({ RegionId: "" })).then((apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.customerId, apiRes?.data).then(
          (customer: any) => {
            setLeadSelectDetail((prevValue) => ({
              ...prevValue,
              customer: customer?.label,
            }));
          }
        );
    });
    //LeadSource Select List
    dispatch(getLeadSourceListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.leadSource, apiRes?.data).then(
            (leadSource: any) => {
              setLeadSelectDetail((prevValue) => ({
                ...prevValue,
                leadSource: leadSource?.label,
              }));
            }
          );
      }
    );

    //Stage Select List
    dispatch(getStageListAction()).then((apiRes) => {
        details &&
          apiRes?.data &&
          getFilteredObjectFromId(details?.stage, apiRes?.data).then(
            (addStage: any) => {
              setLeadSelectDetail((prevValue) => ({
                ...prevValue,
                stage: addStage?.label,
              }));
            }
          );
      }
    );

    //Customer Type Select List
    dispatch(getCustomerTypeListAction()).then((apiRes) => {
      details &&
        apiRes?.data &&
        getFilteredObjectFromId(details?.customerType, apiRes?.data).then(
          (customerType: any) => {
            setLeadSelectDetail((prevValue) => ({
              ...prevValue,
              customerType: customerType?.label,
            }));
          }
        );
    });
  };
  /* function definition for select list API calls */

  useEffect(() => {
    if (params && params !== null && params !== undefined) {
      const { LeadId } = params;

      setLoadingComponent(true)
      const dataToBeSent = { LeadId: LeadId };
      
      dispatch(getLeadDetailAction(dataToBeSent)).then((apiRes: any) => {
        const lead = apiRes?.data;
        if (lead) {
          selectListApiCalls(lead);
          setLeadDetail(lead);
          
          /* setting the dependencies for attachments */
          if(lead?.attachmentPath && lead?.attachmentPath!=="" && lead?.attachmentPath!==null && lead?.attachmentPath!==undefined){
            let attachmentFileName = lead?.attachmentName;
            const attachmentFileSizeBytes = 0;
            const attachmentFileUrl = `${AppApiConfig.API_ENDPOINT}${lead?.attachmentPath}`;
          addModuleAttachment(null,attachmentFileName,attachmentFileSizeBytes,attachmentFileUrl);
          }
          /* setting the dependencies for attachments */
          setLoadingComponent(false)
        }
      });
    }
  }, []);

  /* function definition to formulate attachment preview */
  const addModuleAttachment = (
    attachmentFileObj: File | null,
    attachmentFileName: string,
    attachmentFileSizeInBytes: number,
    attachmentFileUrl: string
  ) => {
    formulateAttachmentPreview(
      attachmentFileObj,
      attachmentFileName,
      attachmentFileUrl
    ).then((fileImagePreviewResponse: any) => {
      const attachmentFileImagePreview = fileImagePreviewResponse;

      let isFileChanged = true;
      if (attachmentFileUrl !== "") {
        isFileChanged = false;
      }

      const newAttachment = {
        attFileName: attachmentFileName,
        attFileObj: attachmentFileObj,
        attFileSizeBytes: attachmentFileSizeInBytes,
        attFileImagePreview: attachmentFileImagePreview,
        attFileUrl: attachmentFileUrl,
        isFileChanged,
      };

      setAttachment(newAttachment);
    });
  };
  /* function definition to formulate attachment preview */
  return (
    <>
      {loadingComponent ? (
        <TableLoader />
      ) : (
        <div className="project-summary-form-container flex_box flex_box--column">
          <Grid container spacing="md" flexDirection="column">
            {/* Customer Info */}
            <Grid item>
              <Card>
                <div className="customer-management-form-container flex_box flex_box--column">
                  <Grid container>
                    <Title>{t("Sales.Lead.LeadUtils.Lead")}</Title>
                  </Grid>

                  <Grid container spacing="sm">
                    {/* Customer Id */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.Region")}</Label>
                      <div className="label_info">
                        {leadSelectDetail?.region || "-"}
                      </div>
                    </Grid>
                    {/* Customer Name */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.Customer")}</Label>
                      <div className="label_info">
                        {leadSelectDetail?.customer || "-"}
                      </div>
                    </Grid>
                    {/* Address Line 1 */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.Title")}</Label>
                      <div className="label_info">
                        {leadDetail?.title || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.ContactName")}</Label>
                      <div className="label_info">
                        {leadDetail?.contactName || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.ContactPosition")}</Label>
                      <div className="label_info">
                        {leadDetail?.contactPosition || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.ContactPhone")}</Label>
                      <div className="label_info">
                        {leadDetail?.contactPhone || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.ContactEmail")}</Label>
                      <div className="label_info">
                        {leadDetail?.contactEmail || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.LeadSource")}</Label>
                      <div className="label_info">
                        {leadSelectDetail?.leadSource || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.CustomerType")}</Label>
                      <div className="label_info">
                        {leadSelectDetail?.customerType || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.AddStage")}</Label>
                      <div className="label_info">
                        {leadSelectDetail?.stage || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Label>{t("Sales.Lead.LeadForm.DocumentTitle")}</Label>
                      <div className="label_info">
                        {leadDetail?.documentTitle || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ width: "calc(100% - 60px)" }}
                      >
                        <Grid item sm={12} md={12} lg={12}>
                          <Label>{t("CommonUtils.Attachments")}</Label>
                        </Grid>
                      </Grid>
                      {attachment &&
                        attachment !== null &&
                        attachment !== undefined &&
                        (attachment.attFileObj !== null || attachment.attFileUrl !== "")  ?  (
                          <Grid container>
                            <Grid item lg={4}>
                              <FileAttachment
                                attachmentObj={attachment}
                                isView={true}
                              />
                            </Grid>
                          </Grid>
                        ) : <></>}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Label>{t("Sales.Lead.LeadForm.Notes")}</Label>
                      <div className="label_info">
                        {leadDetail?.notes || "-"}
                      </div>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      <Button variant="outlined" onClick={()=>{
                          navigate(AppRoutingConfig.APP_URL_SALES_LEAD_MODULE_LIST);
                      }}>
                        {t("CommonUtils.Button.Back")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default LeadDetail;
